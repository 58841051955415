import React, { setGlobal } from 'reactn';
import { BrowserRouter as Router, Route } from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
// import { ThemeProvider } from '@material-ui/styles';
// import { blueGrey, indigo } from '@material-ui/core/colors';
// import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import { useToast, withToastProvider } from './api/module/Toast';

import Layout from "./component/Layout";

// import Home from './page/Home';
import Login from './page/Login';
import Signup from './page/Signup';
import ForgotPassword from './page/ForgotPassword';
import ForgotPass from './page/ForgotPass';
import LoginAsParticipant from './page/LoginAsParticipant';
import DocumentTemplateDataView from './page/DocumentTemplateDataView';
import DocumentTemplateDataPdfView from './page/DocumentTemplateDataPdfView';
import BoxGetToken from './component/BoxGetToken';
import TemplateEditing from './page/TemplateEditing';


const theme = createMuiTheme({
  palette: {
    // primary: indigo,
    // secondary: indigo,
    // primary: {50: "#e8eaf6", 100: "#c5cae9", 200: "#9fa8da", 300: "#7986cb", 400: "#5c6bc0", 500: "#2f3a4c", 600: "#3949ab", 700: "#303f9f", 800: "#283593", 900: "#1a237e", A100: "#8c9eff", A200: "#536dfe", A400: "#3d5afe", A700: "#304ffe"},
    primary: {
      main: '#1B1464',
      // main: '#2f3a4c', // first design
      // main: '#E6E7EA',
    },
    secondary: {
      main: '#333',
      // main: '#50cfd1', // first design
    },
  },
  typography: {
    // fontFamily: ['NanumSquare', 'Noto Sans KR', 'sans-serif'].join(','),
    h6: {
      fontSize: '1.7em',
      fontWeight: 700,
    },
  },
});

// console.log(theme);

// const theme = {
//   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
// };

function App() {
  const toast = useToast();
  setGlobal({
    toast,
  });
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Route path="/"  component={Layout} />
        {/* <Route path="/" component={Home} /> */}
        <Route path="/login" exact={true} component={Login} />
        <Route path="/signup" exact={true} component={Signup} />
        <Route path="/forgotpassword" exact={true} component={ForgotPassword} />
        <Route path="/forgotpass" exact={true} component={ForgotPass} />
        <Route path="/login_participant" exact={true} component={LoginAsParticipant} />
        <Route path="/get-box-token" exact={true} component={BoxGetToken} />
        <Route path="/templateDataView" exact={true} component={DocumentTemplateDataView} />
        <Route path="/templateDataPdfView" exact={true} component={DocumentTemplateDataPdfView} />
        {/*<Route path="/template_editing" exact={true} component={TemplateEditing} />*/}
      </Router>
      {/* <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.BOTTOM_CENTER}
        // lightBackground
      /> */}
    </MuiThemeProvider>
  );
}

export default withToastProvider(App);