import React, { useState, setGlobal, useGlobal, useEffect } from 'reactn';
import { Redirect } from "react-router-dom";
import {
    Typography,
    TextField,
    Button,
    Divider,
    Link,
} from "@material-ui/core";
import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import axios from "axios";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
//import Kakao from 'kakaojs';
import KakaoLogin from 'react-kakao-login';
import { toast_success, toast_warning } from '../api/api';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogAgreement from '../component/DialogAgreement';
import DialogAgreementPersonal from '../component/DialogAgreementPersonal';

import clsx from 'clsx';

const buttonSns = {
    width: "100%",
    marginTop: 6,
    height: 40,
    WebkitBorderRadius: 5,
};

const useStyles = makeStyles(theme => ({
    button: {
        // margin: theme.spacing(1),
        backgroundColor: "#6141d2",
        color: "#fff",
        width: '100%',
        marginTop: 16,
        height: 45,
        borderRadius: 0,
        fontWeight: 'bold',
        WebkitBorderRadius: "50px",
        fontSize: 16,
        "&:hover": {
            opacity: 0.85,
            backgroundColor: "#6141d2",
            //color: "#435e99",
        },
    },
    buttonNaver: {
        backgroundColor: "#1ecd00",
        "&:hover": {
            //color: "#1ecd00",
            opacity: 0.85,
            backgroundColor: "#1ecd00",
        },
        color: "#fff",
        ...buttonSns,
    },
    buttonKakao: {
        backgroundColor: "#ffe800",
        "&:hover": {
            opacity: 0.8,
            backgroundColor: "#ffe800",
        },
        color: "#381e1f",
        ...buttonSns,
    },
    buttonFacebook: {
        backgroundColor: "#435e99",
        "&:hover": {
            backgroundColor: "#435e99",
            //color: "#435e99",
            opacity: 0.85,
        },
        color: "#fff",
        ...buttonSns,
    },
    buttonGoogle: {
        backgroundColor: "#e94337",
        "&:hover": {
            backgroundColor: "#e94337",
            //color: "#e94337",
            opacity: 0.85,
        },
        color: "#fff",
        ...buttonSns,
    },
    inputField: {
        borderRadius: 0,
    },
    bottomLink: {
        color: 'black',
        fontSize: 13,
        textDecoration: 'none',
        marginLeft: 10,
        marginRight: 10,
        textAlign: 'center',
        opacity: 0.8,
    },

    icon: {
        display: "inline-block",
        verticalAlign: "middle",
        //white: nowrap;
        width: "24px",
        height: "24px",
        //lineheight: "24px",
        borderRadius: "15px",
        background: "#fff",
        border: "2px solid #aaa",
        backgroundImage: "url(images/check.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
        backgroundSize: "14px 9px",
        cursor: "pointer",
        position: "relative",
        //WebkitBorderRadius: '15px',
        //MozBorderRadius: '15px',
        //border-radius: 3px,
        //-webkit-border-radius: 15px,
        //-moz-border-radius: 15px,
        //-ms-border-radius: 15px,
        //-o-border-radius: 15px,
        //position: relative,
        top: "-1px",
    },
    checkedIcon: {
        display: "inline-block",
        verticalAlign: "middle",
        //white: nowrap;
        width: "24px",
        height: "24px",
        //lineheight: "24px",
        borderRadius: "15px",
        background: "#fff",
        border: "2px solid #531580",
        backgroundImage: "url(images/checked.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
        backgroundSize: "14px 9px",
        cursor: "pointer",
        position: "relative",
        //border-radius: 3px,
        //-webkit-border-radius: 15px,
        //-moz-border-radius: 15px,
        //-ms-border-radius: 15px,
        //-o-border-radius: 15px,
        //position: relative,
        top: "-1px",
    },
    saveEmailClass: {
        color:"#999",     
        height: 45,   
        '& .MuiTypography-root': {
            //   borderColor: 'red',
            fontWeight: "bold",
            fontSize : "16px",
        },
    },
}));

const CssTextField = withStyles({
    root: {
        backgroundColor: "#f2f2f2",
        
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                //   borderColor: 'red',
                borderRadius: 0,
                border: "none",
                fontWeight : "400",
                fontSize : "16px",
                color : "#333"
            },
            '&:hover fieldset': {
                //   borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                //   borderColor: 'green',
            },
        },
        
    },
})(TextField);

function Entity(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [bLoading, setLoading] = useState(false);
    const [bSaveID, setSaveID] = useState(false);
    const [bAuth] = useGlobal('bAuth');
    const [sGoogleClientId] = useGlobal('sGoogleClientId');
    const [sFacebookAppId] = useGlobal('sFacebookAppId');
    const [sDomainName] = useGlobal('sDomainName');
    const [sNaverClientId] = useGlobal('sNaverClientId');
    const [sApiUrl] = useGlobal('sApiUrl');    
    const [sUserEmail] = useGlobal('sUserEmail');
    const [bDialogAgreement, setDialogAgreement] = useState(false);   
    const [bDialogAgreementPersonal, setDialogAgreementPersonal] = useState(false);   
    
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
        if(sUserEmail != null && sUserEmail != '' && sUserEmail != undefined) {
            setUsername(sUserEmail);
        }

        if (getCookie("osID")) { // getCookie함수로 id라는 이름의 쿠키를 불러와서 있을경우
            setUsername(getCookie("osID")); //input 이름이 id인곳에 getCookie("id")값을 넣어줌
            setSaveID(true);            
            const saveEmail = document.querySelector("span.MuiFormControlLabel-label");
            saveEmail.style.color = "#531580";
        }
    }, []);

    const setCookie = (name, value, expiredays) => {
        let todayDate = new Date();
        todayDate.setDate(todayDate.getDate() + expiredays);
        document.cookie = name + "=" + escape(value) + "; path=/; expires="
                + todayDate.toGMTString() + ";"
    };


    const getCookie = (cookieName) => {
        cookieName  = cookieName + "=";
        const cookieData = document.cookie;
        let start = cookieData.indexOf(cookieName);
        let cookieValue = '';
        if(start > -1){
            start += cookieName.length;
            var end = cookieData.indexOf(';', start);
            if(end == -1)end = cookieData.length;
            cookieValue = cookieData.substring(start, end);
        }
        return unescape(cookieValue);
    };

    const deleteCookie = (cookieName) => {
        let expireDate = new Date();
        expireDate.setDate(expireDate.getDate() - 1);
        document.cookie = cookieName + "= " + "; expires=" + expireDate.toGMTString();
    };

    const loginSns = (type, token) => {
        if (token !== undefined && token.length > 0) {
            setLoading(true);
            axios
                .create()
                .post(`/${sApiUrl}/user/loginsns`, {
                    type,
                    token,
                    userType: "01",
                })
                .then(res => {
                    setLoading(false);
                    if (res.data.status === "000" && res.data.entity !== null) {
                        console.log(res.data.entity.user);
                        setGlobal({
                            bAuth: true,
                            sToken: res.data.entity.token,
                            iUserId: res.data.entity.user.userIdx,
                            sUserName: res.data.entity.user.userName,
                            sUserEmail: res.data.entity.user.userEmail,
                            iRoleId : res.data.entity.user.roleIdx,
                            // sUserSnsType: res.data.entity.user.socialType,
                        });
                        toast_success("로그인에 성공했습니다.");
                    } else {
                        toast_warning(res.data.message);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast_warning(JSON.stringify(err));
                });
        }
    };

    const responseGoogle = (response) => {
        loginSns('google', response.tokenId);
    };

    const responseFacebook = (response) => {
        loginSns('facebook', response.accessToken);
    };

    const responseKakao = (response) => {
        loginSns('kakao', response.response.access_token);
    };

    if (bAuth) {
        return <Redirect to="/my_document" />;
    }
    
    return (
        <div
            style={{
                //backgroundColor: '#edf1f3',
                //padding: 10,
                //paddingTop: 50,
                //paddingBottom: 50,
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                //backgroundColor: "#edf1f3",
                //flex: 1,
            }}
        >
            {/*
            <Typography variant="h4" gutterBottom style={{ fontWeight: 100 }}>
                Online <span style={{ fontWeight: 700 }}>Sign</span>
            </Typography>
            */}
            <h1 
                style={{
                    // backgroundColor: 'red',
                    fontSize: 42,
                    fontWeight: 100,
                    textAlign : 'center',
                    letterSpacing : '3px',
                    marginBottom : '28px',
                    marginTop : '100px',
                }}
            >
                    <img 
                        src="images/logo1-6.png" 
                        alt=""
                        style={{
                            width:'100%',
                            maxWidth:'260px',
                            minWidth: '200px',
                            cursor: 'pointer',
                        }} 
                        onClick={event => {
                            window.location.href = 'https://onlinesign.co.kr/intro';
                        }}                 
                    />
            </h1>
            <div
                style={{
                    // backgroundColor: 'red',
                    minWidth: 300,
                    maxWidth: 400,
                }}
            >
                <form
                    onSubmit={event => {
                        event.preventDefault();
                        if (username.length > 0 && password.length > 0) {
                            setLoading(true);
                            axios.create({
                                // timeout: 40000,
                                // headers: {
                                //     Authorization: `Bearer ${sToken}`
                                // }
                            }).post(`/${sApiUrl}/user/login`, { username, password })
                            .then(res => {
                                setLoading(false);
                                if (res.data.status === '000') {
                                    if(bSaveID) {
                                        setCookie('osID',username,1);
                                    } else {
                                        deleteCookie('osID');
                                    }
                                    setGlobal({
                                        bAuth: true,
                                        sToken: res.data.entity.token,
                                        iUserId: res.data.entity.user.userIdx,
                                        sUserName: res.data.entity.user.userName,
                                        sUserEmail: res.data.entity.user.userEmail,
                                        iRoleId : res.data.entity.user.roleIdx,
                                        // sUserSnsType: res.data.entity.user.socialType,
                                    });
                                    toast_success("로그인되었습니다.");
                                } else {
                                    toast_warning("이메일 또는 비밀번호가 잘못되었습니다.", 10000);
                                }
                            }).catch(err => {
                                setLoading(false);                                
                                toast_warning(err.response.statusText);
                            });
                        } else {
                            toast_warning("이메일이 존재하지 않습니다.");
                        }
                    }}
                >
                    <CssTextField
                        //label="이메일 주소 입력"
                        placeholder='이메일 주소'
                        variant="outlined"
                        fullWidth
                        value={username}
                        onChange={event => {
                            setUsername(event.target.value);
                        }}
                        margin="dense"
                    />
                    <CssTextField
                        //label="비밀번호 입력"
                        placeholder='비밀번호'
                        variant="outlined"
                        fullWidth
                        type="password"
                        value={password}
                        onChange={event => {
                            setPassword(event.target.value);
                        }}
                        margin="dense"
                        className={classes.inputField}
                    />
                    <div style={{
                        //paddingTop : "18px",
                        verticalAlign: "middle",
                    }}>
                        <div style={{
                            //float: "left"
                            width: "49%",
                            display:"inline-block",
                        }}>
                                <FormControlLabel
                                control={
                                <Checkbox
                                    checked={bSaveID}
                                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                    icon={<span className={classes.icon} />}
                                    onChange={event => {
                                        if(bSaveID) {
                                            setSaveID(false);
                                            const saveEmail = document.querySelector("span.MuiFormControlLabel-label");
                                            saveEmail.style.color = "#999";
                                        } else {
                                            setSaveID(true);
                                            const saveEmail = document.querySelector("span.MuiFormControlLabel-label");
                                            saveEmail.style.color = "#531580";
                                        }
                                    }}
                                />
                                }
                                className={classes.saveEmailClass}
                                label="이메일 주소 저장"
                            />
                            
                        </div>

                        <div style={{
                            //float: "right"
                            width: "49%",
                            display:"inline-block",
                            textAlign: "right",
                        }}>
                            <Link
                                href="/forgotpassword"
                                style={{
                                    fontSize: "16px",
                                    color: "#555",
                                    fontWeight: "400",
                                }}
                            >
                                비밀번호 찾기
                            </Link>
                        </div>
                    </div>
                     
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={bLoading}
                    >
                        로그인
                    </Button>
                </form>
                <Divider
                    variant="middle"
                    style={{
                        width: '100%',
                        margin: 0,
                        marginTop: 30,
                        marginBottom: 20,
                    }}
                />
                
                <div
                    style={{
                        boxSizing: "border-box",
                        //display: 'flex',
                        //flexDirection: 'row',
                        //justifyContent: 'space-between',
                        //alignItems: 'space-between',
                    }}
                >
                    <Button
                        color="inherit"
                        className={classes.buttonNaver}
                        onClick={() => {
                            window.processOAuth = sToken => {
                                if (sToken.length > 0) {
                                    loginSns("naver", sToken);
                                }
                            };
                            const sNaverCallbackUrl = `http://${sDomainName}/signup`;
                            const naver_id_login = new window.naver_id_login(
                                sNaverClientId,
                                sNaverCallbackUrl
                            );
                            const state = naver_id_login.getUniqState();
                            const sNaverAuthUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=token&client_id=${sNaverClientId}&redirect_uri=${encodeURIComponent(
                                sNaverCallbackUrl
                            )}&state=${state}`;
                            window.open(
                                sNaverAuthUrl,
                                "Naver authentication",
                                "width=600px,height=700px,top=100px,left=400px"
                            );
                        }}
                    >
                        {/* 
                        <span 
                            style={{
                                verticalAlign: 'middle',
                                paddingRight: '10px',
                                position: 'relative',
                                top: '2px',
                            }}
                            content={{
                                backgroundImage: "url(images/naver.png)",
                            }}

                        >
                        </span>
                        */}
                        <img src='images/naver.png'
                            style={{
                                verticalAlign: 'middle',
                                paddingRight: '10px',
                                position: 'relative',
                            }}
                        >
                        </img>
                        <span 
                            style={{
                                fontSize:'15px',
                                verticalAlign: 'middle',
                                fontWeight: '600',
                            }}
                        >
                            네이버 로그인
                        </span>
                    </Button>                                  
                    <KakaoLogin
                        jsKey='076bf2c79d2b226f7f16a287c8f33d51'
                        onSuccess={result => responseKakao(result)}
                        onFailure={result => responseKakao(result)}                            
                        render={renderProps => (
                            <Button
                                color="inherit"
                                className={classes.buttonKakao}
                                onClick={renderProps.onClick}
                            >
                            <img src='images/kakao.png'
                                style={{
                                    verticalAlign: 'middle',
                                    paddingRight: '10px',
                                    position: 'relative',
                                }}
                            >
                            </img>
                            <span 
                                style={{
                                    fontSize:'15px',
                                    verticalAlign: 'middle',
                                    fontWeight: '600',
                                }}
                            >
                                카카오계정 로그인
                            </span>
                            </Button>
                        )}
                        getProfile={true}
                    />
                    <FacebookLogin
                        appId={sFacebookAppId}
                        autoLoad={false}
                        callback={responseFacebook}
                        render={renderProps => (
                            <Button
                                color="inherit"
                                className={classes.buttonFacebook}
                                onClick={renderProps.onClick}
                            >
                                <img src='images/facebook.png'
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                        position: 'relative',
                                    }}
                                >
                                </img>
                                <span 
                                    style={{
                                        fontSize:'15px',
                                        verticalAlign: 'middle',
                                        fontWeight: '600',
                                    }}
                                >
                                    페이스북 로그인
                                </span>
                            </Button>
                        )}
                    />
                    <GoogleLogin
                        clientId={sGoogleClientId}
                        // hostedDomain="localhost"
                        render={renderProps => (
                            <Button
                                color="inherit"
                                className={classes.buttonGoogle}
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                <img src='images/google.png'
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                        position: 'relative',
                                    }}
                                >
                                </img>
                                <span 
                                    style={{
                                        fontSize:'15px',
                                        verticalAlign: 'middle',
                                        fontWeight: '600',
                                    }}
                                >
                                    구글계정 로그인
                                </span>
                            </Button>
                        )}
                        buttonText="구글"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                    />
                </div>
                
                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 20,
                    }}
                >
                    <div 
                        style={{
                            float: "left",
                        }}
                    >
                        <Link href="/signup" underline="always"
                            style={{
                                fontSize: "16px",
                                //color: "#555",
                                fontWeight: "400",
                            }}
                        >
                            아직 온라인싸인 회원이 아니신가요?
                        </Link>
                    </div>
                    <div 
                        style={{
                            float: "right"
                        }}
                    >
                        <Link
                            href="/signup"
                            style={{
                                fontSize: "17px",
                                color: "#380B61",
                                fontWeight: "bold",
                            }}
                        >
                            회원가입
                        </Link>
                    </div>
                </div>
                {/*
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                        props.history.push("/signup");
                    }}
                >
                    무료 회원 가입
                </Button>
                */}
                
            </div>
            <Divider
                variant="middle"
                style={{
                    minWidth: 300,
                    maxWidth: 400,
                    width: 400,
                    margin: 0,
                    marginTop: 80,
                    marginBottom: 40,
                }}
                />  
            <div
                style={{
                    //marginTop: 80,
                    marginBottom: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    // alignContent: 'space-between',
                    cursor: 'default',
                    fontWeight: 400,
                    fontSize: '12px',
                }}
            >
                <a
                    className={classes.bottomLink}
                    //href="javascript:void(0)"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        fontWeight: 400,
                        fontSize: '12px',
                    }}
                    onClick={() => {
                        setDialogAgreement(true);
                    }}
                >
                    이용약관
                </a>
                |
                <a
                    className={classes.bottomLink}
                    //href="http://mslk.co.kr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        fontWeight: 400,
                        fontSize: '12px',
                    }}
                    onClick={() => {
                        setDialogAgreementPersonal(true);
                    }}
                >
                    개인정보처리방침
                </a>
                |
                <a
                    className={classes.bottomLink}
                    href="https://onlinesign.co.kr/intro/support/support"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        fontWeight: 400,
                        fontSize: '12px',
                    }}
                >
                    고객센터
                </a>
            </div>
            {/**
            <Divider
                variant="middle"
                style={{
                    width: '100%',
                    margin: 0,
                    marginTop: 20,
                    marginBottom: 20,
                }}
            />
             */}
            <div
                style={{
                    marginTop: 40,
                }}
            >
                <Typography variant="body2" style={{ fontWeight: 500, color: '#531580', fontSize:'12px'}}>
                    Copyright © 2020 OnlineSign All Rights Reserved.
                </Typography>
            </div>
            <DialogAgreement
                open={bDialogAgreement}
                bLoading={bLoading}
                onClose={() => setDialogAgreement(false)}
            />
            <DialogAgreementPersonal
                open={bDialogAgreementPersonal}
                bLoading={bLoading}
                onClose={() => setDialogAgreementPersonal(false)}
            />

        </div>
    );
}

export default Entity;