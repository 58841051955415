/* eslint no-restricted-globals:0 */
import React, { useState } from "reactn";
import ContentPicker from "box-ui-elements/es/elements/content-picker";
import { IntlProvider } from "react-intl";
import Button from "@material-ui/core/Button";
import { toast_warning } from "../api/api";
import { clientId } from "../consts/box.config";

function BoxFilePicker({ onSelect }) {
	const [token, setToken] = useState(false);
	window.setBoxToken = setToken;
	window.onError = toast_warning;

	function onChoose(files) {
		if (!onSelect) return;
		const { authenticated_download_url, name } = files[0];
		onSelect({ token, authenticated_download_url, name });
	}

	function onRequestAccess() {
		const w = 500;
		const h = 500;
		const dualScreenLeft =
			window.screenLeft != undefined ? window.screenLeft : window.screenX;
		const dualScreenTop =
			window.screenTop != undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth
			? window.innerWidth
			: document.documentElement.clientWidth
				? document.documentElement.clientWidth
				: screen.width;
		const height = window.innerHeight
			? window.innerHeight
			: document.documentElement.clientHeight
				? document.documentElement.clientHeight
				: screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;
		window.open(
			`https://account.box.com/api/oauth2/authorize?response_type=code&client_id=${clientId}`,
			"get box token",
			`height=${h / systemZoom},width=${w / systemZoom},top=${top},left=${left}`
		);
	}

	if (!token) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Button onClick={onRequestAccess}>FETCH LIST FROM BOX</Button>
			</div>
		);
	}

	return (
		<IntlProvider locale="en" textComponent={React.Fragment}>
			<ContentPicker
				token={token}
				maxSelectable={1}
				onChoose={onChoose}
				canUpload={false}
				canSetShareAccess={false}
				canCreateNewFolder={false}
				autoFocus={true}
				size="small"
				logoUrl="box"
			/>
		</IntlProvider>
	);
}

export default BoxFilePicker;
