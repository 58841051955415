import React, { useState, useEffect, useGlobal } from 'reactn';
import { Redirect } from "react-router-dom";
import {
    Typography,
    Button,
    Divider,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import axios from "axios";
import { toast_success, toast_warning } from '../api/api';

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: "#6141d2",
        color: "#fff",
        width: '100%',
        marginTop: 16,
        height: 45,
        borderRadius: 0,
        fontWeight: 'bold',
        WebkitBorderRadius: "50px",
        fontSize: 16,        
        "&:hover": {
            opacity: 0.85,
            backgroundColor: "#6141d2",
            //color: "#435e99",
        },
    },
    buttonSns: {
        width: '48%',
        marginTop: 16,
        height: 40,
        borderRadius: 0,
    },
    inputField: {
        borderRadius: 0,
        marginBottom: 10
    },
    bottomLink: {
        color: 'black',
        fontSize: 12,
        textDecoration: 'none',
        marginLeft: 10,
        marginRight: 10,
    },
}));

const CssTextValidator = withStyles({
    root: {
        backgroundColor: "#f2f2f2",
        width:'100%',
        
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                //   borderColor: 'red',
                borderRadius: 0,
                border: 'none',
            },
            '&:hover fieldset': {
                //   borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                //   borderColor: 'green',
            },
        },
    },
})(TextValidator);

function Entity(props) {
    const [email, setEmail] = useState('');
    const [bLoading, setLoading] = useState(false);
    const [bAuth] = useGlobal('bAuth');
    const [sApiUrl] = useGlobal('sApiUrl');

    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (bAuth) {
        return <Redirect to="/" />;
    }

    return (
        <div
            style={{
                //backgroundColor: '#edf1f3',
                padding: 10,
                paddingTop: 50,
                paddingBottom: 50,
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <h1 
                style={{
                    // backgroundColor: 'red',
                    fontSize: 42,
                    fontWeight: 100,
                    textAlign : 'center',
                    letterSpacing : '3px',
                    marginBottom : '28px',
                    marginTop : '100px',
                }}
            >
                <img 
                        src="images/logo1-6.png" 
                        alt=""
                        style={{
                            width:'100%',
                            maxWidth:'260px',
                            minWidth: '200px',
                            cursor: 'pointer',
                        }} 
                        onClick={event => {
                            window.location.href = 'https://onlinesign.co.kr/intro';
                        }}                 
                />
            </h1>
            <div
                style={{
                    // backgroundColor: 'red',
                    minWidth: 300,
                    maxWidth: 400,
                }}
            >
                <Typography align="center"
                    style={{
                        // backgroundColor: 'red',
                        fontSize: 21,
                        fontWeight: 700,
                        textAlign : 'center',
                        paddingBottom : '30px',
                        paddingTop : '30px',
                        //lineHeight : 28,
                    }}
                >
                    비밀번호 찾기
                </Typography>
                
                <Divider
                    variant="middle"
                    style={{
                        width: '100%',
                        margin: 0,
                        marginTop: 30,
                        marginBottom: 20,
                    }}
                />
                <ValidatorForm
                    onSubmit={event => {
                        event.preventDefault();
                        setLoading(true);
                        axios.create().get(`/${sApiUrl}/user/forgotpass`, { params: { email } })
                        .then(res => {
                            setLoading(false);
                            if (res.data.status === '000') {
                                toast_success("이메일주소로 발송되었습니다.");
                            } else {
                                toast_warning(res.data.message);
                            }
                        }).catch(err => {
                            setLoading(false);
                            toast_warning(err.response.statusText);
                        });
                    }}
                    // onError={errors => console.log(errors)}
                >
                    <CssTextValidator
                        //label="이메일 주소를 입력해주세요."
                        placeholder='이메일 주소를 입력해주세요.'
                        onChange={event => {
                            setEmail(event.target.value);
                        }}
                        name="email"
                        // type="email"
                        value={email}
                        validators={['required', 'isEmail']}
                        errorMessages={['이메일은 필수입력입니다.','올바른 이메일을 입력하세요.']}
                        margin="dense"
                        className={classes.inputField}
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                    />
                    <label
                        style={{
                            fontSize: 12,
                            fontWeight: 600,
                        }}
                    >
                        가입하신 이메일 주소로 비밀번호 변경 메일을 발송해드립니다.
                    </label>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={bLoading}
                    >
                        이메일 발송
                    </Button>
                </ValidatorForm>
            </div>
            <Divider
                variant="middle"
                style={{
                    width: '100%',
                    margin: 0,
                    marginTop: 80,
                    marginBottom: 20,
                }}
            />
            <Typography variant="body2" style={{ fontWeight: 300}}>
                Copyright © 2020 OnlineSign All Rights Reserved.
            </Typography>
        </div>
    );
}

export default Entity;