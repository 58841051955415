import React, { Fragment, useEffect, useState, useGlobal } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from '@material-ui/core/Divider';
import axios from "axios";

import { toast_warning } from '../api/api';

import { autoFormatKrNumber } from '../lib/lib';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1.4em' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        textAlign: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
}))(MuiDialogActions);

const Dialog = withStyles(theme => ({
    paper: {
        width: '80%',
        minWidth: 500,
        minHeight: 480,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 4,
        //     marginRight: 4,
        // },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    button_sign_request: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    button_cancel: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            // color: 'white',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    dialogAction: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 140,
        },
    },
    smsTextfield: {
        marginBottom: 10,
    },

    textfield_label: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
}));

function Entity(props) {

    const classes = useStyles();
    const [sName, setName] = useState('');
    const [sBirthDay, setBirthDay] = useState('');
    const [sGender, setGender] = useState('');
    const [sPhoneNo, setPhoneNo] = useState('');
    const [sPhoneCorp, setPhoneCorp] = useState('');
    const [sNation, setNation] = useState('');
    
    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');

    const [bLoading, setLoading] = React.useState(false);

    return (
        <Fragment>
            <Dialog
                onClose={props.onClose}
                aria-labelledby="dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogTitle id="fileuploader-dialog-title" onClose={props.onClose}>
                    휴대폰 본인인증
                </DialogTitle>
                <DialogContent dividers>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 480,
                            height: '9vh',
                        }}
                    >
                        {bLoading && (
                            <div
                                style={{
                                    // width: '100%',
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}
                            >
                                <CircularProgress disableShrink />
                            </div>
                        )}
                        {!bLoading && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        //maxWidth: 300,
                                        width: '100%',
                                    }}
                                >
                                    <Fragment>
                                        {/*
                                        <Typography variant='h5' style={{ marginBottom: 20, fontWeight: 'bold' }}>
                                            {ordinal_suffix_of(1)} 서명 참여자 
                                        </Typography>*/}
                                        
                                        <label htmlFor='field2' className={classes.textfield_label}>이름</label>
                                        <TextField
                                            id="field2"
                                            style={{
                                                marginTop: 3,
                                            }}
                                            placeholder="이름을 입력해주세요." /* Document purpose (eg worker, landlord) */
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={sName}
                                            onChange={e => setName(e.target.value)}
                                        />
                                        <label htmlFor='field3' className={classes.textfield_label}>생년월일</label> {/* Name or company name */}
                                        <TextField
                                            id="field3"
                                            style={{
                                                marginTop: 3,
                                            }}
                                            placeholder="년도월일을 입력해주세요. ex)20200424" /* Please enter your name or company name. */
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={sBirthDay}
                                            onChange={e => setBirthDay(e.target.value)}
                                        />
                                        <label htmlFor='field4' className={classes.textfield_label}>성별</label> {/* Cell Phone Number */}
                                        <RadioGroup
                                            aria-label="position"
                                            name="position"
                                            style={{
                                                width: '90%',
                                            }}
                                            value={sGender}
                                            onChange={event => setGender(event.target.value)}
                                        >
                                            <div
                                                style={{
                                                    width : '100%',
                                                    //paddingLeft : '8%',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="0"
                                                    control={<Radio color="secondary" />}
                                                    label="남"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio color="secondary" />}
                                                    label="여"
                                                    labelPlacement="end"
                                                />
                                            </div>
                                        </RadioGroup>
                                        <label htmlFor='field5' className={classes.textfield_label}>통신사</label> {/* Email Address */}
                                        <FormControl
                                            style={{
                                                height: 40,
                                                width: '100%',
                                                outline: 'none',
                                            }}
                                        >
                                            <Select
                                                value={sPhoneCorp}
                                                onChange={e => setPhoneCorp(e.target.value)}
                                                //input={<BootstrapInput name="age" id="age-customized-select" />}
                                                style={{
                                                    outline: 'none',
                                                }}
                                            >
                                                <MenuItem value={'SKT'}>SK텔레콤</MenuItem>
                                                <MenuItem value={'KTF'}>KT</MenuItem>
                                                <MenuItem value={'LGT'}>LG U+</MenuItem>
                                                <MenuItem value={'SKM'}>SKT mvno</MenuItem>
                                                <MenuItem value={'KTM'}>KT mvno</MenuItem>
                                                <MenuItem value={'LGM'}>LG U+ mvno</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <label htmlFor='field5' className={classes.textfield_label}>휴대폰 번호</label> {/* Email Address */}
                                        <TextField
                                            id="field5"
                                            style={{
                                                marginTop: 3,
                                            }}
                                            placeholder="이메일 주소를 입력해 주세요." /* Please enter your email address. */
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={sPhoneNo}
                                            onChange={e => setPhoneNo(e.target.value)}
                                        />
                                        <label htmlFor='field5' className={classes.textfield_label}>외국인여부</label> {/* Email Address */}
                                        <RadioGroup
                                            aria-label="position"
                                            name="position"
                                            style={{
                                                width: '90%',
                                            }}
                                            value={sNation}
                                            onChange={event => setNation(event.target.value)}
                                        >
                                            <div
                                                style={{
                                                    width : '100%',
                                                    //paddingLeft : '8%',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="0"
                                                    control={<Radio color="secondary" />}
                                                    label="내국인"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio color="secondary" />}
                                                    label="외국인"
                                                    labelPlacement="end"
                                                />
                                            </div>
                                        </RadioGroup>
                                       
                                    </Fragment>
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    {props.bLoading && (
                        <div
                            style={{
                                // width: '100%',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!props.bLoading && (
                        <Fragment>
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.button_sign_request}
                                onClick={() => {
                                    const oSet = {};
                                    //고정
                                    oSet.cpId = 'MLSM1001';
                                    oSet.urlCode = '001001';
                                    //oSet.certNum = certNum;
                                    //oSet.date = date;
                                    oSet.certMet = 'M';
                                    oSet.plusInfo = props.token;
                                    oSet.tr_url = 'https://onlinesign.co.kr/onlinesign/docSignRequest/getKMCRec_Cert';
                                    //oSet.tr_url = 'http://onlinesign.mslk.co.kr/login_participant?token=qt';
                                    oSet.tr_add = 'N';
                                    
                                    //입력정보
                                    oSet.name = sName;
                                    oSet.birthDay = sBirthDay;
                                    oSet.gender = sGender;
                                    oSet.phoneNo = sPhoneNo;
                                    oSet.phoneCorp = sPhoneCorp;
                                    oSet.nation = sNation;

                                    props.onClickSetVerification({
                                        cert_data : oSet,
                                        //sName
                                        
                                    });
                                }}
                            >
                                확인
                            </Button>
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.button_cancel}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                취소
                            </Button>
                        </Fragment>
                    )}
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default Entity;