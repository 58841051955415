import React, { useRef, useState } from 'reactn';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        height: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    inputFile: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        borderRadius: 6,
        padding: 20,
        paddingTop: 6,
        paddingBottom: 6,
        width: '80%',
        marginTop: 20,
        cursor: 'pointer',
    },
}));

const Entity = props => {
    const classes = useStyles();

    const inputFile = useRef(null);
    const [sInputFileName, setInputFileName] = useState('');

    return (
        <div
            className={classes.container}
        >
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <input
                    type='file'
                    ref={inputFile}
                    onChange={e => {
                        if (inputFile.current.files.length > 0) {
                            setInputFileName(inputFile.current.files[0].name);
                            props.setAttachment(e.target.files[0]);
                        } else {
                            setInputFileName('');
                            props.setAttachment(null);
                        }
                    }}
                    id="computer_file_upload_input"
                    style={{
                        display: 'none',
                    }}
                />
                <label
                    htmlFor="computer_file_upload_input"
                    className={classes.inputFile}
                >
                    {(sInputFileName !== '') ? sInputFileName : '파일찾기'}
                </label>
            </div>
        </div>
    );
};

export default Entity;