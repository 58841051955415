import React, { Fragment, useState, useEffect, useGlobal } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import axios from "axios";

import { toast_success, toast_warning } from '../api/api';
import { formatDate } from '../lib/lib';

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        paddingLeft: 30,
        paddingRight: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const Dialog = withStyles(theme => ({
    paper: {
        width: '100%',
        maxWidth: 900,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 4,
        //     marginRight: 4,
        // },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
    button_sign_request: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    button_cancel: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            // color: 'white',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    dialogAction: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 140,
        },
    },
}));

function Entity(props) {

    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
	const [sApiUrl] = useGlobal('sApiUrl');

    const [bLoading, setLoading] = useState(false);

    const [aData, setData] = useState([]);

    useEffect(() => {
        if (props.open === true) {
            // console.log(props.docToEdit);
            // /onlinesign/docSignHistory/findByDocSignRequestIdx?docSignRequestIdx=549
            setLoading(true);
            setData([]);
            axios.create({
                headers: {
                    Authorization: `Bearer ${sToken}`,
                }
            }).get(`/${sApiUrl}/docSignHistory/findByDocSignRequestIdx?docSignRequestIdx=${props.docToEdit.id}`)
                .then(res => {
                    setLoading(false);
                    switch (res.data.status) {
                        case '000':
                            // toast_success('Request successfully sent');
                            if (res.data.entity !== undefined && res.data.entity !== null) {
                                setData(res.data.entity);
                            }
                            break;
                        default:
                            toast_warning(res.data.message);
                    }
                }).catch(err => {
                    setLoading(false);
                    toast_warning(`System error: ${JSON.stringify(err)}`);
                });
        }
    }, [sToken, sApiUrl, props.open, props.docToEdit]);
    
    return (
        <Fragment>
            <Dialog
                // onClose={props.onClose}
                aria-labelledby="dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogTitle onClose={props.onClickCancel}>
                    서명 진행 상태 정보
                </DialogTitle>
                <DialogContent dividers>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {/* <div>
                            <div>
                                서명 요청자 정보
                            </div>
                            <div>
                                /Requester information/
                            </div>
                        </div>
                        <Divider /> */}
                        <div>
                            <div>
                                서명 참여자 정보
                            </div>
                            {/* <div>
                                /Participants information/
                            </div> */}
                            {(props.docToEdit.docSignTargetList !== undefined && props.docToEdit.docSignTargetList !== null) && (
                                <>
                                    {props.docToEdit.docSignTargetList.map(item => (
                                        <div key={item.docSignTargetIdx} style={{ paddingLeft: 10 }}>
                                            - {item.targetName} ({item.targetEmail}/{item.targetPhone})
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                        <div>
                            <div>
                                서명 진행 히스토리
                            </div>
                            {/* <div>
                                /List of actions/
                            </div> */}
                            {bLoading && (
                                <div
                                    style={{
                                        // width: '100%',
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                    }}
                                >
                                    <CircularProgress disableShrink />
                                </div>
                            )}
                            {!bLoading && (
                                <>
                                    {aData.map(item => (
                                        <div key={item.docSignHistoryIdx} style={{ paddingLeft: 10 }}>
                                            - {formatDate(item.regDate)} <b>{item.signYn === 'Y' ? 'SIGNED' : 'Waiting...'}</b> {item.docSignTarget.targetName} ({item.docSignTarget.targetEmail}/{item.docSignTarget.targetPhone})
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions />
            </Dialog>
        </Fragment>
    );
};

export default Entity;