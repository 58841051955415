import { getGlobal } from 'reactn';
// import { ToastsStore } from 'react-toasts';

export const toast_success = (msg) => {
    getGlobal().toast.add(msg, 'success', 3000);
    // ToastsStore.success(msg, 10000);
}
export const toast_warning = (msg) => {
    getGlobal().toast.add(msg, 'warning', 3000);
    // ToastsStore.warning(msg, 10000);
}
export const toast_error = (msg) => {
    getGlobal().toast.add(msg, 'warning', 3000);
    // ToastsStore.warning(msg, 10000);
}
