import React, { setGlobal, useEffect, useGlobal, useState, useRef } from 'reactn';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import IconDelete from '@material-ui/icons/Delete';
// import IconCheck from '@material-ui/icons/Check';
import IconClose from '@material-ui/icons/Close';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import IconEdit from '@material-ui/icons/Edit';
import IconDescription from '@material-ui/icons/Description';
import IconSend from '@material-ui/icons/Send';
import axios from "axios";
// import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingOverlay from 'react-loading-overlay';

import Pagination from '../component/Pagination';
import FileUploader from '../component/FileUploader';
import DialogEditTemplateName from '../component/DialogEditTemplateName';

import { toast_success, toast_warning } from '../api/api';
import { navigatePage, useOnClickOutside, formatDate } from '../lib/lib';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
    { id: 'template_name', numeric: false, disablePadding: false, label: '문서명' },
    { id: 'edit_flag', numeric: false, disablePadding: false, label: '편집여부' },
    { id: 'created_date', numeric: false, disablePadding: false, label: '생성시간' },
    { id: 'updated_date', numeric: false, disablePadding: false, label: '업데이트 시간' },
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'scroll',
        [theme.breakpoints.up('sm')]: {
            overflowX: 'unset',
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableToolbar: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    button_create_template: {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        marginRight: 10,
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
    },
    searchPaper: {
        border: '1px solid #ccc',
        boxShadow: 'none',
        display: 'flex',
        transition: 'all .4s',
        width: '70%',
        '&:hover, &:focus-within': {
            width: '100%',
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 7,
        color: theme.palette.secondary.main,
    },
    iconButtonDel: {
        padding: 7,
        color: '#999',
    },
    tableRowTemplateName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: 300,
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 440,
        },
    },
}));

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount && rowCount > 0}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headCell.numeric ? { width: 200 } : {}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                fontSize: '1.4em',
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const StyledButton = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        width: 190,
        height: 50,
        border: 'none',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '1.2em',
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}))(Button);

function Entity(props) {
    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    
    const [bClickedStart, setClicketStart] = useGlobal('bClickedStart');

    const [bLoading, setLoading] = React.useState(false);

    const [sSearchQuery, setSearchQuery] = useState('');
    const [sQuery, setQuery] = useState('');
    const [rows, setRows] = useState([]);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openPopper, setOpenPopper] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [docToDel, setDocToDel] = React.useState({ id: -1, template_name: '' });
    const [openPopperEdit, setOpenPopperEdit] = React.useState(false);
    const [docToEdit, setDocToEdit] = React.useState({ id: -1, template_name: '' });

    const [bFileUploaderOpen, setFileUploaderOpen] = React.useState(false);
    const [bDialogEditTemplateName, setDialogEditTemplateName] = React.useState(false);
    
    const ref = useRef();

    const refDel = useRef();

    useOnClickOutside(ref, () => setOpenPopperEdit(false));

    useOnClickOutside(refDel, () => setOpenPopper(false));

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    function handleChangePage(newPage) {
        setPage(newPage);
    }

    // function handleChangeRowsPerPage(event) {
    //   setRowsPerPage(+event.target.value);
    //   setPage(0);
    // }

    const callbackFileUpload = res => {
        setLoading(false);
        switch (res.data.status) {
            case '000':
                if (res.data.entity !== undefined && res.data.entity !== null) {
                    toast_success('문서가 업로드되었습니다.');
                    setGlobal({
                        iEditingDocId: (res.data.entity !== undefined && res.data.entity !== null) ? res.data.entity.templateIdx : -1,
                        sEditingDocTemplateName: (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.templateName !== null) ? res.data.entity.templateName : '',
                        sEditingDocTemplateApiKey: (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.apiKey !== null) ? res.data.entity.apiKey : '',
                        //sReferrerDocTempEdit: 'fileuploader',
                        sReferrerDocTempEdit: 'documenttemplatelist',
                    });
                                                
                    setFileUploaderOpen(false);
                    //navigatePage('/document_template/document_template_creation', props);
                    navigatePage('/document_template/template_editing', props);
                } else {
                    toast_warning('문서업로드에 실패하였습니다.');
                }
                break;
            default:
                toast_warning(res.data.message);
        }
    };

    const callbackFileUploadCatch = err => {
        setLoading(false);
        toast_warning(`System error: ${JSON.stringify(err)}`);
    }

    const isSelected = id => selected.indexOf(id) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData();
        setPage(0);
    }, [sApiUrl, sToken, sQuery]);

    const fetchData = () => {
        let sUrl = `/${sApiUrl}/docTemplate`;
        if (sQuery !== null && sQuery !== undefined && sQuery !== '') {
            sUrl = `/${sApiUrl}/docTemplate/search?content=${sQuery}`;
        }
        setLoading(true);
        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).get(sUrl)
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    setRows(res.data.entity.map(item => {
                        let sCreatedDate = '';
                        if (item.regDate !== null) {
                            sCreatedDate = formatDate(item.regDate);
                            // const oDate = new Date(item.regDate);
                            // sCreatedDate = oDate.toDateString();
                        }
                        let sUpdatedDate = '';
                        if (item.updDate !== null) {
                            sUpdatedDate = formatDate(item.updDate);
                            // const oUpdDate = new Date(item.updDate);
                            // sUpdatedDate = oUpdDate.toDateString();
                        }
                        
                        return {
                            id: item.templateIdx,
                            template_name: item.templateName,
                            edit_flag: item.edit_flag == 'Y' ? '완료' : '미완료',
                            apiKey: item.apiKey,
                            created_date: sCreatedDate,
                            updated_date: sUpdatedDate,
                        };
                    }));
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
    };

    useEffect(() => {
        if (bClickedStart) {
            //setFileUploaderOpen(true);
            //playTutorial();
        }
        setClicketStart(false);
    }, [bClickedStart]);

    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <div className={classes.tableWrapper}>
                <div className={classes.tableToolbar}>
                    <div style={{ display: 'inline-block'}}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button_create_template}
                            onClick={() => {
                                setFileUploaderOpen(true);
                                // props.history.push('/document_template/document_template_creation');
                            }}
                        >
                            문서 업로드
                        </Button>
                        {/**  
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button_create_template}
                            onClick={() => {
                                setGlobal({
                                    aSignRequestParticipant: [],
                                });
                                setFileUploaderOpen(true);
                            }}
                        >
                            다운로드
                        </Button>   
                          
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button_create_template}
                            onClick={() => {
                                setGlobal({
                                    aSignRequestParticipant: [],
                                });
                                setFileUploaderOpen(true);
                            }}
                        >
                            삭제
                        </Button>   
                        */}
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Paper className={classes.searchPaper}>
                            <form
                                onSubmit={event => {
                                    event.preventDefault();
                                    setQuery(sSearchQuery);
                                }}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <InputBase
                                    className={classes.input}
                                    placeholder="검색어를 입력해 주세요."
                                    inputProps={{ 'aria-label': '검색어를 입력해 주세요.' }}
                                    value={sSearchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                />
                                <IconButton className={classes.iconButton} aria-label="search" type="submit">
                                    <SearchIcon />
                                </IconButton>
                            </form>
                        </Paper>
                    </div>
                </div>
                <LoadingOverlay
                    active={bLoading}
                    spinner
                    text='Loading...'
                    styles={{
                        overlay: {
                            position: 'fixed',
                            height: '100vh',
                            width: '100%',
                            top: 0,
                            left: 0,
                            display: 'flex',
                            textAlign: 'center',
                            fontSize: '1.2em',
                            color: '#FFF',
                            background: 'rgba(0,0,0,0.7)',
                            zIndex: 800,
                            transition: 'opacity 500ms ease-in',
                            opacity: 1,
                        },
                        // wrapper: {},
                        // content: {},
                        // spinner: {}
                    }}
                >
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    onChange={event => handleClick(event, row.id)}
                                                />
                                            </TableCell>
                                            
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div
                                                        className={classes.tableRowTemplateName}
                                                        onClick={e => {
                                                            setOpenPopper(false);
                                                            setAnchorEl(e.currentTarget);
                                                            setDocToEdit(row);
                                                            if (anchorEl === e.currentTarget) {
                                                                setOpenPopperEdit(!openPopperEdit);
                                                            } else {
                                                                setOpenPopperEdit(true);
                                                            }
                                                        }}
                                                    >
                                                        {row.template_name}
                                                    </div>
                                                    <IconButton
                                                        className={classes.iconButtonDel}
                                                        aria-label="delete"
                                                        onClick={e => {
                                                            setOpenPopperEdit(false);
                                                            setAnchorEl(e.currentTarget);
                                                            setDocToDel(row);
                                                            if (anchorEl === e.currentTarget) {
                                                                setOpenPopper(!openPopper);
                                                            } else {
                                                                setOpenPopper(true);
                                                            }
                                                        }}
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">{row.edit_flag}</TableCell>
                                            <TableCell align="center">{row.created_date}</TableCell>
                                            <TableCell align="center">{row.updated_date}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && rows.length > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <Popper open={openPopperEdit} anchorEl={anchorEl} placement="bottom-start" transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper
                                    ref={ref}
                                    style={{
                                        width: 270,
                                        //height: 150,
                                        height: 210,
                                        padding: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <div>
                                        <Typography className={classes.typography} style={{ fontWeight: 'bold', textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {docToEdit.template_name}
                                        </Typography>
                                    </div>
                                    <List>
                                        <ListItem
                                            button
                                            onClick={() => {
                                                // console.log(docToEdit);
                                                setLoading(true);
                                                
                                                setGlobal({
                                                    iEditingDocId: docToEdit.id,
                                                    sEditingDocTemplateName: docToEdit.template_name,
                                                    sEditingDocTemplateApiKey: docToEdit.apiKey,
                                                    sReferrerDocTempEdit: 'signParticipant',
                                                });
                                               
                                                navigatePage('/my_document/sign_participant', props);
                                            }}
                                        >
                                            <ListItemIcon className={classes.listItemIcon}><IconSend /></ListItemIcon>
                                            <ListItemText className={classes.listItemText} primary={'서명 요청'} />
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setGlobal({
                                                    iEditingDocId: docToEdit.id,
                                                    sEditingDocTemplateName: docToEdit.template_name,
                                                    sEditingDocTemplateApiKey: docToEdit.apiKey,
                                                    sReferrerDocTempEdit: 'documenttemplatelist',
                                                });
                                                navigatePage('/document_template/template_editing', props);
                                            }}
                                        >
                                            <ListItemIcon className={classes.listItemIcon}><IconEdit /></ListItemIcon>
                                            <ListItemText className={classes.listItemText} primary={'문서 수정'} />
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setGlobal({
                                                    iEditingDocId: docToEdit.id,
                                                    sEditingDocTemplateName: docToEdit.template_name,
                                                    sEditingDocTemplateApiKey: docToEdit.apiKey,
                                                    sReferrerDocTempEdit: 'documenttemplatelist',
                                                });
                                                setDialogEditTemplateName(true);
                                            }}
                                        >
                                            <ListItemIcon className={classes.listItemIcon}><IconDescription /></ListItemIcon>
                                            <ListItemText className={classes.listItemText} primary={'문서명 변경'} />
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                    <Popper open={openPopper} anchorEl={anchorEl} placement="bottom" transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper
                                    ref={refDel}
                                    style={{
                                        width: 270,
                                        height: 150,
                                        padding: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <Typography className={classes.typography} style={{ textAlign: 'center' }}>정말 삭제하시겠습니까?</Typography>
                                    <Typography className={classes.typography} style={{ fontWeight: 'bold', textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {docToDel.template_name}
                                    </Typography>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            // padding: 10,
                                        }}
                                    >
                                        <IconButton
                                            className={classes.iconButtonDel}
                                            aria-label="yes"
                                            onClick={() => {
                                                setOpenPopper(false);
                                                setLoading(true);
                                                axios.create({
                                                    headers: {
                                                        Authorization: `Bearer ${sToken}`,
                                                    }
                                                }).delete(`/${sApiUrl}/docTemplate/${docToDel.id}`)
                                                    .then(res => {
                                                        setLoading(false);
                                                        switch (res.data.status) {
                                                            case '000':
                                                                toast_success('삭제되었습니다');
                                                                if (sQuery === '') {
                                                                    setQuery(null);
                                                                } else {
                                                                    setQuery('');
                                                                }
                                                                break;
                                                            default:
                                                                toast_warning(res.data.message);
                                                        }
                                                    }).catch(err => {
                                                        setLoading(false);
                                                        toast_warning(`System error: ${JSON.stringify(err)}`);
                                                    });
                                            }}
                                        >
                                            <IconDelete />
                                        </IconButton>
                                        <IconButton
                                            className={classes.iconButtonDel}
                                            aria-label="no"
                                            onClick={() => {
                                                setOpenPopper(false);
                                            }}
                                        >
                                            <IconClose />
                                        </IconButton>
                                    </div>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </LoadingOverlay>
            </div>
            {rows.length === 0 && (
                <div
                    style={{
                        width: '100%',
                        height: 350,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            padding: 0,
                        }}
                    >
                        생성된 문서가 없습니다.
                        </div>
                    <div
                        style={{
                            padding: 20,
                        }}
                    >
                        새로운 문서을 업로드하여 쉽게 사용하실 수 있습니다.
                        </div>
                    <div
                        style={{
                            padding: 20,
                        }}
                    >
                        <StyledButton
                            onClick={() => setFileUploaderOpen(true)}
                        >
                            문서 업로드 하기
                            </StyledButton>
                    </div>
                </div>
            )}
            {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
            'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
            'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
            <Pagination
                page={page}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                style={{
                    marginBottom: 50,
                }}
            />
            <FileUploader
                open={bFileUploaderOpen}
                bLoading={bLoading}
                onClose={() => setFileUploaderOpen(false)}
                nextStep={par => {
                    if (par.type === 'computer') {
                        const data = new FormData();
                        data.append('file', par.file);
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/upload`, data)
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if (par.type === 'googledrive') {
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/googledrive`, { fileId: par.file, fileName: par.filename, accessToken: par.token })
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if (par.type === 'dropbox') {
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/dropbox`, { fileId: par.file, fileName: par.filename, accessToken: par.token })
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if (par.type === 'onedrive') {
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/onedrive`, { fileUrl: par.downloadlink })
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if (par.type === 'box') {
                        // console.log(par);
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/box`, { fileUrl: par.authenticated_download_url, fileName: par.name, accessToken: par.token })
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if (par.type === 'my_templates') {
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/fromTemplate`, { templateIdx: par.file })
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                }}
            />
            <DialogEditTemplateName
                open={bDialogEditTemplateName}
                bLoading={bLoading}
                onClose={() => setDialogEditTemplateName(false)}
                onClickCreate={(tempName) => {
                    setLoading(true);
                    
                    axios.create({
                        headers: {
                            Authorization: `Bearer ${sToken}`,
                        }
                    }).post(`/${sApiUrl}/docTemplate/updateTemplateName`, {
                        templateName: tempName,
                        templateIdx: docToEdit.id,
                    })
                    .then(res => {
                        switch (res.data.status) {
                            case '000':
                                setLoading(false);
                                toast_success('변경되었습니다.');
                                setDialogEditTemplateName(false);
                                fetchData();
                                break;
                            default:
                                toast_warning(res.data.message);
                        }
                    }).catch(err => {
                        setLoading(false);
                        toast_warning(`System error: ${JSON.stringify(err)}`);
                    });
                }}
                onClickCancel={() => {
                    setDialogEditTemplateName(false);
                }}
            />
        </div>
    );
}

export default Entity;