import React, { useEffect, useRef } from 'reactn';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    canvas: {
        border: '1px solid grey',
        width: 600,
        height: 300,
        marginBottom: 10,
        backgroundColor: '#eee',
    },
    clear_canvas_btn: {
        marginRight: 10,
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 1,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
}));

const Entity = props => {

    const classes = useStyles();

    const canvas = useRef();

    useEffect(() => {
        // console.log(canvas.current);
        if (canvas.current === null) {
            return;
        }
        // return;

        const context = canvas.current.getContext("2d");
        const boundings = canvas.current.getBoundingClientRect();

        let mouseX = 0;
        let mouseY = 0;
        context.strokeStyle = 'black';
        context.lineWidth = 5;
        let isDrawing = false;

        const fn1 = event => {
            setMouseCoordinates(event);
            isDrawing = true;

            context.beginPath();
            context.moveTo(mouseX, mouseY);
        };

        const fn2 = event => {
            setMouseCoordinates(event);

            if (isDrawing) {
                context.lineTo(mouseX, mouseY);
                context.stroke();
            }
        };

        const fn3 = event => {
            setMouseCoordinates(event);
            const data = canvas.current.toDataURL();
            if (isDrawing) {
                // console.log(data);
                props.setCanvasImg(data);
            }
            isDrawing = false;
        };

        canvas.current.addEventListener('mousedown', fn1);

        canvas.current.addEventListener('mousemove', fn2);

        canvas.current.addEventListener('mouseup', fn3);
        
        canvas.current.addEventListener('mouseout', fn3);

        function setMouseCoordinates(event) {
            mouseX = event.clientX - boundings.left;
            mouseY = event.clientY - boundings.top;
        }

        // Handle Save Button
        // var saveButton = document.getElementById('save');

        // saveButton.addEventListener('click', function() {
        //     var imageName = prompt('Please enter image name');
        //     var canvasDataURL = canvas.toDataURL();
        //     var a = document.createElement('a');
        //     a.href = canvasDataURL;
        //     a.download = imageName || 'drawing';
        //     a.click();
        // });

        return () => {
            canvas.current.removeEventListener('mousedown', fn1);
            canvas.current.removeEventListener('mousemove', fn2);
            canvas.current.removeEventListener('mouseup', fn3);
            canvas.current.removeEventListener('mouseout', fn3);
        };
    }, []);


    return (
        <div
            className={classes.container}
        >
            <div
                style={{
                    // backgroundColor: 'red',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <canvas
                    ref={canvas}
                    width="600"
                    height="300"
                    className={classes.canvas}
                />
            </div>
            <div
                style={{
                    // backgroundColor: 'green',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.clear_canvas_btn}
                    onClick={() => {
                        if (canvas.current === null) {
                            return;
                        }
                        const context = canvas.current.getContext("2d");
                        context.clearRect(0, 0, canvas.current.width, canvas.current.height);
                    }}
                >
                    모두 지우기
                </Button>
            </div>
        </div>
    );
};

export default Entity;