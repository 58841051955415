import React, { useEffect, useRef } from 'react';

function Toast({ children, remove, status, duration }) {
  const removeRef = useRef();
  removeRef.current = remove;

  useEffect(() => {
    // const duration = duration;
    const id = setTimeout(() => removeRef.current(), duration);

    return () => clearTimeout(id);
  }, []);

  return (
    /**
    <div className={`toast-${status}`}>
      <div className="toast__text">
        { children }
      </div>
      <div className="toast__close-btn-wrapper">
        <button onClick={remove} className="toast__close-btn">x</button>
      </div>
    </div>
     */
    <div className="pop-wrap">
      <div className="pop">
          <h1>알림</h1>
          <h3>{ children }</h3>
          <button onClick={remove}>확인</button>
      </div>
    </div>
  );
}

export default Toast;