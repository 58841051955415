import React, { useState, useEffect, useGlobal, Fragment } from 'reactn';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import {
	TextField,
	Button,
} from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import LoadingOverlay from 'react-loading-overlay';

import { toast_success, toast_warning } from '../api/api';

import axios from "axios";

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'space-around',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
}))(MuiDialogContent);

const Dialog = withStyles(theme => ({
	paper: {
		width: '100%',
		maxWidth: 350,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 4,
			marginRight: 4,
			maxWidth: '96%',
		},
		// [theme.breakpoints.down('sm')]: {
		//     marginLeft: 4,
		//     marginRight: 4,
		// },
	},
	paperScrollBody: {
		[theme.breakpoints.down('xs')]: {
			maxWidth: '96% !important',
		},
	}
}))(MuiDialog);

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			// hidden={value !== index} // this cause UI problem on textbox label
			style={{
				...value !== index ? {
					height: 0,
					width: 0,
					overflow: 0,
					position: 'fixed',
					top: -99999,
				} : {}
			}}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box
				p={3}
				style={{
					minHeight: 'calc(100vh - 220px)',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'stretch',
				}}
			>
				{children}
			</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
	button: {
		// margin: theme.spacing(1),
		width: '100%',
		marginTop: 16,
		height: 40,
		// borderRadius: 0,

		borderWidth: 2,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
	},
	buttonSns: {
		width: '48%',
		marginTop: 16,
		height: 40,
		borderRadius: 0,
	},
	inputField: {
		borderRadius: 0,
	},
	bottomLink: {
		color: 'black',
		fontSize: 12,
		textDecoration: 'none',
		marginLeft: 10,
		marginRight: 10,
		textAlign: 'center',
	},
	table: {
        width: '100%',
    },
}));

const CssTextField = withStyles({
	root: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderRadius: 0,
			},
			'&:hover fieldset': {
				//   borderColor: 'yellow',
			},
			'&.Mui-focused fieldset': {
				//   borderColor: 'green',
			},
		},
	},
})(TextField);

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headCell.numeric ? { width: 150, whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                fontSize: '1.4em',
                            }}
                        >
                            {headCell.label}
                            {/* {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null} */}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
	headCells: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
    { id: 'account_info', numeric: false, disablePadding: false, label: '계정정보' },
    { id: 'payment_info', numeric: false, disablePadding: false, label: '요금정보' },
    { id: 'payment_purpose', numeric: false, disablePadding: false, label: '결제내역' },
];

function Entity() {
	const classes = useStyles();

	const [sToken, setToken] = useGlobal('sToken');
	const [sApiUrl] = useGlobal('sApiUrl');

	const [iSelectedTab, setSelectedTab] = useState(0);
	const [bLoading, setLoading] = useState(false);

	const [sName, setName] = useState('');
	const [sEmail, setEmail] = useState('');

	const [bHasCompany, setHasCompany] = useState(false);

	const [sCompanyName, setCompanyName] = useState('');
	const [sPosition, setPosition] = useState('');
	const [sRegistration, setRegistration] = useState('');

	const [bPasswordDialogOpen, setPasswordDialogOpen] = useState(false);
	const [sPassOld, setPassOld] = useState('');
	const [sPassNew, setPassNew] = useState('');
	const [sPassNewConfirm, setPassNewConfirm] = useState('');

	const [rows, setRows] = useState([]);

	const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('update_time');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);

	useEffect(() => {
		let sUrl = `/${sApiUrl}/user/settings`;
		setLoading(true);
		axios.create({
			headers: {
				Authorization: `Bearer ${sToken}`,
			}
		}).get(sUrl)
			.then(res => {
				setLoading(false);
				switch (res.data.status) {
					case '000':
						if (res.data.entity !== undefined && res.data.entity !== null) {
							setName(res.data.entity.userName);
							setEmail(res.data.entity.userEmail);
							if (res.data.entity.userCompany !== undefined && res.data.entity.userCompany !== null) {
								setHasCompany(true);
								setCompanyName(res.data.entity.userCompany.companyName);
							} else {
								setHasCompany(false);
							}
						} else {
						}
						break;
					default:
						toast_warning(res.data.message);
				}
			}).catch(err => {
				setLoading(false);
				toast_warning(`System error: ${JSON.stringify(err)}`);
			});
	}, [sApiUrl, sToken]);

	function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
	}
	
	const isSelected = id => selected.indexOf(id) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				// justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					backgroundColor: 'white',
					margin: 20,
					maxWidth: 800,
					minWidth: 500,
					width: '100%',
				}}
			>
				<AppBar position="static">
					<Tabs
						value={iSelectedTab}
						onChange={(event, newValue) => setSelectedTab(newValue)}
						aria-label="settings-tab"
					>
						<Tab label="계정정보" />
						<Tab label="요금정보" />
						<Tab label="결제내역" />
					</Tabs>
				</AppBar>
				<TabPanel
					value={iSelectedTab}
					index={0}
				>
					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							paddingBottom: 100,
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<div
								style={{
									maxWidth: 400,
								}}
							>
								<form
									onSubmit={event => {
										event.preventDefault();
										if (sName.length > 0 && sEmail.length > 0) {
											const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
											if (!re.test(sEmail)) {
												toast_warning('Email is not valid');
												return;
											}
											setLoading(true);
											const sUrl = `/${sApiUrl}/user/settings`;
											axios.create({
												headers: {
													Authorization: `Bearer ${sToken}`,
												}
											}).put(sUrl, { userName: sName, userEmail: sEmail })
												.then(res => {
													setLoading(false);
													if (res.data.status === '000') {
														setToken(res.data.entity);
														toast_success("Successfully updated");
													} else {
														toast_warning("Request failed");
													}
												}).catch(err => {
													setLoading(false);
													toast_warning(err.response.statusText);
												});
										} else {
											toast_warning("Name and email is required");
										}
									}}
								>
									<Typography
										component="h2"
									>
										* 필수 정보
									</Typography>
									<CssTextField
										label="이름"
										variant="outlined"
										fullWidth
										value={sName}
										onChange={event => {
											setName(event.target.value);
										}}
										margin="dense"
									/>
									<CssTextField
										label="이메일"
										variant="outlined"
										fullWidth
										value={sEmail}
										onChange={event => {
											setEmail(event.target.value);
										}}
										margin="dense"
										className={classes.inputField}
									/>
									<div
										style={{
											display: 'grid',
											gridTemplateColumns: '50% 50%',
										}}
									>
										<div
											style={{
												paddingRight: 2,
											}}
										>
											<Button
												type="submit"
												// variant="contained"
												// color="primary"
												variant="outlined"
                            					color="secondary"
												className={classes.button}
												disabled={bLoading}
											>
												저장하기
											</Button>
										</div>
										<div
											style={{
												paddingLeft: 2,
											}}
										>
											<Button
												type="button"
												// variant="contained"
												// color="primary"
												variant="outlined"
                            					color="secondary"
												className={classes.button}
												disabled={bLoading}
												onClick={() => setPasswordDialogOpen(true)}
											>
												비밀번호 수정
											</Button>
											<Dialog
												onClose={() => setPasswordDialogOpen(false)}
												aria-labelledby="dialog-title"
												open={bPasswordDialogOpen}
												scroll="body"
											>
												<DialogTitle onClose={() => setPasswordDialogOpen(false)}>
													비밀번호 수정
												</DialogTitle>
												<DialogContent dividers>
													<div
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														{bLoading && (
															<div
																style={{
																	// width: '100%',
																	paddingLeft: 20,
																	paddingRight: 20,
																}}
															>
																<CircularProgress disableShrink />
															</div>
														)}
														{!bLoading && (
															<div
																style={{
																	width: 300,
																	// paddingLeft: 40,
																	// paddingRight: 40,
																}}
															>
																<CssTextField
																	label="현재 비밀번호"
																	variant="outlined"
																	fullWidth
																	type="password"
																	value={sPassOld}
																	onChange={event => {
																		setPassOld(event.target.value);
																	}}
																	margin="dense"
																/>
																<CssTextField
																	label="신규 비밀번호"
																	variant="outlined"
																	fullWidth
																	type="password"
																	value={sPassNew}
																	onChange={event => {
																		setPassNew(event.target.value);
																	}}
																	margin="dense"
																	className={classes.inputField}
																/>
																<CssTextField
																	label="신규 비밀번호 확인"
																	variant="outlined"
																	fullWidth
																	type="password"
																	value={sPassNewConfirm}
																	onChange={event => {
																		setPassNewConfirm(event.target.value);
																	}}
																	margin="dense"
																	className={classes.inputField}
																/>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'space-between',
																		alignItems: 'center',
																	}}
																>
																	<Button
																		// variant="contained"
																		// color="primary"
																		variant="outlined"
                            											color="secondary"
																		className={classes.button}
																		disabled={bLoading}
																		style={{
																			marginRight: 2,
																		}}
																		onClick={() => {
																			if (sPassOld.length > 0 && sPassNew.length > 0 && sPassNew === sPassNewConfirm) {
																				const re = /^[A-Za-z\d!@#$%^&_\-|?,.\\/=+*]{8,15}$/;
																				if (!re.test(sPassNewConfirm)) {
																					toast_warning('should be 8-15 letters consist from numbers, sybmols and alphabet');
																					return;
																				}
																				setLoading(true);
																				const sUrl = `/${sApiUrl}/user/password`;
																				axios.create({
																					headers: {
																						Authorization: `Bearer ${sToken}`,
																					}
																				}).put(sUrl, { password: sPassOld, newPassword: sPassNew })
																					.then(res => {
																						setLoading(false);
																						if (res.data.status === '000') {
																							setPasswordDialogOpen(false);
																							toast_success("Successfully updated");
																						} else {
																							toast_warning(res.data.message);
																						}
																					}).catch(err => {
																						setLoading(false);
																						toast_warning(err.response.statusText);
																					});
																			} else {
																				toast_warning("Please type old and new password correctly");
																			}
																		}}
																	>
																		저장하기
																	</Button>
																	<Button
																		// variant="contained"
																		// color="primary"
																		variant="outlined"
                            											color="secondary"
																		className={classes.button}
																		disabled={bLoading}
																		style={{
																			marginLeft: 2,
																		}}
																		onClick={() => setPasswordDialogOpen(false)}
																	>
																		취소
																	</Button>
																</div>
															</div>
														)}

													</div>
												</DialogContent>
											</Dialog>
										</div>
									</div>
								</form>
							</div>
							<Divider style={{
								width: '100%',
								minWidth: 400,
								marginTop: 15,
								marginBottom: 15,
							}} />
							<div
								style={{
									maxWidth: 400,
								}}
							>
								{bHasCompany && (
									<form
										onSubmit={event => {
											event.preventDefault();
											// if (sCompanyName.length > 0 && sPosition.length > 0 && sRegistration.length > 0) {
											if (sCompanyName.length > 0) {
												setLoading(true);
												let sUrl = `/${sApiUrl}/user/company`;
												axios.create({
													headers: {
														Authorization: `Bearer ${sToken}`
													}
												}).put(sUrl, { companyName: sCompanyName, companySignNum: '01' })
													.then(res => {
														setLoading(false);
														if (res.data.status === '000') {
															toast_success("Successfully updated");
														} else {
															toast_warning("Request failed");
														}
													}).catch(err => {
														setLoading(false);
														toast_warning(err.response.statusText);
													});
											} else {
												toast_warning("Company name is required");
											}
										}}
									>
										<Typography
											component="h2"
										>
											* 부가 정보
										</Typography>
										<CssTextField
											label="회사"
											variant="outlined"
											fullWidth
											value={sCompanyName}
											onChange={event => {
												setCompanyName(event.target.value);
											}}
											margin="dense"
										/>
										<CssTextField
											label="직급"
											variant="outlined"
											fullWidth
											value={sPosition}
											onChange={event => {
												setPosition(event.target.value);
											}}
											margin="dense"
											className={classes.inputField}
										/>
										<CssTextField
											label="회사번호"
											variant="outlined"
											fullWidth
											value={sRegistration}
											onChange={event => {
												setRegistration(event.target.value);
											}}
											margin="dense"
											className={classes.inputField}
										/>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											<Button
												type="submit"
												// variant="contained"
												// color="primary"
												variant="outlined"
                            					color="secondary"
												className={classes.button}
												disabled={bLoading}
												style={{ width: '50%' }}
											>
												저장하기
											</Button>
										</div>
									</form>
								)}
							</div>
						</div>
					</div>
				</TabPanel>
				<TabPanel
					value={iSelectedTab}
					index={1}
				>
					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							paddingBottom: 200,
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<div
								style={{
									maxWidth: 400,
								}}
							>
								<form
									onSubmit={event => {
										event.preventDefault();
										// if (sName.length > 0 && sEmail.length > 0) {
										// 	const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
										// 	if (!re.test(sEmail)) {
										// 		toast_warning('Email is not valid');
										// 		return;
										// 	}
										// 	setLoading(true);
										// 	const sUrl = `/${sApiUrl}/user/settings`;
										// 	axios.create({
										// 		headers: {
										// 			Authorization: `Bearer ${sToken}`,
										// 		}
										// 	}).put(sUrl, { userName: sName, userEmail: sEmail })
										// 		.then(res => {
										// 			setLoading(false);
										// 			if (res.data.status === '000') {
										// 				toast_success("Successfully updated");
										// 			} else {
										// 				toast_warning("Request failed");
										// 			}
										// 		}).catch(err => {
										// 			setLoading(false);
										// 			toast_warning(err.response.statusText);
										// 		});
										// } else {
										// 	toast_warning("Name and email is required");
										// }
									}}
								>
									<Typography
										component="h2"
									>
										* 요금정보
									</Typography>
									<CssTextField
										label="요금제"
										variant="outlined"
										fullWidth
										// value={sName}
										// onChange={event => {
										// 	setName(event.target.value);
										// }}
										margin="dense"
									/>
									<CssTextField
										label="잔여 건수"
										variant="outlined"
										fullWidth
										// value={sEmail}
										// onChange={event => {
										// 	setEmail(event.target.value);
										// }}
										margin="dense"
										className={classes.inputField}
									/>
									<div
										style={{
											display: 'grid',
											gridTemplateColumns: '50% 50%',
										}}
									>
										<div
											style={{
												paddingRight: 2,
											}}
										>
											<Button
												type="submit"
												// variant="contained"
												// color="primary"
												variant="outlined"
                            					color="secondary"
												className={classes.button}
												disabled={bLoading}
											>
												요금제 변경
											</Button>
										</div>
										<div
											style={{
												paddingLeft: 2,
											}}
										>
											<Button
												type="button"
												// variant="contained"
												// color="primary"
												variant="outlined"
                            					color="secondary"
												className={classes.button}
												disabled={bLoading}
												// onClick={() => setPasswordDialogOpen(true)}
											>
												결제
											</Button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</TabPanel>
				<TabPanel
					value={iSelectedTab}
					index={2}
				>
					<LoadingOverlay
						active={bLoading}
						spinner
						text='Loading...'
						styles={{
							overlay: {
								position: 'fixed',
								height: '100vh',
								width: '100%',
								top: 0,
								left: 0,
								display: 'flex',
								textAlign: 'center',
								fontSize: '1.2em',
								color: '#FFF',
								background: 'rgba(0,0,0,0.7)',
								zIndex: 800,
								transition: 'opacity 500ms ease-in',
								opacity: 1,
							},
							wrapper: {
								width: '100%',
							},
							// content: {},
							// spinner: {}
						}}
					>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							size={'medium'}
						>
							<EnhancedTableHead
								headCells={headCells}
								classes={classes}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
							/>
							<TableBody>
								{stableSort(rows, getSorting(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = isSelected(row.id);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}
											>
												{headCells.map(column => {
													return (
														<TableCell
															key={`${row.id}-${column.id}`}
															align="left"
														>
															{row[column.id]}
														</TableCell>
													);
												})}
												{/* <TableCell component="th" id={labelId} scope="row" padding="none">
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
														}}
													>
														<div
															className={classes.tableRowTemplateName}
															onClick={e => {
																// setOpenPopper(false);
																// setAnchorEl(e.currentTarget);
																// setDocToEdit(row);
																// if (anchorEl === e.currentTarget) {
																// 	setOpenPopperEdit(!openPopperEdit);
																// } else {
																// 	setOpenPopperEdit(true);
																// }
															}}
														>
															{row.template_name}
														</div>
														<IconButton
															className={classes.iconButtonDel}
															aria-label="delete"
															onClick={e => {
																// setOpenPopperEdit(false);
																// setAnchorEl(e.currentTarget);
																// setDocToDel(row);
																// if (anchorEl === e.currentTarget) {
																// 	setOpenPopper(!openPopper);
																// } else {
																// 	setOpenPopper(true);
																// }
															}}
														>
															<IconDelete />
														</IconButton>
													</div>
												</TableCell>
												<TableCell align="right">{row.created_date}</TableCell>
												<TableCell align="right">{row.updated_date}</TableCell> */}
											</TableRow>
										);
									})}
								{emptyRows > 0 && rows.length > 0 && (
									<TableRow style={{ height: 49 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</LoadingOverlay>
				</TabPanel>
			</div>
		</div>
	);
}

export default Entity;