import React, { useEffect, useState, useGlobal, Fragment } from 'reactn';
import queryString from 'query-string';
import {
    Typography,
    Button,
    Divider,
    Checkbox,
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { navigatePage, ordinal_suffix_of } from '../lib/lib';
import { Document } from 'react-pdf';

import axios from "axios";

import { toast_success, toast_warning } from '../api/api';
import { autoFormatKrNumber } from '../lib/lib';
import DialogConfirm from '../component/DialogConfirm';

const useStyles = makeStyles(theme => ({
    button: {
        // margin: theme.spacing(1),
        width: '100%',
        marginTop: 16,
        height: 40,
        borderRadius: 0,
    },
    buttonSns: {
        width: '48%',
        marginTop: 16,
        height: 40,
        borderRadius: 0,
    },
    inputField: {
        borderRadius: 0,
    },
    bottomLink: {
        color: 'black',
        fontSize: 12,
        textDecoration: 'none',
        marginLeft: 10,
        marginRight: 10,
    },
    textInputfield: {
        marginTop: 5,
        marginBottom: 5,
    },
    button_wizard_next: {
        width: '35%;',
        marginLeft: 5,
        height: 56,
        //marginTop: 19,
        //marginBottom: 24,
        borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));

const Entity = props => {

    const [token, setToken] = useState('');
    const [bLoading, setLoading] = useState(false);

    const [sApiUrl] = useGlobal('sApiUrl');
    const [sViewerDomainName] = useGlobal('sViewerDomainName');
    const [oConfirmData, setConfirmData] = useState({});

    const classes = useStyles();

    const [sPkey, setPkey] = useState('');
    const [sCkey, setCkey] = useState('');
    
    useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        
        if (parsed.pkey !== undefined && parsed.ckey !== undefined) {
            setPkey(parsed.pkey);
            setCkey(parsed.ckey);
        }
        /*
        if (parsed.token !== undefined) {
            setToken(parsed.token);

            
        } else {
            props.history.push('/login');
        }
        */
        window.addEventListener("message", function(e) {
            setConfirmData(e.data);
        });
    }, [props]);

    useEffect(() => {
        if(oConfirmData.confirm) {
            if(window.opener) {
                window.close();
            }
        }
	}, [oConfirmData]);

    return (
        <div
            style={{
                // backgroundColor: 'green',
                height: '100vh',
                padding: 10,
                //paddingTop: 50,
                paddingBottom: 30,
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div 
                style={{
                    // backgroundColor: 'red',
                    //fontSize: 42,
                    fontWeight: 100,
                    textAlign : 'center',
                    letterSpacing : '3px',
                    //marginBottom : '-20px',
                }}
            >
                    <img 
                        src="images/logo1-6.png" 
                        alt=""
                        style={{
                            width:'100%',
                            maxWidth:'290px',
                            minWidth: '200px',
                        }}
                    />
            </div>
            <div
                style={{
                    flex: 1,
                    // backgroundColor: 'green',
                    width: '100%',
                    height: '100%',
                }}
            >
                <iframe                    
                    //운영
                    //src={`https://onlinesign.co.kr/viewer/pdfView.html?doc_data_id=${sCkey}`}
                    //개발
                    //src={`http://pilot.onlinesign.co.kr/viewer/pdfView.html?doc_data_id=${sCkey}`}
                    //로컬
                    src={`${sViewerDomainName}/viewer/pdfView.html?doc_data_id=${sCkey}`}                                        
                    style={{
                        flex: 1,
                        border: 'none',
                        width: '100%',
                        height: '100%',
                    }}
                />
            </div>
            <div>
                Copyright © 2020 OnlineSign All Rights Reserved.
            </div>
        </div>
    );
};

export default Entity;