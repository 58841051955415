import React, { useEffect,Fragment, useState, useGlobal, setGlobal } from 'reactn';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";

import { toast_success, toast_warning } from '../api/api';
import { navigatePage, ordinal_suffix_of } from '../lib/lib';
import DialogTemplateSignRequest from '../component/DialogTemplateSignRequest';
import DialogCopyTemplate from '../component/DialogCopyTemplate';
import DialogConfirm from '../component/DialogConfirm';

const useStyles = makeStyles(theme => ({
    textfield_label: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    button_create_template: {
        width: 100,
        height: 30,
        marginBottom: 12,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_back: {
        width: '100%',
        marginRight: 5,
        height: 45,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_next: {
        width: '100%',
        marginLeft: 5,
        height: 45,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));

const Entity = props => {
    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    const [sViewerDomainName] = useGlobal('sViewerDomainName');

    const [iEditingDocId] = useGlobal('iEditingDocId');
    const [sEditingDocTemplateApiKey] = useGlobal('sEditingDocTemplateApiKey');
    const [sEditingDocTemplateName] = useGlobal('sEditingDocTemplateName');
    const [aSignRequestParticipant, setSignRequestParticipant] = useGlobal('aSignRequestParticipant');

    const [sReferrerDocTempEdit] = useGlobal('sReferrerDocTempEdit');

    const [bLoading, setLoading] = useState(false);
    const [bDialogTemplateSignRequestOpen, setDialogTemplateSignRequestOpen] = useState(false);
    const [bDialogCopyTemplate, setDialogCopyTemplate] = useState(false);
    const [oViewerData, setViewerData] = useState({});
    const [bConfirmOpen, setConfirmOpen] = useState(false);
    const [sTitle, setTitle] = useState('');
    const [sContent, setContent] = useState('');
    const [bEditTarget, setEditTarget] = useState(false);
    const [sEditFlag, setEditFlag] = useState('');
    const [oConfirmData, setConfirmData] = useState({});

    useEffect(() => {
        console.log(btoa(unescape(encodeURIComponent(JSON.stringify(aSignRequestParticipant)))));
        window.addEventListener("message", function(e) {
            setConfirmData(e.data);
            /*
            if(e.data.validation == "T") {
                toast_warning('사인 입력요소에 서명참여자를 선택해주세요.');
            } else if(e.data.validation == "S") {
                //toast_warning('본인의 서명'');
            }
            //saveFlag : N,R,S,G(N:다른이름으로저장,R:서명요청,S:저장, G:가이드종료)
            setViewerData(e.data);
            if(e.data.saveFlag == "R") {
                if(e.data.bSignboxHasData) {
                    setContent('서명요청 진행하시겠습니까?');
                } else {
                    setContent(`본인 서명이 없습니다.본인서명 없이 서명요청 진행하시겠습니까?`);
                }
                setDialogTemplateSignRequestOpen(true);
            } else if(e.data.saveFlag == "N") {
                setTitle('확인');
                setContent('다른이름으로 저장하시겠습니까?');
                setConfirmOpen(true);
            } else if(e.data.saveFlag == "G") {
                closeGuide();
            } else if(e.data.saveFlag == "S") {
                console.log(e);
                toast_success('저장되었습니다.');

            } else {
                
                setDialogTemplateSignRequestOpen(false);
            }
            */
        });

        //뒤로가기 막기
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
        

        if(sReferrerDocTempEdit === 'my_document/sign_participant') {
            setEditTarget(true);
            setEditFlag('R');
        } else if(sReferrerDocTempEdit === 'documenttemplatelist') {
            setEditTarget(false);
            setEditFlag('E');
        } else if(sReferrerDocTempEdit === 'offertemplatelist') {
            setEditTarget(false);
            setEditFlag('E');
        }
    }, [props]);

    useEffect(() => {
        if(oConfirmData.validation == "T") {
            toast_warning('사인 입력요소에 서명참여자를 선택해주세요.');
        } else if(oConfirmData.validation == "S") {
            //toast_warning('본인의 서명'');
        }
        //saveFlag : N,R,S,G(N:다른이름으로저장,R:서명요청,S:저장, G:가이드종료)
        setViewerData(oConfirmData);
        if(oConfirmData.saveFlag == "R") {
            if(oConfirmData.bSignboxHasData) {
                setContent('서명요청 진행하시겠습니까?');
            } else {
                setContent(`본인 서명이 없습니다.본인서명 없이 서명요청 진행하시겠습니까?`);
            }
            setDialogTemplateSignRequestOpen(true);
        } else if(oConfirmData.saveFlag == "N") {
            setTitle('확인');
            setContent('다른이름으로 저장하시겠습니까?');
            setConfirmOpen(true);
        } else if(oConfirmData.saveFlag == "G") {
            closeGuide();
        } else if(oConfirmData.saveFlag == "S") {
            toast_success('저장되었습니다.');
        } else {
            
            setDialogTemplateSignRequestOpen(false);
        }
    }, [oConfirmData]);

    const closeGuide = () => {
		document.body.style.overflow = 'auto';
		const tutorialWindow = document.getElementById('tutorialWindow');
		document.body.removeChild(tutorialWindow);
		
	}

    return (
        <div
            style={{
                //height: 'calc(100% - 100px)',
                height: 'calc(100%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            }}
        >  
            <div
                style={{ 
                    display: 'flex', 
                    width: '100%',
                    alignItems: 'center',
                    paddingTop: 10
                }}
            >
                <div style={{ display: 'flex', justifyContent:'flex-start', width: '50%' }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button_create_template}
                        style={{marginLeft: '10px', }}
                        onClick={() => {
                            console.log(sReferrerDocTempEdit);
                            setGlobal({
                                bTemplateView : false,
                            });
                            if(sReferrerDocTempEdit == 'my_document/sign_participant') {
                                navigatePage('/my_document/sign_participant', props);
                            } else if(sReferrerDocTempEdit == 'offertemplatelist') {
                                navigatePage('/offer_template', props);
                            } else {
                                navigatePage('/document_template', props);
                            }
                            
                        }}
                    >
                        돌아가기
                    </Button>   
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button_create_template}
                        style={{marginRight: '10px', }}
                        onClick={() => {
                            const bg = document.createElement('div');
                            bg.id = 'tutorialWindow';
                            bg.style.position = 'absolute';
                            bg.style.width = '100vw';
                            bg.style.height = '100vw';
                            bg.style.top = document.documentElement.scrollTop;
                            bg.style.left = '0';
                            bg.style.backgroundColor = 'rgba(0,0,0,0.8)';
                            bg.style.zIndex = '9999';

                            const divClose = document.createElement('div');
                            divClose.style.width = '100vw';
                            divClose.style.textAlign = 'right';

                            const close = document.createElement('img');
                            close.src = '/images/tutorial/close.png';
                            close.style.width = '50px';
                            close.addEventListener('click', function(event){
                                closeGuide();
                            });

                            divClose.appendChild(close);
                            bg.appendChild(divClose);

                            const divArrow = document.createElement('div');
                            divArrow.style.width = '100vw';
                            divArrow.style.display = 'flex';
                            
                            const divLeft = document.createElement('div');
                            divLeft.style.width = '100vw';
                            divLeft.style.textAlign = 'left';
                            divLeft.style.paddingTop = '18%';
                            divLeft.style.paddingLeft = '9%';

                            const left = document.createElement('img');
                            left.src = '/images/arrow_left.png';
                            left.style.width = '70px';
                            left.addEventListener('click', function(event){
                                const iframe = document.getElementById('iframeTutorial').contentWindow;
                                iframe.postMessage({ cmd : 'prev'}, '*');
                            });

                            divLeft.appendChild(left);
                            divArrow.appendChild(divLeft);

                            const divRight = document.createElement('div');
                            divRight.style.width = '100vw';
                            divRight.style.textAlign = 'right';
                            divRight.style.paddingTop = '18%';
                            divRight.style.paddingRight = '9%';

                            const right = document.createElement('img');
                            right.src = '/images/arrow_right.png';
                            right.style.width = '70px';
                            right.addEventListener('click', function(event){
                                const iframe = document.getElementById('iframeTutorial').contentWindow;
                                iframe.postMessage({ cmd : 'next'}, '*');
                            });

                            divRight.appendChild(right);
                            divArrow.appendChild(divRight);
                            bg.appendChild(divArrow);

                            const tutorial = document.createElement('iframe');
                            tutorial.id = 'iframeTutorial';
                            //로컬
                            //tutorial.src = 'http://localhost:8082/viewer/Guide.html';
                            //운영
                            tutorial.src = 'https://onlinesign.co.kr/viewer/Guide.html';
                            tutorial.frameBolder = '0';
                            tutorial.style.position = 'absolute';
                            tutorial.style.top = '25%';
                            tutorial.style.left  = '50%';
                            tutorial.style.transform  = 'translate(-50%, -50%)';
                            tutorial.width  = '1050';
                            tutorial.height  = '660';
                            tutorial.style.border  = '0';
                            tutorial.style.borderRadius  = '10';

                            
                            
                            
                            bg.appendChild(tutorial);
                            document.body.appendChild(bg)
                            document.body.style.overflow = 'hidden';
                        }}
                    >
                        가이드
                    </Button>   
                </div>
            </div>
            <div style={{width:'100%', height:'100%', display:'flex'}}>
                <iframe
                    id='iframeA'
                    //운영
                    //src={(bEditTarget) ? `https://onlinesign.co.kr/viewer/?id=${sEditingDocTemplateApiKey}`
                    //                   : `https://onlinesign.co.kr/viewer/?id=${sEditingDocTemplateApiKey}&flag=${btoa(unescape(encodeURIComponent(sEditFlag)))}`
                    //개발
                    //src={`http://pilot.onlinesign.co.kr/viewer/?id=${sEditingDocTemplateApiKey}`}
                    //로컬
                    src={(bEditTarget) ? `${sViewerDomainName}/viewer/?id=${sEditingDocTemplateApiKey}`
                                       : `${sViewerDomainName}/viewer/?id=${sEditingDocTemplateApiKey}&flag=${btoa(unescape(encodeURIComponent(sEditFlag)))}`
                    }
                    style={{
                        width: 'calc(100%)',
                        flex: 1,
                        border: 'none',
                        //marginBottom : '30px',
                    }}
                />
            </div>
            {/**
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        maxWidth: 500,
                        minWidth: 500,
                    }}
                >
                    {bLoading && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!bLoading && (
                        <Fragment>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.button_wizard_back}
                                onClick={() => {
                                    navigatePage('/document_template', props);
                                }}
                            >
                                완료
                            </Button>
                            <Button
                                color="secondary"
                                className={classes.button_wizard_next}
                                onClick={() => {
                                    axios.create({
                                        headers: {
                                            Authorization: `Bearer ${sToken}`,
                                        }
                                    }).get(`/${sApiUrl}/docTemplateTarget/findByTemplateIdx?templateIdx=${iEditingDocId}`)
                                        .then(res => {
                                            // const oEntity = res.data.entity;
                                            switch (res.data.status) {
                                                case '000':
                                                    //setLoading(false);
                                                    
                                                    if(res.data.entity == null || res.data.entity.length == 0) {
                                                        axios.create({
                                                            headers: {
                                                                Authorization: `Bearer ${sToken}`,
                                                            }
                                                        }).put(`/${sApiUrl}/docTemplate`, {
                                                            templateIdx: iEditingDocId,
                                                            templateName: sEditingDocTemplateName,
                                                            targetList: [{
                                                                targetPurpose: '',
                                                                targetName: '',
                                                                targetPhone: '',
                                                                targetEmail: '',
                                                            }],
                                                        })
                                                            .then(resPut => {      
                                                                console.log(resPut.data);
                                                                switch (resPut.data.status) {
                                                                    case '000':
                                                                        setGlobal({
                                                                            //sEditingDocTemplateApiKey: res.data.entity.templateApiKey !== undefined ? res.data.entity.templateApiKey : '',                                
                                                                            sEditingSignReqName: sEditingDocTemplateName,
                                                                            sEditingSignReqExpireDay : '14',
                                                                            aSignRequestParticipant: resPut.data.entity.targetList.map((item, index) => {
                                                                                return {
                                                                                    id: item.docTemplateTargetIdx,
                                                                                    targetName: item.targetName,
                                                                                    targetPhone: item.targetPhone,
                                                                                    targetEmail: item.targetEmail,
                                                                                    message: '',        
                                                                                    authUse : 'Y',                            
                                                                                };
                                                                            }),
                                                                        });
                                                                        break;
                                                                    default:
                                                                        toast_warning(resPut.data.message);
                                                                }
                                                            }).catch(err => {
                                                                setLoading(false);
                                                                toast_warning(`System error: ${JSON.stringify(err)}`);
                                                            });
                                                    } else {
                                                        setGlobal({
                                                            //sEditingDocTemplateApiKey: res.data.entity.templateApiKey !== undefined ? res.data.entity.templateApiKey : '',                                
                                                            sEditingSignReqName: sEditingDocTemplateName,
                                                            sEditingSignReqExpireDay : '14',
                                                            aSignRequestParticipant: res.data.entity.map((item, index) => {                                   
                                                                return {
                                                                    id: item.docTemplateTargetIdx,
                                                                    targetName: item.targetName,
                                                                    targetPhone: item.targetPhone,
                                                                    targetEmail: item.targetEmail,
                                                                    message: '',        
                                                                    authUse : 'Y',                            
                                                                };
                                                            }),
                                                        });
                                                    }
                                                    setLoading(false);
                                                    navigatePage('/my_document/sign_request_settings', props);
                                                    break;
                                                default:
                                                    toast_warning(res.data.message);
                                            }
                                        }).catch(err => {
                                            // setLoading(false);
                                            toast_warning(`System error: ${JSON.stringify(err)}`);
                                        });
                                }}
                            >
                                서명 요청하기
                            </Button>
                        </Fragment>
                    )}
                </div>
            </div>
             */}
            <DialogConfirm
                open={bConfirmOpen}
                bLoading={bLoading}
                title={sTitle}
                content={sContent}
                onClose={() => {
                    // setDialogCancelSignRequestOpen(false);
                }}
                onClickCancel={() => {
                    setConfirmOpen(false);
                }}
                onClickConfirm={() => {
                    setConfirmOpen(false);
                    setDialogCopyTemplate(true);
                }}
            />
            
            <DialogCopyTemplate 
                open={bDialogCopyTemplate}
                bLoading={bLoading}
                onClose={() => {
                    setDialogCopyTemplate(false);
                }}
                onClickCancel={() => {
                    setDialogCopyTemplate(false);
                }}
                onClickCreate={(newTemplateName) => {
                    setDialogCopyTemplate(false);
                    console.log(newTemplateName);
                    console.log(oViewerData);
                    setLoading(true);
                    axios.create({
                        headers: {
                            Authorization: `Bearer ${sToken}`,
                        }
                    }).post(`/${sApiUrl}/docTemplate/copyNewTemplate`,{
                        newTemplateName : newTemplateName,
                        viewerData : oViewerData,
                        templateIdx : iEditingDocId
                    }).then(res => {
                        switch (res.data.status) {
                            case '000':
                                setLoading(false);
                                toast_success('저장되었습니다.');
                                navigatePage('/document_template', props);
                        }
                        
                    });
                }}
            />

            <DialogTemplateSignRequest
                open={bDialogTemplateSignRequestOpen}
                bLoading={bLoading}
                content={sContent}
                onClose={() => {
                    setDialogTemplateSignRequestOpen(false);
                }}
                onClickCancel={() => {
                    setDialogTemplateSignRequestOpen(false);
                }}
                onClickCreate={() => {

                    if(sReferrerDocTempEdit === 'my_document/sign_participant') {
                        navigatePage('/my_document/sign_request_settings', props);
                    } else {
                        setGlobal({
                            sReferrerDocTempEdit: 'my_document/sign_participant',
                        });
                       
                        navigatePage('/my_document/sign_participant', props);
                    }
                    
                }}
            />
        </div>
    );
};

export default Entity;