import React, { setGlobal, useEffect, useGlobal, useState, useRef } from 'reactn';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import IconDelete from '@material-ui/icons/Delete';
// import IconCheck from '@material-ui/icons/Check';
import IconClose from '@material-ui/icons/Close';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import IconEdit from '@material-ui/icons/Edit';
import IconDescription from '@material-ui/icons/Description';
import IconSend from '@material-ui/icons/Send';
import IconFileCopy from '@material-ui/icons/FileCopy';
import IconOpenInBrowser from '@material-ui/icons/OpenInBrowser';
import axios from "axios";
// import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingOverlay from 'react-loading-overlay';

import Pagination from '../component/Pagination';
import FileUploader from '../component/FileUploader';
import DialogEditTemplateName from '../component/DialogEditTemplateName';
import DialogConfirm from '../component/DialogConfirm';

import { toast_success, toast_warning } from '../api/api';
import { navigatePage, useOnClickOutside, formatDate } from '../lib/lib';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
//일반사용자용
const headCells = [
    { id: 'template_name', numeric: false, disablePadding: false, label: '문서명' },
    { id: 'bzName', numeric: false, disablePadding: false, label: '업종분류' },
    { id: 'tiName', numeric: false, disablePadding: false, label: '주제분류' },
    { id: 'created_date', numeric: false, disablePadding: false, label: '생성 시간' },
];
//관리자용
const headCellsAdmin = [
    { id: 'template_name', numeric: false, disablePadding: false, label: '문서명' },
    { id: 'bzName', numeric: false, disablePadding: false, label: '업종분류' },
    { id: 'tiName', numeric: false, disablePadding: false, label: '주제분류' },
    { id: 'created_date', numeric: false, disablePadding: false, label: '생성 시간' },
    { id: 'offer_yn', numeric: false, disablePadding: false, label: '제공여부' },
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'scroll',
        [theme.breakpoints.up('sm')]: {
            overflowX: 'unset',
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableToolbar: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    button_create_template: {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        marginRight: 10,
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
    },
    searchPaper: {
        border: '1px solid #ccc',
        boxShadow: 'none',
        display: 'flex',
        transition: 'all .4s',
        width: '70%',
        '&:hover, &:focus-within': {
            width: '100%',
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 7,
        color: theme.palette.secondary.main,
    },
    iconButtonDel: {
        padding: 7,
        color: '#999',
    },
    tableRowTemplateName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: 300,
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 440,
        },
    },
}));
//일반사용자용
function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount && rowCount > 0}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headCell.numeric ? { width: 200 } : {}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                fontSize: '1.4em',
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
//일반사용자용
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

//일반사용자용
function EnhancedTableHeadAdmin(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount && rowCount > 0}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCellsAdmin.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headCell.numeric ? { width: 200 } : {}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                fontSize: '1.4em',
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
//일반사용자용
EnhancedTableHeadAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const StyledButton = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        width: 190,
        height: 50,
        border: 'none',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '1.2em',
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}))(Button);

const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
        outline: 'none',
      },
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        outline: 'none',
        height: 38,
        width: '100%',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        // padding: '10px 26px 10px 12px',
        padding: 0,
        paddingLeft: 10,
        // paddingRight: 10,
        lineHeight: 1,
        // color: '',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        // fontFamily: [
        //     '-apple-system',
        //     'BlinkMacSystemFont',
        //     '"Segoe UI"',
        //     'Roboto',
        //     '"Helvetica Neue"',
        //     'Arial',
        //     'sans-serif',
        //     '"Apple Color Emoji"',
        //     '"Segoe UI Emoji"',
        //     '"Segoe UI Symbol"',
        // ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function Entity(props) {
    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    const [iRoleId] = useGlobal('iRoleId');
    const [sUserEmail] = useGlobal('sUserEmail');
    
    const [bClickedStart, setClicketStart] = useGlobal('bClickedStart');

    const [bLoading, setLoading] = React.useState(false);

    const [sSearchQuery, setSearchQuery] = useState('');
    const [sQuery, setQuery] = useState('');
    const [rows, setRows] = useState([]);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openPopper, setOpenPopper] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [docToDel, setDocToDel] = React.useState({ id: -1, template_name: '' });
    const [openPopperEdit, setOpenPopperEdit] = React.useState(false);
    const [docToEdit, setDocToEdit] = React.useState({ id: -1, template_name: '' });

    const [bFileUploaderOpen, setFileUploaderOpen] = React.useState(false);
    const [bDialogEditTemplateName, setDialogEditTemplateName] = React.useState(false);
    const [bConfirmOpen, setConfirmOpen] = React.useState(false);
    const [sTitle, setTitle] = React.useState('');
    const [sContent, setContent] = React.useState('');
    const [sBzCode, setBzCode] = React.useState('000');
    const [sTiCode, setTiCode] = React.useState('000');
    const [aBzCodes, setBzCodes] = React.useState([]);
    const [aTiCodes, setTiCodes] = React.useState([]);
    
    const ref = useRef();

    const refDel = useRef();

    useOnClickOutside(ref, () => setOpenPopperEdit(false));

    useOnClickOutside(refDel, () => setOpenPopper(false));

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    function handleChangePage(newPage) {
        setPage(newPage);
    }

    const isSelected = id => selected.indexOf(id) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    useEffect(() => {
        window.scrollTo(0, 0);
        searchComCode();
    }, []);

    useEffect(() => {
        fetchData();
        setPage(0);
    }, [sApiUrl, sToken, sQuery, sBzCode, sTiCode]);

    const searchComCode = () => {
        let sUrl = `/${sApiUrl}/common/getComCode`;
        
        setLoading(true);
        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).post(sUrl, {
                GRP_CODE : '001',
                MST_CODE : "001,002"

        })
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    console.log(res.data.entity);
                  
                    setBzCodes(res.data.entity.MST_CODE_001);
                    setTiCodes(res.data.entity.MST_CODE_002);
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
    }

    const fetchData = () => {
        console.log(sUserEmail);
        let sUrl = `/${sApiUrl}/docOffer/search`;
        let offer_yn = ''
        if(iRoleId != 1) {
            offer_yn = 'Y'
        }
        setLoading(true);
        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).post(sUrl, {
                USER_EMAIL : sUserEmail,
                TEMPLATE_NAME : sQuery,
                BZ_CODE : sBzCode,
                TI_CODE : sTiCode,
                OFFER_YN : offer_yn,
        })
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    console.log(res.data.entity);
                    
                    setRows(res.data.entity.map(item => {
                        let sCreatedDate = '';
                        if (item.regDate !== null) {
                            sCreatedDate = formatDate(item.regDate);
                            // const oDate = new Date(item.regDate);
                            // sCreatedDate = oDate.toDateString();
                        }
                        let sUpdatedDate = '';
                        if (item.updDate !== null) {
                            sUpdatedDate = formatDate(item.updDate);
                            // const oUpdDate = new Date(item.updDate);
                            // sUpdatedDate = oUpdDate.toDateString();
                        }
                        
                        return {
                            id: item.offerTemplateIdx,
                            templateIdx : item.templateIdx,
                            template_name: item.templateName,
                            edit_flag: item.edit_flag == 'Y' ? '완료' : '미완료',
                            apiKey: item.apiKey,
                            bzName : item.bzName,
                            tiName : item.tiName,
                            offer_yn : item.offer_yn,
                            created_date: sCreatedDate,
                            updated_date: sUpdatedDate,
                        }
                    }));
                    
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
    };

    useEffect(() => {
        if (bClickedStart) {
            //setFileUploaderOpen(true);
            //playTutorial();
        }
        setClicketStart(false);
    }, [bClickedStart]);

    const offerTemplateCopy = () => {
        setLoading(true);
        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).post(`/${sApiUrl}/docOffer/docOfferTake`, {
            templateIdx : docToEdit.templateIdx,
        })
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    toast_success('문서함으로 가져오기에 성공하였습니다.');
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
        
    }

    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <div className={classes.tableWrapper}>
                <div className={classes.tableToolbar}>
                    <div style={{ display: 'inline-block', width: 170, marginRight: 10}}>
                        <FormControl
                            style={{
                                height: 40,
                                width: '100%',
                                outline: 'none',
                            }}
                        >
                            <Select
                                value={sBzCode}
                                onChange={e => setBzCode(e.target.value)}
                                input={<BootstrapInput name="age" id="age-customized-select" />}
                                style={{
                                    outline: 'none',
                                }}
                            >
                                <MenuItem value='000'>업무분류</MenuItem>
                                <MenuItem value={'001'}>인사/노무</MenuItem>
                                <MenuItem value={'002'}>유통/구매</MenuItem>
                                <MenuItem value={'003'}>제조/기계</MenuItem>
                                <MenuItem value={'004'}>광고/홍보/마케팅</MenuItem>
                                <MenuItem value={'005'}>정보/보안</MenuItem>
                                <MenuItem value={'006'}>전자상거래</MenuItem>
                            </Select>
                            
                            
                        </FormControl>
                        
                    </div>
                    <div style={{ display: 'inline-block', width: 170, marginRight: 10 }}>
                        <FormControl
                            style={{
                                height: 40,
                                width: '100%',
                                outline: 'none',
                            }}
                        >
                            <Select
                                value={sTiCode}
                                onChange={e => setTiCode(e.target.value)}
                                input={<BootstrapInput name="age" id="age-customized-select" />}
                                style={{
                                    outline: 'none',
                                }}
                            >
                                <MenuItem value={'000'}>주제분류</MenuItem>
                                <MenuItem value={'001'}>근로계약</MenuItem>
                                <MenuItem value={'002'}>구매계약</MenuItem>
                                <MenuItem value={'003'}>도급/하도급 계약</MenuItem>
                                <MenuItem value={'004'}>개인/프리랜서 계약</MenuItem>
                                <MenuItem value={'005'}>동의서</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Paper className={classes.searchPaper}>
                            <form
                                onSubmit={event => {
                                    event.preventDefault();
                                    setQuery(sSearchQuery);
                                }}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <InputBase
                                    className={classes.input}
                                    placeholder="문서명를 입력해 주세요."
                                    inputProps={{ 'aria-label': '검색어를 입력해 주세요.' }}
                                    value={sSearchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                />
                                <IconButton className={classes.iconButton} aria-label="search" type="submit">
                                    <SearchIcon />
                                </IconButton>
                            </form>
                        </Paper>
                        
                    </div>
                </div>
                <LoadingOverlay
                    active={bLoading}
                    spinner
                    text='Loading...'
                    styles={{
                        overlay: {
                            position: 'fixed',
                            height: '100vh',
                            width: '100%',
                            top: 0,
                            left: 0,
                            display: 'flex',
                            textAlign: 'center',
                            fontSize: '1.2em',
                            color: '#FFF',
                            background: 'rgba(0,0,0,0.7)',
                            zIndex: 800,
                            transition: 'opacity 500ms ease-in',
                            opacity: 1,
                        },
                        // wrapper: {},
                        // content: {},
                        // spinner: {}
                    }}
                >
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        {(iRoleId === 1) && (
                            <EnhancedTableHeadAdmin
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                        )}
                        {(iRoleId != 1) && (
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                        )}
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.apiKey);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.apiKey}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    onChange={event => handleClick(event, row.apiKey)}
                                                />
                                            </TableCell>
                                            
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div
                                                        className={classes.tableRowTemplateName}
                                                        onClick={e => {
                                                            setOpenPopper(false);
                                                            setAnchorEl(e.currentTarget);
                                                            setDocToEdit(row);
                                                            if (anchorEl === e.currentTarget) {
                                                                setOpenPopperEdit(!openPopperEdit);
                                                            } else {
                                                                setOpenPopperEdit(true);
                                                            }
                                                        }}
                                                    >
                                                        {row.template_name}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">{row.bzName}</TableCell>
                                            <TableCell align="center">{row.tiName}</TableCell>
                                            <TableCell align="center">{row.created_date}</TableCell>
                                            {iRoleId === 1 && (<TableCell align="center">{row.offer_yn}</TableCell>)}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && rows.length > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <Popper open={openPopperEdit} anchorEl={anchorEl} placement="bottom-start" transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper
                                    ref={ref}
                                    style={{
                                        width: 280,
                                        //height: 150,
                                        height: (iRoleId === 1) ? 270 :180,
                                        padding: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <div>
                                        <Typography className={classes.typography} style={{ fontWeight: 'bold', textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {docToEdit.template_name}
                                        </Typography>
                                    </div>
                                    <List>
                                        {(iRoleId === 1) && (
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    setGlobal({
                                                        iEditingDocId: docToEdit.templateIdx,
                                                        sEditingDocTemplateName: docToEdit.template_name,
                                                        sEditingDocTemplateApiKey: docToEdit.apiKey,
                                                        sReferrerDocTempEdit: 'offertemplatelist',
                                                    });
                                                    navigatePage('/document_template/template_editing', props);
                                                }}
                                            >
                                                <ListItemIcon className={classes.listItemIcon}><IconEdit /></ListItemIcon>
                                                <ListItemText className={classes.listItemText} primary={'문서 편집'} />
                                            </ListItem>
                                        )}
                                        {(iRoleId === 1 && docToEdit.offer_yn === 'N') && (
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    setLoading(true);
                                                    axios.create({
                                                        headers: {
                                                            Authorization: `Bearer ${sToken}`,
                                                        }
                                                    }).post(`/${sApiUrl}/docOffer/updateOffer`,{
                                                        OFFER_TEMPLATE_IDX : docToEdit.id,
                                                        TEMPLATE_IDX: docToEdit.templateIdx,
                                                        OFFER_YN : 'Y',
                                                    }).then(res => {
                                                        setLoading(false);
                                                        switch (res.data.status) {
                                                            case '000':
                                                                console.log(res.data.entity);
                                                                toast_success('제공되었습니다.');
                                                                fetchData();
                                                                setPage(0);
                                                                break;
                                                        default:
                                                            toast_warning(res.data.message);
                                                        }
                                                    }).catch(err => {
                                                        setLoading(false);
                                                        toast_warning(`System error: ${JSON.stringify(err)}`);
                                                    });
                                                }}
                                            >
                                                <ListItemIcon className={classes.listItemIcon}><IconOpenInBrowser /></ListItemIcon>
                                                <ListItemText className={classes.listItemText} primary={'제공하기'} />
                                            </ListItem>
                                        )}
                                        {(iRoleId === 1 && docToEdit.offer_yn === 'Y') && (
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    setLoading(true);
                                                    axios.create({
                                                        headers: {
                                                            Authorization: `Bearer ${sToken}`,
                                                        }
                                                    }).post(`/${sApiUrl}/docOffer/updateOffer`,{
                                                        OFFER_TEMPLATE_IDX : docToEdit.id,
                                                        TEMPLATE_IDX: docToEdit.templateIdx,
                                                        OFFER_YN : 'N',
                                                    }).then(res => {
                                                        setLoading(false);
                                                        switch (res.data.status) {
                                                            case '000':
                                                                console.log(res.data.entity);
                                                                toast_success('제공 취소되었습니다.');
                                                                fetchData();
                                                                setPage(0);
                                                                break;
                                                        default:
                                                            toast_warning(res.data.message);
                                                        }
                                                    }).catch(err => {
                                                        setLoading(false);
                                                        toast_warning(`System error: ${JSON.stringify(err)}`);
                                                    });
                                                }}
                                            >
                                                <ListItemIcon className={classes.listItemIcon}><IconOpenInBrowser /></ListItemIcon>
                                                <ListItemText className={classes.listItemText} primary={'제공 취소'} />
                                            </ListItem>
                                        )}
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setLoading(true);

                                                axios.create({
                                                    headers: {
                                                        Authorization: `Bearer ${sToken}`,
                                                    }
                                                }).post(`/${sApiUrl}/docOffer/save`,{
                                                    TEMPLATE_IDX: docToEdit.templateIdx,
                                                }).then(res => {
                                                        setLoading(false);
                                                        switch (res.data.status) {
                                                            case '000':
                                                                console.log(res.data.entity);
                                                                
                                                                setGlobal({
                                                                    iEditingDocId: docToEdit.templateIdx,
                                                                    sEditingDocTemplateName: docToEdit.template_name,
                                                                    sEditingDocTemplateApiKey: docToEdit.apiKey,
                                                                    sReferrerDocTempEdit: 'signParticipant',
                                                                    iOfferDocId : res.data.entity.OFFER_TEMPLATE_IDX
                                                                });
                                                               
                                                                navigatePage('/offer_template/sign_participant', props);
                                                                break;
                                                        default:
                                                            toast_warning(res.data.message);
                                                    }
                                                }).catch(err => {
                                                    setLoading(false);
                                                    toast_warning(`System error: ${JSON.stringify(err)}`);
                                                });
                                            }}
                                        >
                                            <ListItemIcon className={classes.listItemIcon}><IconSend /></ListItemIcon>
                                            <ListItemText className={classes.listItemText} primary={'서명 요청'} />
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setTitle('문서함으로 가져오기');
                                                setContent('문서함으로 가져오시겠습니까?');
                                                setConfirmOpen(true);
                                            }}
                                        >
                                            <ListItemIcon className={classes.listItemIcon}><IconFileCopy /></ListItemIcon>
                                            <ListItemText className={classes.listItemText} primary={'문서함으로 가져오기'} />
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </LoadingOverlay>
            </div>
            {rows.length === 0 && (
                <div
                    style={{
                        width: '100%',
                        height: 350,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            padding: 0,
                        }}
                    >
                        제공중인 문서가 존재하지 않습니다.
                        </div>
                </div>
            )}
            {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
            'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
            'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
            <Pagination
                page={page}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                style={{
                    marginBottom: 50,
                }}
            />
            <DialogConfirm
                open={bConfirmOpen}
                bLoading={bLoading}
                title={sTitle}
                content={sContent}
                onClose={() => {
                    // setDialogCancelSignRequestOpen(false);
                }}
                onClickCancel={() => {
                    setConfirmOpen(false);
                }}
                onClickConfirm={() => {
                    setConfirmOpen(false);
                    //setDialogCopyTemplate(true);
                    offerTemplateCopy();
                }}
            />
        </div>
    );
}

export default Entity;