import React, { useEffect, useGlobal, useState, Fragment } from 'reactn';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";

import { toast_success, toast_warning } from '../api/api';
import { navigatePage, ordinal_suffix_of, autoFormatKrNumber } from '../lib/lib';

const useStyles = makeStyles(theme => ({
    textfield_label: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    button_create_template: {
        width: '100%',
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_back: {
        width: '100%',
        marginRight: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_next: {
        width: '100%',
        marginLeft: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));

function Entity(props) {
    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');

    const [iEditingDocId] = useGlobal('iEditingDocId');
    const [sEditingDocTemplateName, setEditingDocTemplateName] = useGlobal('sEditingDocTemplateName');
    const [sReferrerDocTempEdit, setReferrerDocTempEdit] = useGlobal('sReferrerDocTempEdit');

    const [bLoading, setLoading] = useState(false);

    const [sParticipantNameFirst, setParticipantNameFirst] = useState('');
    const [sParticipantPhoneFirst, setParticipantPhoneFirst] = useState('');
    const [sParticipantEmailFirst, setParticipantEmailFirst] = useState('');
    const [sParticipantPurposeFirst, setParticipantPurposeFirst] = useState('');
    
    const [aSignRequestParticipant, setSignRequestParticipant] = useState([]);
    const [aAdditionalParticipant, setAdditionalParticipant] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (iEditingDocId !== -1) {
            setLoading(true);
            axios.create({
                headers: {
                    Authorization: `Bearer ${sToken}`,
                }
            }).get(`/${sApiUrl}/docTemplateTarget/findByTemplateIdx`, {
                params: {
                    templateIdx: iEditingDocId,
                },
            })
                .then(res => {
                    const oEntity = res.data.entity;
                    switch (res.data.status) {
                        case '000':
                            setLoading(false);
                            if (oEntity.length > 0) {
                                setSignRequestParticipant(oEntity.map(item => {
                                    return {
                                        id: item.docTemplateTargetIdx,
                                        targetPurpose: item.targetPurpose,
                                        targetName: item.targetName,
                                        targetPhone: item.targetPhone,
                                        targetEmail: item.targetEmail,
                                    };
                                }));
                            }
                            break;
                        default:
                            toast_warning(res.data.message);
                    }
                }).catch(err => {
                    // setLoading(false);
                    toast_warning(`System error: ${JSON.stringify(err)}`);
                });
        }
    }, [sToken, sApiUrl, iEditingDocId]);

    const iPadding = aSignRequestParticipant.length > 0 ? (aSignRequestParticipant.length + 1) : 2;

    return (
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    maxWidth: 500,
                    width: '100%',
                }}
            >
                <Typography variant='h5' style={{ marginBottom: 20, fontWeight: 'bold' }}>
                    템플릿 이름 {/* Template name */}
                </Typography>
                {/* <label htmlFor='template_name' className={classes.textfield_label}>템플릿 이름</label> */}
                <TextField
                    id="template_name"
                    style={{
                        marginTop: 3,
                    }}
                    placeholder="템플릿 이름을 입력해 주세요." /* Please enter a template name. */
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={sEditingDocTemplateName}
                    onChange={e => setEditingDocTemplateName(e.target.value)}
                />
                <Divider style={{
                    marginTop: 15,
                    marginBottom: 15,
                }} />
                {aSignRequestParticipant.length === 0 && (
                    <Fragment>
                        <Typography variant='h5' style={{ marginBottom: 20, fontWeight: 'bold' }}>
                            {ordinal_suffix_of(1)} 서명 참여자 {/* Signature Participant */}
                        </Typography>
                        <label htmlFor='field2' className={classes.textfield_label}>용도</label>
                        <TextField
                            id="field2"
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="문서 용도(예: 근로자, 임대인)" /* Document purpose (eg worker, landlord) */
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={sParticipantPurposeFirst}
                            onChange={e => setParticipantPurposeFirst(e.target.value)}
                        />
                        <label htmlFor='field3' className={classes.textfield_label}>이름 or 회사명</label> {/* Name or company name */}
                        <TextField
                            id="field3"
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이름 또는 회사명을 입력해 주세요." /* Please enter your name or company name. */
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={sParticipantNameFirst}
                            onChange={e => setParticipantNameFirst(e.target.value)}
                        />
                        <label htmlFor='field4' className={classes.textfield_label}>핸드폰 번호</label> {/* Cell Phone Number */}
                        <TextField
                            id="field4"
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="핸드폰 번호를 입력해 주세요." /* Please enter your mobile number. */
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={sParticipantPhoneFirst}
                            onChange={e => setParticipantPhoneFirst(autoFormatKrNumber(e.target.value))}
                        />
                        <label htmlFor='field5' className={classes.textfield_label}>이메일 주소</label> {/* Email Address */}
                        <TextField
                            id="field5"
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이메일 주소를 입력해 주세요." /* Please enter your email address. */
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={sParticipantEmailFirst}
                            onChange={e => setParticipantEmailFirst(e.target.value)}
                        />
                        <Divider style={{
                            marginTop: 15,
                            marginBottom: 15,
                        }} />
                    </Fragment>
                )}
                {aSignRequestParticipant.map((item, index) => (
                    <Fragment key={item.id}>
                        <Typography variant='h5' style={{ marginBottom: 20, fontWeight: 'bold' }}>
                            {ordinal_suffix_of(index + 1)} 서명 참여자
                        </Typography>
                        <label htmlFor={`field_purpose_${item.id}`} className={classes.textfield_label}>용도</label>
                        <TextField
                            id={`field_purpose_${item.id}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="문서 용도(예: 근로자, 임대인)" /* Document purpose (eg worker, landlord) */
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetPurpose}
                            onChange={e => {
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetPurpose: e.target.value,
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_name_${item.id}`} className={classes.textfield_label}>이름 or 회사명</label>
                        <TextField
                            id={`field_name_${item.id}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이름 또는 회사명을 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetName}
                            onChange={e => {
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetName: e.target.value,
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_phone_${item.id}`} className={classes.textfield_label}>핸드폰 번호</label>
                        <TextField
                            id={`field_phone_${item.id}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="핸드폰 번호를 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetPhone}
                            onChange={e => {
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetPhone: autoFormatKrNumber(e.target.value),
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_email_${item.id}`} className={classes.textfield_label}>이메일 주소</label>
                        <TextField
                            id={`field_email_${item.id}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이메일 주소를 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetEmail}
                            onChange={e => {
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetEmail: e.target.value,
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <Divider style={{
                            marginTop: 15,
                            marginBottom: 15,
                        }} />
                    </Fragment>
                ))}
                {aAdditionalParticipant.map((item, index) => (
                    <Fragment key={item.id_temp}>
                        <Typography variant='h5' style={{ marginBottom: 20, fontWeight: 'bold' }}>
                            {ordinal_suffix_of(index + iPadding)} 서명 참여자
                        </Typography>
                        <label htmlFor={`field_purpose_${item.id_temp}`} className={classes.textfield_label}>용도</label>
                        <TextField
                            id={`field_purpose_${item.id_temp}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="문서 용도(예: 근로자, 임대인)" /* Document purpose (eg worker, landlord) */
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetPurpose}
                            onChange={e => {
                                setAdditionalParticipant(aAdditionalParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetPurpose: e.target.value,
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_name_${item.id_temp}`} className={classes.textfield_label}>이름 or 회사명</label>
                        <TextField
                            id={`field_name_${item.id_temp}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이름 또는 회사명을 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetName}
                            onChange={e => {
                                setAdditionalParticipant(aAdditionalParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetName: e.target.value,
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_phone_${item.id_temp}`} className={classes.textfield_label}>핸드폰 번호</label>
                        <TextField
                            id={`field_phone_${item.id_temp}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="핸드폰 번호를 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetPhone}
                            onChange={e => {
                                setAdditionalParticipant(aAdditionalParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetPhone: autoFormatKrNumber(e.target.value),
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_email_${item.id_temp}`} className={classes.textfield_label}>이메일 주소</label>
                        <TextField
                            id={`field_email_${item.id_temp}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이메일 주소를 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetEmail}
                            onChange={e => {
                                setAdditionalParticipant(aAdditionalParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetEmail: e.target.value,
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <Divider style={{
                            marginTop: 15,
                            marginBottom: 15,
                        }} />
                    </Fragment>
                ))}
                {/* Add signature participant */}
                { <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button_create_template}
                    onClick={() => {
                        setAdditionalParticipant([...aAdditionalParticipant, {
                            id_temp: `${new Date().getTime().toString()}-${Math.random()}`,
                            targetPurpose: '',
                            targetName: '',
                            targetPhone: '',
                            targetEmail: '',
                        }]);
                    }}
                >
                    + 서명 참여자 추가하기
                </Button>}
                <TextField
                    id="field6"
                    disabled
                    style={{
                        marginTop: 3,
                        marginBottom: 27,
                    }}
                    placeholder="핸드폰 번호를 입력하시면 문자 or 카카오톡으로 전송이 됩니다." /* If you enter your mobile number will be sent to text or KakaoTalk. */
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: 20,
                        paddingBottom: 50,
                    }}
                >
                    {bLoading && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // width: '100%',
                                // paddingLeft: 20,
                                // paddingRight: 20,
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!bLoading && (
                        <Fragment>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.button_wizard_back}
                                onClick={() => {
                                    if (sReferrerDocTempEdit === 'fileuploader') {
                                        setLoading(true);
                                        axios.create({
                                            headers: {
                                                Authorization: `Bearer ${sToken}`,
                                            }
                                        }).delete(`/${sApiUrl}/docTemplate/${iEditingDocId}`)
                                            .then(res => {
                                                setLoading(false);
                                                switch (res.data.status) {
                                                    case '000':
                                                        navigatePage('/document_template', props);
                                                        break;
                                                    default:
                                                        toast_warning(res.data.message);
                                                }
                                            }).catch(err => {
                                                setLoading(false);
                                                toast_warning(`System error: ${JSON.stringify(err)}`);
                                            });
                                    } else {
                                        navigatePage('/document_template', props);
                                    }
                                }}
                            >
                                이전
                            </Button>
                            {/* <Button
                                color="secondary"
                                className={classes.button_wizard_next}
                                onClick={() => {
                                    const aAll = [];
                                    if (aSignRequestParticipant.length > 0) {
                                        aSignRequestParticipant.forEach(item => {
                                            aAll.push({
                                                docTemplateTargetIdx: item.id, // extra field for existing items
                                                targetPurpose: item.targetPurpose,
                                                targetName: item.targetName,
                                                targetPhone: item.targetPhone,
                                                targetEmail: item.targetEmail,
                                            });
                                        });
                                    } else {
                                        aAll.push({
                                            targetPurpose: sParticipantPurposeFirst,
                                            targetName: sParticipantNameFirst,
                                            targetPhone: sParticipantPhoneFirst,
                                            targetEmail: sParticipantEmailFirst,
                                        });
                                    }
                                    const iInitialCount = aAll.length + 1;
                                    aAdditionalParticipant.forEach((item, index) => {
                                        aAll.push({
                                            ...item,
                                        });
                                    });
                                    let bCorrect = true;
                                    aAll.forEach(item => {
                                        if (item.targetPurpose === null || item.targetPurpose.length === 0) {
                                            bCorrect = false;
                                        }
                                        if (item.targetName === null || item.targetName.length === 0) {
                                            bCorrect = false;
                                        }
                                        if (item.targetPhone === null || item.targetPhone.length === 0) {
                                            bCorrect = false;
                                        }
                                        if (item.targetEmail === null || item.targetEmail.length === 0) {
                                            bCorrect = false;
                                        }
                                    });
                                    if (!bCorrect) {
                                        alert('Please fill all fields!');
                                        return;
                                    }
                                    setLoading(true);
                                    axios.create({
                                        headers: {
                                            Authorization: `Bearer ${sToken}`,
                                        }
                                    }).put(`/${sApiUrl}/docTemplate`, {
                                        templateIdx: iEditingDocId,
                                        templateName: sEditingDocTemplateName,
                                        targetList: aAll,
                                    })
                                        .then(res => {
                                            setLoading(false);
                                            switch (res.data.status) {
                                                case '000':
                                                    toast_success('Template created');
                                                    navigatePage('/document_template', props);
                                                    break;
                                                default:
                                                    toast_warning(res.data.message);
                                            }
                                        }).catch(err => {
                                            setLoading(false);
                                            toast_warning(`System error: ${JSON.stringify(err)}`);
                                        });


                                    // axios.create({
                                    //     headers: {
                                    //         Authorization: `Bearer ${sToken}`,
                                    //     }
                                    // }).post(`/${sApiUrl}/docTemplateTarget`, {
                                    //     templateIdx: iEditingDocId,
                                    //     targetList: aAll,
                                    // })
                                    //     .then(res => {
                                    //         // setLoading(false);
                                    //         switch (res.data.status) {
                                    //             case '000':
                                    //                 axios.create({
                                    //                     headers: {
                                    //                         Authorization: `Bearer ${sToken}`,
                                    //                     }
                                    //                 }).put(`/${sApiUrl}/docTemplate`, {
                                    //                     templateIdx: iEditingDocId,
                                    //                     templateName: sEditingDocTemplateName,
                                    //                 })
                                    //                     .then(res => {
                                    //                         setLoading(false);
                                    //                         switch (res.data.status) {
                                    //                             case '000':
                                    //                                 toast_success('Template created');
                                    //                                 navigatePage('/document_template', props);
                                    //                                 break;
                                    //                             default:
                                    //                                 toast_warning(res.data.message);
                                    //                         }
                                    //                     }).catch(err => {
                                    //                         setLoading(false);
                                    //                         toast_warning(`System error: ${JSON.stringify(err)}`);
                                    //                     });
                                    //                 break;
                                    //             default:
                                    //                 setLoading(false);
                                    //                 toast_warning(res.data.message);
                                    //         }
                                    //     }).catch(err => {
                                    //         setLoading(false);
                                    //         toast_warning(`System error: ${JSON.stringify(err)}`);
                                    //     });
                                }}
                            >
                                다음
                            </Button> */}
                            <Button
                                color="secondary"
                                className={classes.button_wizard_next}
                                onClick={() => {
                                    const aAll = [];
                                    if (aSignRequestParticipant.length > 0) {
                                        aSignRequestParticipant.forEach(item => {
                                            aAll.push({
                                                docTemplateTargetIdx: item.id, // extra field for existing items
                                                targetPurpose: item.targetPurpose,
                                                targetName: item.targetName,
                                                targetPhone: item.targetPhone,
                                                targetEmail: item.targetEmail,
                                            });
                                        });
                                    } else {
                                        aAll.push({
                                            targetPurpose: sParticipantPurposeFirst,
                                            targetName: sParticipantNameFirst,
                                            targetPhone: sParticipantPhoneFirst,
                                            targetEmail: sParticipantEmailFirst,
                                        });
                                    }
                                    const iInitialCount = aAll.length + 1;
                                    aAdditionalParticipant.forEach((item, index) => {
                                        aAll.push({
                                            ...item,
                                        });
                                    });
                                    
                                    let bCorrect = true;
                                    let sCorrectType = "";
                                    aAll.forEach(item => {                                        
                                        console.log(item);
                                        if (item.targetPhone != null && item.targetPhone != "") {
                                            if(item.targetPhone.length < 11 || item.targetPhone.length > 13) {
                                                bCorrect = false;
                                                sCorrectType = "01";
                                            }
                                            
                                        } 

                                        if (item.targetEmail != null && item.targetEmail != "") {
                                            const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
                                            if (item.targetEmail.match(regExp) != null) {
                                            } else {
                                                bCorrect = false;
                                                sCorrectType = "02";
                                            }

                                        }
                                    });
                                    
                                    if (!bCorrect) {
                                        if(sCorrectType == "01") {
                                            alert("핸드폰번호가 올바르지 않습니다.")
                                        } else if(sCorrectType == "02") {
                                            alert("이메일 형식이 올바르지 않습니다.")
                                        }
                                        return;
                                    }
                                    
                                    setLoading(true);
                                    axios.create({
                                        headers: {
                                            Authorization: `Bearer ${sToken}`,
                                        }
                                    }).put(`/${sApiUrl}/docTemplate`, {
                                        templateIdx: iEditingDocId,
                                        templateName: sEditingDocTemplateName,
                                        targetList: aAll,
                                    })
                                        .then(res => {
                                            setLoading(false);
                                            switch (res.data.status) {
                                                case '000':
                                                    //toast_success('서명참여자가 등록되었습니다.'');
                                                    setReferrerDocTempEdit('document_template_creation');
                                                    navigatePage('/document_template/document_template_editing', props);
                                                    // navigatePage('/document_template', props);
                                                    break;
                                                default:
                                                    toast_warning(res.data.message);
                                            }
                                        }).catch(err => {
                                            setLoading(false);
                                            toast_warning(`System error: ${JSON.stringify(err)}`);
                                        });
                                }}
                            >
                                다음
                            </Button>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Entity;