import React, { useState, useGlobal, useRef } from 'reactn';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';

import axios from "axios";

import { toast_warning } from '../api/api';

import MakeSvg from './MakeSvg';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    selectLang: {
        minWidth: 120,
    },
    textField: {
        flex: 1,
        margin: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    create_btn: {
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 1,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    generatedSignImg: {
        width: '100%',
        border: '1px solid grey',
        cursor: 'pointer',
        //backgroundColor: '#ccc',
        backgroundColor: 'white',
        '&:hover': {
            borderColor: 'blue',
            backgroundColor: 'white',
        },
    },
    generatedSignImgSelected: {
        width: '100%',
        border: '1px solid blue',
        cursor: 'pointer',
        backgroundColor: 'white',
        '&:hover': {
            // borderColor: 'blue',
            backgroundColor: 'white',
        },
    },
}));

const Entity = props => {

    const classes = useStyles();

    const [bLoading, setLoading] = React.useState(false);

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');

    const [sLanguage, setLanguage] = useState(1);
    const [sName, setName] = useState('');
    const [aSignImg, setSignImg] = useState([]);

    const [makeSvgLang, setMakeSvgLang] = useState(1);

    const textValidCheck = inputTxt => {
        const inputLength = inputTxt.length;
        if (inputLength > 4) {
            alert('입력한 문자는 4 글자를 초과할 수 없습니다.');
        } else {
            setName(inputTxt);
            /**
             * 이름 3자 기본 x:75 y:75 size:40, 같은라인 다음칸 x:200(+125) text : x-25 font 25
             * 이름 4자 기본 x:75 y:75 size:40, 같은라인 다음칸 x:200(+125) 동일
             * 이름 5자 기본 x:75 y:75 size:40, 같은라인 다음칸 x:200(+125) text : x-30 font 20
             */
            //setMakeSvgLang(lang.current);
            var canvas = document.getElementById("stamp1");
            var ctx = canvas.getContext("2d");
            ctx.beginPath();
            //ctx.arc(0, 0, 60, 0, 2*Math.PI);
            ctx.arc(canvas.width / 2, canvas.height / 2, 60, 0, Math.PI * 2, false); // Outer circle
            ctx.font = 'bold 25px maplestory'; //텍스트 font
            ctx.fillStyle = "#ff2020";
            ctx.fill();
            ctx.strokeStyle = "#ff2020"; //테두리 색상
            ctx.lineWidth = 2;
            ctx.stroke();
            ctx.closePath();
            ctx.beginPath();
            ctx.fillStyle = "#fff";
            ctx.fillText('황윤',50,70); //원 안 텍스트
            ctx.fillText('동인',50,100); //원 안 텍스트            
            
            ctx.closePath();
            var dataUrl = canvas.toDataURL();
            console.log(dataUrl);
            var canvasImg = document.getElementById('canvasImg');
            canvasImg.src = dataUrl;
            /*
            canvas = document.getElementById("stamp2");
            ctx = canvas.getContext("2d");
            //이름 5자이상
            ctx.beginPath();
            ctx.arc(75, 75, 40, 0, Math.PI * 2, true); // Outer circle
            ctx.font = 'bold 20px HJ한전서A'; //텍스트 font
            ctx.fillStyle = "#ff2020";
            ctx.fillText('황윤',50,70); //원 안 텍스트
            ctx.fillText('동인',50,100); //원 안 텍스트   
            ctx.strokeStyle = "#ff2020"; //테두리 색상
            ctx.lineWidth = 2;
            ctx.stroke();
            ctx.closePath();

            canvas = document.getElementById("stamp3");
            ctx = canvas.getContext("2d");
            ctx.beginPath();
            ctx.arc(75, 75, 40, 0, Math.PI * 2, true); // Outer circle
            ctx.font = 'bold 25px 맑은고딕'; //텍스트 font
            ctx.fillStyle = "#ff2020";
            ctx.fillText('황',312,70); //원 안 텍스트
            ctx.fillText('동',312,100); //원 안 텍스트
            ctx.strokeStyle = "#ff2020"; //테두리 색상
            ctx.lineWidth = 2;
            ctx.stroke();
            ctx.closePath();

            canvas = document.getElementById("stamp4");
            ctx = canvas.getContext("2d");
            ctx.beginPath();
            //ctx.fillRect(450, 75, 80, 80);
            //ctx.clearRect(4, 20, 60, 60)
            //ctx.arc(450, 75, 40, 0, Math.PI * 2, true); // Outer circle
            ctx.font = 'bold 25px 궁서'; //텍스트 font
            ctx.fillStyle = "#ff2020";
            ctx.fillText('황윤',425,70); //원 안 텍스트
            ctx.fillText('동인',425,100); //원 안 텍스트
            ctx.strokeStyle = "#ff2020"; //테두리 색상
            ctx.lineWidth = 2;
            //ctx.stroke();
            ctx.strokeRect(400, 40, 80, 80);
            ctx.closePath();
            */
            //2번째줄
        }
    }

    const lang = useRef(1);
    const onChangeLang = (n) => {
        lang.current = n;
        setLanguage(n);
    }

    return (
        <div
            className={classes.container}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 10,
                }}
            >
                {/*
                <Select
                    className={classes.selectLang}
                    value={sLanguage}
                    onChange={e => onChangeLang(e.target.value)}
                    displayEmpty
                    margin="dense"
                    input={<OutlinedInput />}
                >
                    <MenuItem value={1}>한글</MenuItem>
                    <MenuItem value={2}>한자</MenuItem>
                </Select>
                */}
                <TextField
                    className={classes.textField}
                    label="이름을 입력해 주세요."
                    margin="dense"
                    variant="outlined"
                    value={sName}
                    onChange={e => setName(e.target.value)}
                />
                <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.create_btn}
                    onClick={() => {
                        setLoading(true);
                        const sUrl = `/${sApiUrl}/docUserStampImg/create`;
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(sUrl, {
                            name: sName,
                        })
                        .then(res => {
                            setLoading(false);
                            switch (res.data.status) {
                                case '000':
                                    if (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.length > 0) {
                                        setSignImg(res.data.entity.map(item => {
                                            return {
                                                id: item.signImgIdx,
                                                filename: item.systemFileName,
                                            };
                                        }));
                                    }
                                    break;
                                default:
                                    toast_warning(res.data.message);
                            }
                        }).catch(err => {
                            setLoading(false);
                            toast_warning(`System error: ${JSON.stringify(err)}`);
                        });
                    }}
                    id="create_svg_btn"
                >
                    생성하기
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        width: 400,
                        display: 'grid',
                        gridTemplateColumns: '50% 50%',
                    }}
                >
                    {aSignImg.map(oImg => (
                        <div
                            key={oImg.id}
                            style={{
                                padding: 2,
                            }}
                        >
                            <img
                                onClick={() => {
                                    props.setGeneratedImgId(oImg.id);
                                }}
                                className={props.iGeneratedImgId === oImg.id ? classes.generatedSignImgSelected : classes.generatedSignImg}
                                alt={oImg.filename}
                                src={`${sApiUrl}/docUserSignImg/downloadSignImg/${oImg.id}`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Entity;