import React from 'reactn';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IconFirstPage from '@material-ui/icons/FirstPage';
import IconLastPage from '@material-ui/icons/LastPage';
import IconNavigateNext from '@material-ui/icons/NavigateNext';
import IconNavigateBefore from '@material-ui/icons/NavigateBefore';
// import { useState, useEffect } from 'reactn';

const useStyles = makeStyles(theme => ({
	iconButton: {
		padding: 7,
		color: theme.palette.primary.main,
	},
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

function Entity(props) {
	const classes = useStyles();
	const iTotalPages = Math.ceil(props.count / props.rowsPerPage);
	
	// const [iTotalPages, setTotalPages] = useState(Math.ceil(props.count / props.rowsPerPage));
	// useEffect(() => {
	// 	setTotalPages(Math.ceil(props.count / props.rowsPerPage));
	// }, [props.count, props.rowsPerPage]);

	return (
		<div
			style={{ ...props.style }}
			className={classes.root}
		>
			<IconButton className={classes.iconButton} aria-label="first page" onClick={() => {
				props.onChangePage(0);
			}}>
				<IconFirstPage />
			</IconButton>
			<IconButton className={classes.iconButton} aria-label="previous page" onClick={() => {
				if (props.page - 1 > -1) {
					props.onChangePage(props.page - 1);
				}
			}}>
				<IconNavigateBefore />
			</IconButton>
			<div
				style={{
					cursor: 'default',
					padding: '4px 10px',
					marginLeft: 4,
					marginRight: 4,
					backgroundColor: '#e9e9e9',
					border: 'solid 1px #cccccc',
				}}
			>
				{/* {props.page + 1} of {iTotalPages > 0 ? iTotalPages : '1'} */}
				{props.page + 1}
			</div>
			<IconButton className={classes.iconButton} aria-label="next page" onClick={() => {
				if (iTotalPages > props.page + 1) {
					props.onChangePage(props.page + 1);
				}
			}}>
				<IconNavigateNext />
			</IconButton>
			<IconButton className={classes.iconButton} aria-label="last page" onClick={() => {
				if (iTotalPages > 0) {
					props.onChangePage(iTotalPages - 1);
				} else {
					props.onChangePage(0);
				}
			}}>
				<IconLastPage />
			</IconButton>
		</div>
	);
}

export default Entity;