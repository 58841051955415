import { getGlobal, setGlobal, useEffect } from 'reactn';

export const navigatePage = (path, props) => {
    const aPage = getGlobal().aPage.filter(item => item.path === path);
    if (aPage.length > 0) {
        //if(path == '/document_template/template_editing') {
        if(path.indexOf('template_editing') > -1) {
            setGlobal({
                oPageTitle: { title: aPage[0].title, desc: aPage[0].desc },
                bTemplateView : true,
            });
        } else {
            setGlobal({
                oPageTitle: { title: aPage[0].title, desc: aPage[0].desc },
                bTemplateView : false,
            });
        }
        props.history.push(aPage[0].path);
    }
};

export const ordinal_suffix_of = i => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
};


export const useOnClickOutside = (ref, handler) => {
    useEffect(
        () => {
            const listener = event => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
};

export const formatDate = date => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        minute = '' + d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;

    return `${[year, month, day].join('-')} ${hour}:${minute}`;
};

export const autoFormatKrNumber = value => {
    const alldigits = ((value || "").match(/\d+/g) || []).join("");

    // old number format
    if (alldigits.length === 10) {
        return (
            alldigits.substring(0, 3) +
            "-" +
            alldigits.substring(3, 6) +
            "-" +
            alldigits.substring(6, 10)
        );
    }
    // new number format (mobile phone)
    else {
        let result = alldigits.substring(0, 3);

        const p2 = alldigits.substring(3, 7);

        if (p2) {
            result += "-" + p2;
        } else {
            return result;
        }

        const p3 = alldigits.substring(7, 11);

        if (p3) {
            result += "-" + p3;
        }

        return result;
    }
};