import React, { Suspense, useState, useGlobal, setGlobal, useEffect } from 'reactn';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Cookies from 'js-cookie';

import axios from "axios";

import Home from '../page/Home';
import MyDocument from '../page/MyDocument';
import DocumentTemplate from '../page/DocumentTemplate';
import DocumentTemplateCreation from '../page/DocumentTemplateCreation';
import TemplateEditing from '../page/TemplateEditing';
import DocumentTemplateEditing from '../page/DocumentTemplateEditing';
import DocumentTemplateView from '../page/DocumentTemplateView';
import DataUpload from '../page/DataUpload';
import EstimateSend from '../page/EstimateSend';
import DrawSign from '../page/DrawSign';
import SignRequest from '../page/SignRequest';
import SignParticipant from '../page/SignParticipant';
import SignRequestSettings from '../page/SignRequestSettings';
import Settings from '../page/Settings';
import Payment from '../page/Payment';
import Tutorial from '../page/Tutorial';
import OfferTemplate from '../page/OfferTemplate';
import OfferSignParticipant from '../page/OfferSignParticipant';
import OfferTemplateEditing from '../page/OfferTemplateEditing';
import OfferSignRequestSettings from '../page/OfferSignRequestSettings';

import { toast_success, toast_warning } from '../api/api';

const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		height: '100%',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		marginLeft: drawerWidth,
		boxShadow: 'none',
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: '#ccc',
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			backgroundColor: 'transparent',
			// color: theme.palette.primary.main,
			color: '#212529',
		},
	},
	toolbarButton: {
		backgroundColor: theme.palette.secondary.main,
		color: 'white',
		fontSize: 17,
		fontWeight: 'bold',
		paddingLeft: 20,
		paddingRight: 9,
		width: 150,
		height: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer',
		transition: 'width .2s',
		'&:hover': {
			width: 250,
			backgroundColor: theme.palette.secondary.dark,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingBottom: 28,
		width: '100vw',
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
		},
	},
	listItemIcon: {
		color: theme.palette.primary.contrastText,
	},
	listItemText: {
		color: theme.palette.primary.contrastText,
		fontWeight: 'bold',
	},
	menuitemactive: {
		borderLeftWidth: 5,
		borderLeftStyle: 'solid',
		borderLeftColor: 'white',
		backgroundColor: 'rgba(255, 255, 255, 0.12)',
		fontWeight: 'bold',
	},
	menuitem: {
		borderLeftWidth: 5,
		borderLeftStyle: 'solid',
		borderLeftColor: 'transparent',
		backgroundColor: 'transparent',
		opacity: '.4',
	},
	menuitemSecondary: {
		borderLeftColor: 'transparent',
		backgroundColor: 'transparent',
		opacity: '.4',
		'&:hover': {
			// fontWeight: 'bold',
			backgroundColor: 'rgba(255, 255, 255, 0.17)',
			color: 'white',
			opacity: '1',
		},
	},
	menuitemSecondaryActive: {
		borderLeftColor: 'white',
		backgroundColor: 'rgba(255, 255, 255, 0.12)',
		'&:hover': {
			// fontWeight: 'bold',
			backgroundColor: 'rgba(255, 255, 255, 0.17)',
			color: 'white',
			opacity: '1',
		},
	},
	secondaryColorText: {
		color: 'white',
		// color: theme.palette.secondary.main,
	},
	footer: {
		width: '100%',
		height: '2.2em',
		fontSize: '0.9em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		right: 0,
		bottom: 0,
		position: 'fixed',
		textAlign: 'center',
		backgroundColor: '#edf1f3',
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
		},
	},

	payButton: {
		height: "50px",
		marginTop: "25px",
		width: "100%",
		border: "1px solid rgba(255, 255, 255, 0.6)",
		color: "#fff",
		backgroundColor: "rgba(255, 255, 255, 0.12)",
		fontSize: "15px",
		'&:hover': {
			backgroundColor: "#fff",
			color: "#1B1464",
		},
	},
}));

const StyledMenuItem = withStyles(theme => ({
	root: {
		height: 75,
		paddingLeft: 30,
		// fontWeight: 'bold',
		fontSize: '1.5em',
		'&:hover': {
			// fontWeight: 'bold',
			backgroundColor: 'rgba(255, 255, 255, 0.17)',
			color: 'white',
			opacity: '1',
		},
	},
}))(MenuItem);

const StyledListItem = withStyles(theme => ({
	root: {
		height: 158,
		// backgroundColor: '#2a3344',
		backgroundColor: 'rgba(255, 255, 255, 0.12)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		fontWeight: 'bold',		
		fontSize: '1.2em',
		marginTop: '0.5em',
		'&:hover': {
			fontWeight: 'bold',
		},
	},
}))(ListItem);

const StyledButton = withStyles(theme => ({
	root: {
		// backgroundColor: theme.palette.primary.main,
		backgroundColor: 'white',
		borderWidth: 2,
        borderColor: 'white',
        color: theme.palette.primary.main,
		width: 190,
		height: 50,
		border: 'none',
		borderRadius: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: '1.2em',
		transition: 'all .3s',
        whiteSpace: 'nowrap',
		'&:hover': {
			// backgroundColor: theme.palette.primary.dark,
			borderWidth: 2,
			borderColor: 'white',
            backgroundColor: theme.palette.primary.dark,
            color: 'white',
		},

		// borderWidth: 2,
        // borderColor: theme.palette.primary.main,
        // color: theme.palette.primary.main,
        // backgroundColor: 'white',
        // paddingLeft: 6,
        // paddingRight: 6,
        // fontWeight: 'bold',
        // fontSize: '1.1em',
        // transition: 'all .3s',
        // whiteSpace: 'nowrap',
        // '&:hover': {
        //     borderWidth: 2,
        //     backgroundColor: theme.palette.primary.main,
        //     color: 'white',
        // },
	},
}))(Button);

const StyledButton2 = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
		height: 40,
		paddingLeft: 15,
		paddingRight: 15,
		marginRight: 10,
        border: 'none',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 17,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}))(Button);

function HideOnScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({ target: window ? window() : undefined });

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

function Layout(props) {
	const [oPageTitle] = useGlobal('oPageTitle');
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = useState(false);
	const [bAuth] = useGlobal('bAuth');
	const [aRoutePermitted] = useGlobal('aRoutePermitted');
	const [aPage] = useGlobal('aPage');
	const [bClickedStart] = useGlobal('bClickedStart');

	const [sToken] = useGlobal('sToken');
	const [sApiUrl] = useGlobal('sApiUrl');
	const [bTemplateView] = useGlobal('bTemplateView');
	
	
	const [bLoading, setLoading] = React.useState(false);

	const [iActiveDocuments, setActiveDocuments] = useGlobal('iActiveDocuments');
	const [oConfirmData, setConfirmData] = useState({});

	useEffect(() => {
		window.addEventListener("message", function(e) {
			setConfirmData(e.data);
        });

		if (bAuth === true) {
			return; // we need this ajax request when default page is not "My documents"
			let sUrl = `/${sApiUrl}/docSignRequest`;
			setLoading(true);
			axios.create({
				headers: {
					Authorization: `Bearer ${sToken}`,
				}
			}).get(sUrl)
				.then(res => {
					setLoading(false);
					switch (res.data.status) {
						case '000':
							if (res.data.entity !== undefined && res.data.entity !== null) {
								setActiveDocuments(res.data.entity.filter(item => {
									let bRet = false;
									if (item.docSignTargetList.length > 0) {
										item.docSignTargetList.forEach(element => {
											if (element.signYn !== undefined && element.signYn === 'Y') {
											} else {
												bRet = true;
											}
										});
									} else {
										bRet = true;
									}
									return bRet;
								}).length);
							} else {
								setActiveDocuments(0);
							}
							break;
						default:
							toast_warning(res.data.message);
					}
				}).catch(err => {
					setLoading(false);
					// toast_warning(`System error: ${JSON.stringify(err)}`);
				});
		}
	}, [sApiUrl, sToken, bAuth]);

	useEffect(() => {
		if(oConfirmData.flag == "G") {
			closeTutorial();
		} 
	}, [oConfirmData]);

	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen);
	}

	const loading = () => (
		<div>Loading...</div>
	);

	const drawer = (
		<div>
			<div className={classes.toolbar} style={{ display: 'flex', alignItems: 'stretch', height: '8em' }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flex: 1,
						fontWeight: 700,
						fontSize: '1.9em',
						// fontFamily: ['NanumSquare', 'Noto Sans KR', 'sans-serif'].join(','),
						color: theme.palette.primary.contrastText,
						cursor: 'pointer',
					}}
					onClick={() => {
						setMobileOpen(false);
						/*
						setMobileOpen(false);
						setGlobal({
							oPageTitle: { title: 'Welcome', desc: '' },
						});
						props.history.push('/');
						*/
						
						window.location.href = `http://onlinesign.co.kr/intro`;
					}}
				>
					{/* ONLINESIGN */}
					<img
						src="/logo.png"
						style={{
							width: '80%',
						}}
					/>
        		</div>
			</div>
			{/* <Divider /> */}
			<List>
				{aPage.filter(item => item.child === undefined).map((item, index) => {
					return (
						<StyledMenuItem
							key={item.title}
							className={props.location.pathname.startsWith(item.path) ? classes.menuitemactive : classes.menuitem}
							onClick={() => {
								setMobileOpen(false);
								setGlobal({
									oPageTitle: item,
								});
							
								props.history.push(item.path);
							}}
						>
							{item.title}
						</StyledMenuItem>
					);
				})}
				<StyledListItem
				// button
				// onClick={() => {
				//   setMobileOpen(false);
				//   setGlobal({
				//     oPageTitle: { title: '서명 요청', desc: '' },
				//   });
				//   props.history.push('/sign_request');
				// }}
				>
					<div>
						{'서명 요청'}
					</div>
					<div
						style={{
							fontSize: '1.7em',
							marginTop: '0.5em',
						}}
					>
						<span
							className={classes.secondaryColorText}
						>
							{iActiveDocuments}
              			</span>
						<span>건</span>
					</div>
				</StyledListItem>
			</List>
			<div
				style={{
					textAlign: "center",
					padding: "0 20px",
				}}
			>
				<button 
					className={classes.payButton}
					onClick={() => {
						setMobileOpen(false);
						setGlobal({
							oPageTitle: { title: '요금결제', desc: '사용하실 요금제를 선택하실 수 있습니다.' },
						});
						props.history.push('/payment');
					}}
				>
					요금결제
				</button>
			</div>
			{/* <Divider /> */}
			{/* <div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '9em',
				}}
			>
				<StyledButton
					variant="outlined"
					color="primary"
					onClick={() => {
						setMobileOpen(false);
						setGlobal({
							bClickedStart: true,
						});
						props.history.push('/document_template');
					}}
				>
					시작하기
        		</StyledButton>
			</div> */}
			{/* <List>
				<ListItem
					className={props.location.pathname.startsWith('/settings') ? classes.menuitemSecondaryActive : classes.menuitemSecondary}
					button
					onClick={() => {
						setMobileOpen(false);
						setGlobal({
							oPageTitle: { title: '설정', desc: '' },
						});
						props.history.push('/settings');
					}}
				>
					<ListItemIcon className={classes.listItemIcon}><EditIcon /></ListItemIcon>
					<ListItemText className={classes.listItemText} primary={'설정'} />
				</ListItem>
				<ListItem
					className={classes.menuitemSecondary}
					button
					onClick={() => {
						Cookies.remove('token');
						window.location.reload();
					}}
				>
					<ListItemIcon className={classes.listItemIcon}><ExitIcon /></ListItemIcon>
					<ListItemText className={classes.listItemText} primary={'로그아웃'} />
				</ListItem>
			</List> */}
		</div>
	);

	if (bAuth === false) {
		console.log(props.location.pathname);
		if (aRoutePermitted.filter(item => item === props.location.pathname).length === 0) {
			return <Redirect to="/login" />;
		} else {
			return <Redirect to={`${props.location.pathname}${props.location.search}`} />;
		}
	}

	const closeTutorial = () => {
		document.body.style.overflow = 'auto';
		const tutorialWindow = document.getElementById('tutorialWindow')
		document.body.removeChild(tutorialWindow);
		
	}
	

	return (
		
		<div className={classes.root}>
			<CssBaseline />
			<Hidden smUp implementation="css">
				<AppBar position="fixed" className={classes.appBar} style={{display : (bTemplateView ? 'none' : '')}}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							className={classes.menuButton}
						>
							<MenuIcon />
						</IconButton>
						<div
							style={{
								width: '100%',
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								justifyContent: 'center',
							}}
						>
							<Typography variant="h4" noWrap>
								{oPageTitle.title}
							</Typography>
							<Typography noWrap style={{ width: 'calc(100% - 50px)' }}>
								{oPageTitle.desc}
							</Typography>
						</div>
					</Toolbar>
				</AppBar>
			</Hidden>
			<Hidden xsDown implementation="css">
				{/* <HideOnScroll {...props}> */}
					<AppBar position="fixed" className={classes.appBar} style={{display : (bTemplateView ? 'none' : '')}}>
						<Toolbar style={{
							height: 50,
							backgroundColor: 'white',
							// boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.05)',
							borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
							padding: 0,
						}}>
							<div
								style={{
									width: '100%',
									height: '100%',
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								<div
									className={classes.toolbarButton}
									onClick={() => {
										setMobileOpen(false);
										setGlobal({
											bClickedStart: true,
										});
										//props.history.push('/document_template');
										//props.history.push('/tutorial');
										const bg = document.createElement('div');
										bg.id = 'tutorialWindow';
										bg.style.position = 'absolute';
										bg.style.width = '100vw';
										bg.style.height = '100vw';
										bg.style.top = document.documentElement.scrollTop;
										bg.style.left = '0';
										bg.style.backgroundColor = 'rgba(0,0,0,0.8)';
										bg.style.zIndex = '9999';

										const div = document.createElement('div');
										div.style.width = '100vw';
										div.style.textAlign = 'right';

										const close = document.createElement('img');
										close.src = '/images/tutorial/close.png';
										close.style.width = '50px';
										close.addEventListener('click', function(event){
											closeTutorial();
										});

										div.appendChild(close);
										bg.appendChild(div);

										const divArrow = document.createElement('div');
										divArrow.style.width = '100vw';
										divArrow.style.display = 'flex';
										
										const divLeft = document.createElement('div');
										divLeft.style.width = '100vw';
										divLeft.style.textAlign = 'left';
										divLeft.style.paddingTop = '18%';
										divLeft.style.paddingLeft = '9%';

										const left = document.createElement('img');
										left.src = '/images/arrow_left.png';
										left.style.width = '70px';
										left.addEventListener('click', function(event){
											const iframe = document.getElementById('iframeTutorial').contentWindow;
											iframe.postMessage({ cmd : 'prev'}, '*');
										});

										divLeft.appendChild(left);
										divArrow.appendChild(divLeft);

										const divRight = document.createElement('div');
										divRight.style.width = '100vw';
										divRight.style.textAlign = 'right';
										divRight.style.paddingTop = '18%';
										divRight.style.paddingRight = '9%';

										const right = document.createElement('img');
										right.src = '/images/arrow_right.png';
										right.style.width = '70px';
										right.addEventListener('click', function(event){
											const iframe = document.getElementById('iframeTutorial').contentWindow;
											iframe.postMessage({ cmd : 'next'}, '*');
										});

										divRight.appendChild(right);
										divArrow.appendChild(divRight);
										bg.appendChild(divArrow);

										const tutorial = document.createElement('iframe');
										tutorial.id = 'iframeTutorial';
										//로컬
										//tutorial.src = 'http://localhost:8082/intro/tutorial/tutorial';
										//운영
										tutorial.src = 'https://onlinesign.co.kr/intro/tutorial';
										tutorial.frameBolder = '0';
										tutorial.style.position = 'absolute';
										tutorial.style.top = '25%';
										tutorial.style.left  = '50%';
										tutorial.style.transform  = 'translate(-50%, -50%)';
										tutorial.width  = '1050';
										tutorial.height  = '650';
										tutorial.style.border  = '0';
										tutorial.style.borderRadius  = '10';

										bg.appendChild(tutorial);
										document.body.appendChild(bg)
										document.body.style.overflow = 'hidden';
									}}
								>
									시작하기
									<img
										src="/images/next-icon.png"
										style={{
											height: '50%',
										}}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										marginRight: 10,
									}}
								>
									<StyledButton2
										onClick={() => {
											setMobileOpen(false);
											setGlobal({
												oPageTitle: { title: '설정', desc: '' },
											});
											props.history.push('/settings');
										}}
									>
										설정
									</StyledButton2>
									<StyledButton2
										onClick={() => {
											Cookies.remove('token');
											window.location.reload();
										}}
									>
										로그아웃
									</StyledButton2>
								</div>
							</div>
						</Toolbar>
						<Toolbar style={{
							height: 70,
							backgroundColor: 'white',
						}}>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								className={classes.menuButton}
							>
								<MenuIcon />
							</IconButton>
							<div
								style={{
									width: '100%',
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start',
									justifyContent: 'space-around',
								}}
							>
								<Typography variant="h6" noWrap>
									{oPageTitle.title}
								</Typography>
								<Typography noWrap>
									{oPageTitle.desc}
								</Typography>
							</div>
						</Toolbar>
					</AppBar>
				{/* </HideOnScroll> */}
			</Hidden>
			<nav className={classes.drawer} aria-label="mailbox folders" style={{display : (bTemplateView ? 'none' : '')}}>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content} style={{padding : (bTemplateView ? '0 0 28px 0' : '')}}>
				{(!bTemplateView) && (
				<div>
					<Hidden smUp implementation="css">
						<div className={classes.toolbar} />
					</Hidden>
					<Hidden xsDown implementation="css">
						<div className={classes.toolbar} style={{
							height: 120,
						}} />
					</Hidden>
				</div>
				)}
				
				<Suspense fallback={loading()}>
					<Route path="/" exact component={Home} />
					<Route path="/my_document" exact component={MyDocument} />
					<Route path="/my_document/sign_participant" exact component={SignParticipant} />
					<Route path="/my_document/sign_request_settings" exact component={SignRequestSettings} />
					<Route path="/document_template" exact component={DocumentTemplate} />
					<Route path="/document_template/document_template_creation" exact component={DocumentTemplateCreation} />
					<Route path="/document_template/template_editing" exact component={TemplateEditing} />
					<Route path="/document_template/document_template_editing" exact component={DocumentTemplateEditing} />
					<Route path="/document_template/document_template_view" exact component={DocumentTemplateView} />
					<Route path="/data_upload" exact component={DataUpload} />
					{/*<Route path="/esti_send" exact component={EstimateSend} />*/}
					<Route path="/draw_sign" exact component={DrawSign} />
					<Route path="/sign_request" exact component={SignRequest} />
					<Route path="/settings" exact component={Settings} />
					<Route path="/payment" exact component={Payment} />
					<Route path="/tutorial" exact component={Tutorial} />
					<Route path="/offer_template" exact component={OfferTemplate} />
					<Route path="/offer_template/sign_participant" exact component={OfferSignParticipant} />
					<Route path="/offer_template/offer_template_editing" exact component={OfferTemplateEditing} />
					<Route path="/offer_template/sign_request_settings" exact component={OfferSignRequestSettings} />
				</Suspense>
			</main>
			<footer
				className={classes.footer}
				style={{width : (bTemplateView ? 'calc(100%)' : '')}}
			>
				© 2020. Onlinesign Inc. All rights reserved.
      </footer>
		</div>
	);
}

export default Layout;