import React, { useState, useEffect, useGlobal } from 'reactn';
import { Redirect } from "react-router-dom";
import {
    Typography,
    Button,
    Divider,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import queryString from 'query-string';
import axios from "axios";

import { toast_success, toast_warning } from '../api/api';

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: "#6141d2",
        color: "#fff",
        width: '100%',
        marginTop: 16,
        height: 45,
        borderRadius: 0,
        fontWeight: 'bold',
        WebkitBorderRadius: "50px",
        fontSize: 16,        
        "&:hover": {
            opacity: 0.85,
            backgroundColor: "#6141d2",
            //color: "#435e99",
        },
    },
    buttonSns: {
        width: '48%',
        marginTop: 16,
        height: 40,
        borderRadius: 0,
    },
    inputField: {
        borderRadius: 0,
    },
    bottomLink: {
        color: 'black',
        fontSize: 12,
        textDecoration: 'none',
        marginLeft: 10,
        marginRight: 10,
    },
}));

const CssTextValidator = withStyles({
    root: {
        backgroundColor: "#f2f2f2",
        width:'100%',
        
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                //   borderColor: 'red',
                borderRadius: 0,
                border: 'none',
            },
            '&:hover fieldset': {
                //   borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                //   borderColor: 'green',
            },
        },
    },
})(TextValidator);

function Entity(props) {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [token, setToken] = useState('');
    const [bLoading, setLoading] = useState(false);
    const [bAuth] = useGlobal('bAuth');
    const [sApiUrl] = useGlobal('sApiUrl');

    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        if (parsed.token !== undefined) {
            setToken(parsed.token);
        } else {
            props.history.push('/login');
        }
    }, [props]);

    useEffect(() => {
        ValidatorForm.addValidationRule('isPwdCorrect', value => {
            const re = /^[A-Za-z\d!@#$%^&_\-|?,.\\/=+*]{8,15}$/;
            return re.test(value);
        });
    }, []);
    useEffect(() => {
        ValidatorForm.addValidationRule('isPwdSame', value => {
            return value === password;
        });
        return () => {
            ValidatorForm.removeValidationRule('isPwdSame');
        };
    }, [password]);

    if (bAuth) {
        return <Redirect to="/" />;
    }

    return (
        <div
            style={{
                // backgroundColor: 'green',
                padding: 10,
                paddingTop: 50,
                paddingBottom: 50,
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <h1 
                style={{
                    // backgroundColor: 'red',
                    fontSize: 42,
                    fontWeight: 100,
                    textAlign : 'center',
                    letterSpacing : '3px',
                    marginBottom : '28px',
                    marginTop : '100px',
                }}
            >
                <img 
                        src="images/logo1-6.png" 
                        alt=""
                        style={{
                            width:'100%',
                            maxWidth:'260px',
                            minWidth: '200px',
                            cursor: 'pointer',
                        }} 
                        onClick={event => {
                            window.location.href = 'https://onlinesign.co.kr/intro';
                        }}                 
                />
            </h1>
            <div
                style={{
                    // backgroundColor: 'red',
                    minWidth: 300,
                    maxWidth: 400,
                }}
            >
                <Typography variant="h6" gutterBottom style={{ marginTop: 10, textAlign: 'center' }}>
                    비밀번호 재설정
                </Typography>
                <Divider
                    variant="middle"
                    style={{
                        width: '100%',
                        margin: 0,
                        marginTop: 0,
                        marginBottom: 20,
                    }}
                />
                <ValidatorForm
                    onSubmit={event => {
                        event.preventDefault();
                        setLoading(true);
                        // console.log(password, token);
                        axios.create().post(`/${sApiUrl}/user/changepass`, { token, newPass: password })
                        .then(res => {
                            setLoading(false);
                            if (res.data.status === '000') {
                                toast_success("Password has been changed");
                                props.history.push('/login');
                            } else {
                                toast_warning(res.data.message);
                            }
                        }).catch(err => {
                            setLoading(false);
                            toast_warning(err.response.statusText);
                        });
                    }}
                    // onError={errors => console.log(errors)}
                >
                    <CssTextValidator
                        //label="영문/숫자/특수문자 조합 8~15자"
                        placeholder='비밀번호 입력(8~12자리 영문, 숫자, 특수기호 포함)'
                        variant="outlined"
                        fullWidth
                        type="password"
                        value={password}
                        onChange={event => {
                            setPassword(event.target.value);
                            setPassword2('');
                        }}
                        margin="dense"
                        className={classes.inputField}
                        validators={['required', 'isPwdCorrect']}
                        errorMessages={['비밀번호를 영문,숫자,특수문자 조합8~15자리로 입력해주세요.', '비밀번호를 영문,숫자,특수문자 조합8~15자리로 입력해주세요.']}
                    />
                    <CssTextValidator
                        //label="비밀번호 재확인"
                        placeholder='비밀번호 재확인'
                        variant="outlined"
                        fullWidth
                        type="password"
                        value={password2}
                        pw1={password}
                        onChange={event => {
                            setPassword2(event.target.value);
                        }}
                        margin="dense"
                        className={classes.inputField}
                        validators={['required', 'isPwdSame']}
                        errorMessages={['비밀번호를 영문,숫자,특수문자 조합8~15자리로 입력해주세요.', '동일한 비밀번호를 입력해주세요.']}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={bLoading}
                    >
                        비밀번호 변경
                    </Button>
                </ValidatorForm>
            </div>
            <Divider
                variant="middle"
                style={{
                    width: '100%',
                    margin: 0,
                    marginTop: 80,
                    marginBottom: 20,
                }}
            />
            <div>
                Copyright © 2020 OnlineSign All Rights Reserved.
            </div>
        </div>
    );
}

export default Entity;