import React, { Fragment, useState, useEffect } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { toast_warning } from '../api/api';

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const Dialog = withStyles(theme => ({
    paper: {
        width: '100%',
        maxWidth: 500,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 4,
        //     marginRight: 4,
        // },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    button_sign_request: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    button_cancel: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            // color: 'white',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    dialogAction: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 140,
        },
    },
}));

function Entity(props) {

    const classes = useStyles();

    const [iExpireDay, setExpireDay] = useState(props.docToEdit.expire_day);
    const [iParticipant, setParticipant] = useState(props.docToEdit.participant);

    useEffect(() => {
        setExpireDay(props.docToEdit.expire_day);
        setParticipant(props.docToEdit.participant);
        
    }, [props.docToEdit]);
    
    return (
        <Fragment>
            <Dialog
                // onClose={props.onClose}
                aria-labelledby="dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogContent dividers>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 50,
                            }}
                        >
                            <h3>서명 유효기간 변경</h3>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 75,
                            }}
                        >
                            <p>{iParticipant}님의 서명 유효기간 만료일을 재설정합니다.</p>
                            <div>
                                <TextField
                                    label="일후 만료"
                                    type="number"
                                    margin="dense"
                                    style={{
                                        marginTop: 3,
                                        marginBottom: 20,
                                        width: 120,
                                    }}
                                    // placeholder="이름 또는 회사명을 입력해 주세요."
                                    // fullWidth
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={iExpireDay}
                                    onChange={e => {
                                        try {
                                            const iNewValue = parseInt(e.target.value, 10);
                                            if (iNewValue > 0) {
                                                setExpireDay(iNewValue);
                                            } else {
                                                toast_warning('숫자만 입력해주세요.');
                                            }
                                        } catch (error) {
                                            //toast_warning(JSON.stringify(error));
                                            toast_warning("에러가 발생하였습니다. 고객센터로 문의해주세요.");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.dialogAction}>
                        {props.bLoading && (
                            <div
                                style={{
                                    // width: '100%',
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}
                            >
                                <CircularProgress disableShrink />
                            </div>
                        )}
                        {!props.bLoading && (
                            <Fragment>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.button_sign_request}
                                    onClick={() => {
                                        props.onClickChange(iExpireDay);
                                    }}
                                >
                                    변경 하기
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.button_cancel}
                                    onClick={props.onClickCancel}
                                >
                                    취소
                                </Button>
                            </Fragment>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default Entity;