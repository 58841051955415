import React, { Fragment, useRef, useState, useGlobal,useEffect } from 'reactn';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import axios from "axios";
import BoxFilePicker from './BoxFilePicker';

import { toast_warning } from '../api/api';
import { formatDate } from '../lib/lib';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;    
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1.4em' }}>{children[0]}</Typography>
            <Typography style={{ fontSize: '0.8em', textAlign: 'left' }}>
                        템플릿을 선택해주세요.
            </Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },        
    },
}))(MuiDialogContent);

const IconButton = withStyles(theme => ({
    root: {
        flex: '0 0 auto',
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '5px',
        overflow: 'visible',
        fontSize: '1.5rem',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        
    },
}))(MuiIconButton);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
}))(MuiDialogActions);

const Dialog = withStyles(theme => ({
    paper: {
        width: '100%',
        maxWidth: 768,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchPaper: {
        border: '1px solid #ccc',
        boxShadow: 'none',
        display: 'flex',
        transition: 'all .4s',
        width: '70%',
        '&:hover, &:focus-within': {
            width: '100%',
        },
        height : "35px"
    },
    table: {
        minWidth: 400,
    },
    tableWrapper: {
        overflowX: 'scroll',
        [theme.breakpoints.up('sm')]: {
            overflowX: 'unset',
        },
    },
    container: {
        maxHeight: 440,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableRowTemplateName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: 300,
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 440,
        },
    },
}));

const headCells = [
    { id: 'template_name', numeric: false, disablePadding: false, label: '템플릿 이름' },
    { id: 'created_date', numeric: true, disablePadding: false, label: '생성시간' },
    { id: 'updated_date', numeric: true, disablePadding: false, label: '업데이트 시간' },
];
  
function createData(name, insertTime, updateTime,) {
return { name, insertTime, updateTime };
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>                
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headCell.numeric ? { width: 200 } : { width : 370 }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                fontSize: '1.4em',
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    //numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    //onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};



function Entity(props) {
    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    
    const [bLoading, setLoading] = React.useState(false);
    
    const [sDomainName] = useGlobal('sDomainName');

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('updated_date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);
    const [sQuery, setQuery] = useState('');
    const [sSearchQuery, setSearchQuery] = useState('');
    const [rows, setRows] = useState([]);

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    /*
    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }
    */
    function handleClick(event, id) {
        
        console.log("handleclick::"+id);
        setSelected(id);
    }
    
    const isSelected = id => selected.length !== 0;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    

    useEffect(() => {
        const fetchData = () => {
            let sUrl = `/${sApiUrl}/docTemplate`;
            if (sQuery !== null && sQuery !== undefined && sQuery !== '') {
                sUrl = `/${sApiUrl}/docTemplate/search?content=${sQuery}`;
            }
            setLoading(true);
            axios.create({
                headers: {
                    Authorization: `Bearer ${sToken}`,
                }
            }).get(sUrl)
            .then(res => {
                setLoading(false);
                switch (res.data.status) {
                    case '000':
                        setRows(res.data.entity.map(item => {
                            let sCreatedDate = '';
                            if (item.regDate !== null) {
                                console.log(item.regDate);
                                //const oDate = new Date(item.regDate);
                                //sCreatedDate = oDate.toDateString();
                                sCreatedDate = item.regDate.substring(0,item.regDate.length-3);
                            }
                            let sUpdatedDate = '';
                            if (item.updDate !== null) {
                                //const oUpdDate = new Date(item.updDate);
                                //sUpdatedDate = oUpdDate.toDateString();
                                sUpdatedDate = item.regDate.substring(0,item.updDate.length-3);
                            }
                            return {
                                id: item.templateIdx,
                                template_name: item.templateName,
                                apiKey: item.apiKey,
                                created_date: sCreatedDate,
                                updated_date: sUpdatedDate,
                            };
                        }));
                        break;
                    default:
                        toast_warning(res.data.message);
                }
            }).catch(err => {
                setLoading(false);
                toast_warning(`System error: ${JSON.stringify(err)}`);
            });
        };
        fetchData();
        //setPage(0);
    }, [sApiUrl, sToken, sQuery]);

    return (
        <Fragment>
            <Dialog
                onClose={props.onClose}
                aria-labelledby="fileuploader-dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogTitle id="fileuploader-dialog-title" onClose={props.onClose}>
                    템플릿 조회                    
                    <Typography style={{ fontSize: '0.8em', textAlign: 'left' }}>
                        템플릿을 선택해주세요.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>                   
                    
                    <div
                        className={classes.DialogContent}
                    >
                            <Fragment>                                
                                <div style={{ display: 'inline-block' }}>
                                    
                                    <Paper className={classes.searchPaper}>
                                        <form
                                            onSubmit={event => {
                                                event.preventDefault();
                                                setQuery(sSearchQuery);
                                            }}
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            <InputBase
                                                className={classes.input}
                                                placeholder="검색어를 입력해 주세요."
                                                inputProps={{ 'aria-label': '검색어를 입력해 주세요.' }}
                                                value={sSearchQuery}
                                                onChange={e => setSearchQuery(e.target.value)}
                                            />
                                            <IconButton className={classes.iconButton} aria-label="search" type="submit">
                                                <SearchIcon />
                                            </IconButton>
                                        </form>
                                    </Paper>
                                </div>
                                {/* <TableContainer className={classes.container}> */}
                                    <Table stickyHeader aria-label="sticky table">
                                        <EnhancedTableHead
                                            classes={classes}
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            //onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                        {stableSort(rows, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                    >
                                                       
                                                        <TableCell component="th" id={labelId} scope="row" padding="none">
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    className={classes.tableRowTemplateName}
                                                                    onClick={e => {
                                                                        handleClick(e, row.id);
                                                                        props.setDocument( row.id );                                                                           
                                                                        //document.close = {props.close};
                                                                    }}                                                                    
                                                                >
                                                                    {row.template_name}
                                                                </div>                                                                
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="right">{row.created_date}</TableCell>
                                                        <TableCell align="right">{row.updated_date}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && rows.length > 0 && (
                                            <TableRow style={{ height: 49 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                {/* </TableContainer>                                */}
                            </Fragment>                   
                    </div>
                </DialogContent>                
                <DialogActions>
                   
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default Entity;