import React from 'reactn';

function Entity() {

  return (
    <div>
      <div>Sign request</div>
    </div>
  );
}

export default Entity;