import React, { useState, setGlobal, useEffect, useGlobal } from 'reactn';
import { Redirect } from "react-router-dom";
import {
    Typography,
    TextField,
    Button,
    Divider,
    Checkbox,
    Link,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import axios from "axios";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
//import Kakao from 'kakaojs';
import KakaoLogin from 'react-kakao-login';
import clsx from 'clsx';

import { toast_success, toast_warning } from '../api/api';

const buttonSns = {
    width: "100%",
    marginTop: 6,
    height: 40,
    WebkitBorderRadius: 5,
};

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: "#6141d2",
        color: "#fff",
        width: '100%',
        marginTop: 16,
        height: 45,
        borderRadius: 0,
        fontWeight: 'bold',
        WebkitBorderRadius: "50px",
        fontSize: 16,        
        "&:hover": {
            opacity: 0.85,
            backgroundColor: "#6141d2",
            //color: "#435e99",
        },
    },
    buttonSns: {
        width: '48%',
        marginTop: 16,
        height: 40,
        borderRadius: 0,
    },
    buttonNaver: {
        backgroundColor: "#1ecd00",
        "&:hover": {
            //color: "#1ecd00",
            backgroundColor: "#1ecd00",
            opacity: 0.85,
        },
        color: "#fff",
        ...buttonSns,
    },
    buttonKakao: {
        backgroundColor: "#ffe800",
        "&:hover": {
            backgroundColor: "#ffe800",
            opacity: 0.8,
        },
        color: "#381e1f",
        ...buttonSns,
    },
    buttonFacebook: {
        backgroundColor: "#435e99",
        "&:hover": {
            backgroundColor: "#435e99",
            //color: "#435e99",
            opacity: 0.85,
        },
        color: "#fff",
        ...buttonSns,
    },
    buttonGoogle: {
        backgroundColor: "#e94337",
        "&:hover": {
            backgroundColor: "#e94337",
            //color: "#e94337",
            opacity: 0.85,
        },
        color: "#fff",
        ...buttonSns,
    },
    inputField: {
        borderRadius: 0,
    },
    bottomLink: {
        color: 'black',
        fontSize: 12,
        textDecoration: 'none',
        marginLeft: 10,
        marginRight: 10,
    },

    icon: {
        display: "inline-block",
        verticalAlign: "middle",
        //white: nowrap;
        width: "24px",
        height: "24px",
        //lineheight: "24px",
        borderRadius: "15px",
        background: "#fff",
        border: "2px solid #aaa",
        backgroundImage: "url(images/check.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
        backgroundSize: "14px 9px",
        cursor: "pointer",
        position: "relative",
        //WebkitBorderRadius: '15px',
        //MozBorderRadius: '15px',
        //border-radius: 3px,
        //-webkit-border-radius: 15px,
        //-moz-border-radius: 15px,
        //-ms-border-radius: 15px,
        //-o-border-radius: 15px,
        //position: relative,
        top: "-1px",
    },
    checkedIcon: {
        display: "inline-block",
        verticalAlign: "middle",
        //white: nowrap;
        width: "24px",
        height: "24px",
        //lineheight: "24px",
        borderRadius: "15px",
        background: "#fff",
        border: "2px solid #531580",
        backgroundImage: "url(images/checked.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
        backgroundSize: "14px 9px",
        cursor: "pointer",
        position: "relative",
        //border-radius: 3px,
        //-webkit-border-radius: 15px,
        //-moz-border-radius: 15px,
        //-ms-border-radius: 15px,
        //-o-border-radius: 15px,
        //position: relative,
        top: "-1px",
    },
    saveEmailClass: {
        color:"#999",     
        height: 45,   
        '& .MuiTypography-root': {
            //   borderColor: 'red',
            fontWeight: "bold",
            fontSize : "16px",
        },
    },
}));

const CssTextField = withStyles({
    root: {
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                //   borderColor: 'red',
                borderRadius: 0,
            },
            '&:hover fieldset': {
                //   borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                //   borderColor: 'green',
            },
        },
    },
})(TextField);
const CssTextValidator = withStyles({
    root: {
        backgroundColor: "#f2f2f2",
        width:'100%',
        
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                //   borderColor: 'red',
                borderRadius: 0,
                border: 'none',
            },
            '&:hover fieldset': {
                //   borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                //   borderColor: 'green',
            },
        },
    },
})(TextValidator);

function Entity(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [userType] = useState('01'); // 01-Individual, 02-Copropation
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [bLoading, setLoading] = useState(false);
    const [bAuth] = useGlobal('bAuth');
    const [sGoogleClientId] = useGlobal('sGoogleClientId');
    const [sFacebookAppId] = useGlobal('sFacebookAppId');
    const [sDomainName] = useGlobal('sDomainName');
    const [sNaverClientId] = useGlobal('sNaverClientId');
    const [sApiUrl] = useGlobal('sApiUrl');
    const [sUserEmail,setUserEmail] = useGlobal('sUserEmail');
    
    const classes = useStyles();

    useEffect(() => {
        ValidatorForm.addValidationRule('isPwdCorrect', value => {
            //const re = /^[A-Za-z\d!@#$%^&_\-|?,.\\/=+*]{8,15}$/;
            const re = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/
            return re.test(value);
        });
    }, []);

    useEffect(() => {
        ValidatorForm.addValidationRule('isPwdSame', value => {
            return value === password;
        });
        return () => {
            ValidatorForm.removeValidationRule('isPwdSame');
        };
    }, [password]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const loginSns = (type, token) => {
        if (token !== undefined && token.length > 0) {
            setLoading(true);
            axios
                .create()
                .post(`/${sApiUrl}/user/loginsns`, {
                    type,
                    token,
                    userType: "01",
                })
                .then(res => {
                    setLoading(false);
                    if (res.data.status === "000" && res.data.entity !== null) {
                        setGlobal({
                            bAuth: true,
                            sToken: res.data.entity.token,
                            iUserId: res.data.entity.user.userIdx,
                            sUserName: res.data.entity.user.userName,
                            sUserEmail: res.data.entity.user.userEmail,
                            // sUserSnsType: res.data.entity.user.socialType,
                        });
                        toast_success("로그인에 성공했습니다.");
                    } else {
                        toast_warning(res.data.message);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast_warning(JSON.stringify(err));
                });
        }
    };

    const responseGoogle = (response) => {
        loginSns('google', response.tokenId);
    };

    const responseFacebook = (response) => {
        loginSns('facebook', response.accessToken);
    };

    const responseKakao = (response) => {
        loginSns('kakao', response.response.access_token);
    };

    if (bAuth) {
        return <Redirect to="/my_document" />;
    }
    
    const setCookie = (name, value, expiredays) => {
        let todayDate = new Date();
        todayDate.setDate(todayDate.getDate() + expiredays);
        document.cookie = name + "=" + escape(value) + "; path=/; expires="
                + todayDate.toGMTString() + ";"
    };
    /*
    const signupsns = (type, token) => {
        if (token !== undefined && token.length > 0) {
            setLoading(true);
            axios.create()
            .post(`/${sApiUrl}/user/signupsns`, { type, token, userType })
            .then(res => {
                setLoading(false);
                if (res.data.status === "000") {
                    toast_success("Successfully registered");
                    props.history.push('/login');
                } else {
                    toast_warning(res.data.message);
                }
            }).catch(err => {
                setLoading(false);
                toast_warning(JSON.stringify(err));
            });
        }
    };

    const responseGoogle = (response) => {
        signupsns('google', response.tokenId);
    }

    const responseFacebook = (response) => {
        signupsns('facebook', response.accessToken);
    }
    
    if (bAuth) {
        return <Redirect to="/" />;
    }
    */
    return (
        <div
            style={{
                // backgroundColor: 'green',
                //padding: 10,
                //paddingTop: 50,
                //paddingBottom: 50,
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: 450,
                minWidth: 320,
                margin: '0 auto',
                padding: '0 27px',

                //backgroundColor: "#edf1f3",
                //flex: 1,
            }}
        >
            <Typography align="center"
                style={{
                    // backgroundColor: 'red',
                    fontSize: 21,
                    fontWeight: 700,
                    textAlign : 'center',
                    paddingBottom : '30px',
                    paddingTop : '30px',
                    //lineHeight : 28,
                }}
            >
                <img 
                        src="images/logo1-6.png" 
                        alt=""
                        style={{
                            width:'100%',
                            maxWidth:'260px',
                            minWidth: '200px',
                            cursor: 'pointer',
                        }} 
                        onClick={event => {
                            window.location.href = 'https://onlinesign.co.kr/intro';
                        }}                 
                /><br />
                쉽고 빠른 전자계약<br /><span style={{color:'#6141d2'}}>온라인싸인</span>으로 시작해보세요!
            </Typography>
            <div
                style={{
                    // backgroundColor: 'red',
                    minWidth: 300,
                    maxWidth: 400,
                }}
            >
                <ValidatorForm
                    onSubmit={event => {
                        event.preventDefault();
                        if (checkbox2) {
                            setLoading(true);
                            axios.create()
                            .post(`/${sApiUrl}/user/signup`, {
                                userName: name,
                                userEmail: email,
                                userPwd: password,
                                userType,
                            }).then(res => {
                                setLoading(false);
                                if (res.data.status === '000') {
                                    toast_success("회원가입에 성공하였습니다.");
                                    setUserEmail(email);
                                    props.history.push('/login');
                                }
                                if (res.data.status === '003') {
                                    toast_warning("이메일이 이미 존재합니다.");
                                }
                                if (res.data.status === '015') {
                                    toast_warning(res.data.message);
                                }
                                if (res.data.result === '001') {
                                    toast_warning("서버가 오류가 발생하였습니다. 문의해주세요.");
                                }
                            }).catch(() => {
                                setLoading(false);
                                toast_warning("서버가 오류가 발생하였습니다. 문의해주세요.");
                            });
                        } else {
                            // alert('You have to accept terms and conditions');
                            toast_warning("필수동의를 체크해주세요.");
                        }
                    }}
                    // onError={errors => console.log(errors)}
                >
                    <CssTextValidator
                        //label="이름을 입력해주세요."
                        placeholder='이름을 입력해주세요.'
                        onChange={event => {
                            setName(
                                event.target.value
                                    ? event.target.value.replace(/\s/g, '')
                                    : ""
                            );
                        }}
                        name="name"
                        // type="email"
                        value={name}
                        validators={["required"]}
                        errorMessages={["이름은 필수입력입니다."]}
                        margin="dense"
                        className={classes.inputField}
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                    />
                    {/* <CssTextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={event => {
                            setName(event.target.value);
                        }}
                        margin="dense"
                    /> */}
                    <CssTextValidator
                        //label="이메일 주소를 입력해주세요."
                        placeholder='이메일 주소를 입력해주세요.'
                        onChange={event => {
                            setEmail(event.target.value);
                        }}
                        name="email"
                        // type="email"
                        value={email}
                        validators={['required', 'isEmail']}
                        errorMessages={['이메일은 필수입력입니다.','올바른 이메일을 입력하세요.']}
                        margin="dense"
                        className={classes.inputField}
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                    />
                    <CssTextValidator
                        //label="영문/숫자/특수문자 조합 8~15자"
                        placeholder='비밀번호 입력(8~12자리 영문, 숫자, 특수기호 포함)'
                        variant="outlined"
                        fullWidth
                        type="password"
                        value={password}
                        onChange={event => {
                            setPassword(event.target.value);
                            setPassword2('');
                        }}
                        margin="dense"
                        className={classes.inputField}
                        validators={['required', 'isPwdCorrect']}
                        errorMessages={['비밀번호를 영문,숫자,특수문자 조합8~15자리로 입력해주세요.', '비밀번호를 영문,숫자,특수문자 조합8~15자리로 입력해주세요.']}
                    />
                    <CssTextValidator
                        //label="비밀번호 재확인"
                        placeholder='비밀번호 재확인'
                        variant="outlined"
                        fullWidth
                        type="password"
                        value={password2}
                        pw1={password}
                        onChange={event => {
                            setPassword2(event.target.value);
                        }}
                        margin="dense"
                        className={classes.inputField}
                        validators={['required', 'isPwdSame']}
                        errorMessages={['비밀번호를 영문,숫자,특수문자 조합8~15자리로 입력해주세요.', '동일한 비밀번호를 입력해주세요.']}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 15,
                        }}
                    >
                        <div
                             style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 height: '35px',
                             }}
                        >
                            <Checkbox
                                id="checkbox_accept_all"
                                color="default"
                                checked={checkbox1}
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                                    
                                onChange={event => {
                                    setCheckbox1(event.target.checked);
                                    setCheckbox2(event.target.checked);
                                    setCheckbox3(event.target.checked);

                                    const labChk1 = document.getElementById("lab_checkbox1");
                                    const labChk2 = document.getElementById("lab_checkbox2");
                                    const labChk3 = document.getElementById("lab_checkbox3");
                                    if(event.target.checked) {
                                        labChk1.style.color = "#531580";
                                        labChk2.style.color = "#531580";
                                        labChk3.style.color = "#531580";
                                    } else {
                                        labChk1.style.color = "#999";
                                        labChk2.style.color = "#999";
                                        labChk3.style.color = "#999";
                                    }
                                    
                                }}
                            />
                            <label htmlFor="checkbox_accept_all" id="lab_checkbox1"
                                style={{
                                    fontWeight: "bold",
                                    //fontSize : "14px",
                                    color : "#999",
                            }}>
                                전체동의
                            </label>
                            {/**
                            <button
                                type="button"
                                style={{
                                    background: '#2f3a4c',
                                    color: 'white',
                                    maxWidth: 20,
                                    maxHeight: 20,
                                    borderRadius: 20,
                                    border: 'none',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    marginLeft: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    outline: 'none',
                                }}
                                onClick={() => {
                                    window.open('http://mslk.co.kr/', '_blank');
                                }}
                            >
                                ?
                            </button>
                             */}
                        </div>
                        <div
                             style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 height: '35px',
                             }}
                        >
                            <Checkbox
                                id="checkbox_personal_information_policy"
                                color="default"
                                checked={checkbox2}
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                                    
                                onChange={event => {
                                    setCheckbox2(event.target.checked);

                                    const labChk2 = document.getElementById("lab_checkbox2");
                                    if(event.target.checked) {
                                        labChk2.style.color = "#531580";
                                    } else {
                                        labChk2.style.color = "#999";
                                    }

                                    const labChk1 = document.getElementById("lab_checkbox1");
                                    if(checkbox3 && event.target.checked) {
                                        setCheckbox1(true);
                                        labChk1.style.color = "#531580";
                                    } else {
                                        setCheckbox1(false);
                                        labChk1.style.color = "#999";
                                    }
                                    
                                }}
                            />
                            <label htmlFor="checkbox_personal_information_policy" id="lab_checkbox2"
                                style={{
                                    fontWeight: "bold",
                                    //fontSize : "16px",
                                    color : "#999",
                            }}>
                                이용약관, 개인정보처리방침에 동의합니다.<strong>(필수)</strong>
                            </label>
                            {/**
                            <button
                                type="button"
                                style={{
                                    background: '#2f3a4c',
                                    color: 'white',
                                    maxWidth: 20,
                                    maxHeight: 20,
                                    borderRadius: 20,
                                    border: 'none',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    marginLeft: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    outline: 'none',
                                }}
                                onClick={() => {
                                    window.open('http://mslk.co.kr/', '_blank');
                                }}
                            >
                                ?
                            </button>
                             */}
                        </div>
                        <div
                             style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 height: '35px',
                             }}
                        >
                            <Checkbox
                                id="checkbox_newsletter"
                                color="default"
                                checked={checkbox3}
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                                    
                                onChange={event => {
                                    setCheckbox3(event.target.checked);
                                    const labChk3 = document.getElementById("lab_checkbox3");
                                    if(event.target.checked) {
                                        labChk3.style.color = "#531580";
                                    } else {
                                        labChk3.style.color = "#999";
                                    }
                                    
                                    const labChk1 = document.getElementById("lab_checkbox1");
                                    if(checkbox2 && event.target.checked) {
                                        setCheckbox1(true);
                                        labChk1.style.color = "#531580";
                                    } else {
                                        setCheckbox1(false);
                                        labChk1.style.color = "#999";
                                    }
                                }}
                            />
                            <label htmlFor="checkbox_newsletter" id="lab_checkbox3"
                                style={{
                                    fontWeight: "bold",
                                    //fontSize : "16px",
                                    color : "#999",
                            }}>
                                마케팅 정보 수신에 동의합니다.(선택)
                            </label>
                            {/**
                            <button
                                type="button"
                                style={{
                                    background: '#2f3a4c',
                                    color: 'white',
                                    maxWidth: 20,
                                    maxHeight: 20,
                                    borderRadius: 20,
                                    border: 'none',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    marginLeft: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    outline: 'none',
                                }}
                                onClick={() => {
                                    window.open('http://mslk.co.kr/', '_blank');
                                }}
                            >
                                ?
                            </button>
                             */}
                        </div>
                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={bLoading}
                    >
                        회원가입
                    </Button>
                </ValidatorForm>
                <Divider
                    variant="middle"
                    style={{
                        width: '100%',
                        margin: 0,
                        marginTop: 30,
                        marginBottom: 20,
                    }}
                />
                {/* <Typography variant="h6" gutterBottom>
                    SNS sign up
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'space-between',
                    }}
                >
                    <Button variant="outlined" color="inherit" className={classes.buttonSns} onClick={() => {
                        if (checkbox1 && checkbox2) {
                            setLoading(true);
                            window.processOAuth = (sToken) => {
                                if (sToken.length > 0) {
                                    signupsns('naver', sToken);
                                }
                            };
                            const sNaverCallbackUrl = `http://${sDomainName}/signup`;
                            const naver_id_login = new window.naver_id_login(sNaverClientId, sNaverCallbackUrl);
                            const state = naver_id_login.getUniqState();
                            const sNaverAuthUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=token&client_id=${sNaverClientId}&redirect_uri=${encodeURIComponent(sNaverCallbackUrl)}&state=${state}`;
                            window.open(
                                sNaverAuthUrl,
                                'Naver authentication',
                                "width=600px,height=700px,top=100px,left=400px"
                            );
                        } else {
                            toast_warning("You have to accept Terms and Conditions");
                        }
                    }}>
                        NAVER
                    </Button>
                    <Button variant="outlined" color="inherit" className={classes.buttonSns} onClick={() => {
                        if (checkbox1 && checkbox2) {
                            setLoading(true);
                            Kakao.Auth.login({
                                success: response => {
                                    signupsns('kakao', response.access_token);
                                },
                                fail: err => {
                                    setLoading(false);
                                    toast_warning(JSON.stringify(err));
                                }
                              });
                        } else {
                            toast_warning("You have to accept Terms and Conditions");
                        }
                    }}>
                        KAKAO
                    </Button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <FacebookLogin
                        appId={sFacebookAppId}
                        // autoLoad
                        callback={responseFacebook}
                        render={renderProps => (
                            <Button
                                variant="outlined"
                                color="inherit"
                                className={classes.buttonSns}
                                onClick={() => {
                                    if (checkbox1 && checkbox2) {
                                        renderProps.onClick();
                                    } else {
                                        toast_warning("You have to accept Terms and Conditions");
                                    }
                                }}
                            >
                                Facebook
                            </Button>
                        )}
                    />
                    <GoogleLogin
                        clientId={sGoogleClientId}
                        // hostedDomain="localhost"
                        render={renderProps => (
                            <Button
                                variant="outlined"
                                color="inherit"
                                className={classes.buttonSns}
                                onClick={() => {
                                    if (checkbox1 && checkbox2) {
                                        renderProps.onClick();
                                    } else {
                                        toast_warning("You have to accept Terms and Conditions");
                                    }
                                }}
                                disabled={renderProps.disabled}
                            >
                                Google
                            </Button>
                        )}
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />
                </div>
                <Divider
                    variant="middle"
                    style={{
                        width: '100%',
                        margin: 0,
                        marginTop: 30,
                        marginBottom: 20,
                    }}
                /> */}
                <div
                    style={{
                        boxSizing: "border-box",
                        //display: 'flex',
                        //flexDirection: 'row',
                        //justifyContent: 'space-between',
                        //alignItems: 'space-between',
                    }}
                >
                    <Button
                        color="inherit"
                        className={classes.buttonNaver}
                        onClick={() => {
                            window.processOAuth = sToken => {
                                if (sToken.length > 0) {
                                    loginSns("naver", sToken);
                                }
                            };
                            const sNaverCallbackUrl = `http://${sDomainName}/signup`;
                            const naver_id_login = new window.naver_id_login(
                                sNaverClientId,
                                sNaverCallbackUrl
                            );
                            const state = naver_id_login.getUniqState();
                            const sNaverAuthUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=token&client_id=${sNaverClientId}&redirect_uri=${encodeURIComponent(
                                sNaverCallbackUrl
                            )}&state=${state}`;
                            window.open(
                                sNaverAuthUrl,
                                "Naver authentication",
                                "width=600px,height=700px,top=100px,left=400px"
                            );
                        }}
                    >
                        {/* 
                        <span 
                            style={{
                                verticalAlign: 'middle',
                                paddingRight: '10px',
                                position: 'relative',
                                top: '2px',
                            }}
                            content={{
                                backgroundImage: "url(images/naver.png)",
                            }}

                        >
                        </span>
                        */}
                        <img src='images/naver.png'
                            style={{
                                verticalAlign: 'middle',
                                paddingRight: '10px',
                                position: 'relative',
                            }}
                        >
                        </img>
                        <span 
                            style={{
                                fontSize:'15px',
                                verticalAlign: 'middle',
                                fontWeight: '600',
                            }}
                        >
                            네이버 로그인
                        </span>
                    </Button>                                  
                    <KakaoLogin
                        jsKey='2e4a9a1e1a0871abf2a48c472200e97c'
                        onSuccess={result => responseKakao(result)}
                        onFailure={result => responseKakao(result)}                            
                        render={renderProps => (
                            <Button
                                color="inherit"
                                className={classes.buttonKakao}
                                onClick={renderProps.onClick}
                            >
                            <img src='images/kakao.png'
                                style={{
                                    verticalAlign: 'middle',
                                    paddingRight: '10px',
                                    position: 'relative',
                                }}
                            >
                            </img>
                            <span 
                                style={{
                                    fontSize:'15px',
                                    verticalAlign: 'middle',
                                    fontWeight: '600',
                                }}
                            >
                                카카오계정 로그인
                            </span>
                            </Button>
                        )}
                        getProfile={true}
                    />
                    <FacebookLogin
                        appId={sFacebookAppId}
                        autoLoad={false}
                        callback={responseFacebook}
                        render={renderProps => (
                            <Button
                                color="inherit"
                                className={classes.buttonFacebook}
                                onClick={renderProps.onClick}
                            >
                                <img src='images/facebook.png'
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                        position: 'relative',
                                    }}
                                >
                                </img>
                                <span 
                                    style={{
                                        fontSize:'15px',
                                        verticalAlign: 'middle',
                                        fontWeight: '600',
                                    }}
                                >
                                    페이스북 로그인
                                </span>
                            </Button>
                        )}
                    />
                    <GoogleLogin
                        clientId={sGoogleClientId}
                        // hostedDomain="localhost"
                        render={renderProps => (
                            <Button
                                color="inherit"
                                className={classes.buttonGoogle}
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                <img src='images/google.png'
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                        position: 'relative',
                                    }}
                                >
                                </img>
                                <span 
                                    style={{
                                        fontSize:'15px',
                                        verticalAlign: 'middle',
                                        fontWeight: '600',
                                    }}
                                >
                                    구글계정 로그인
                                </span>
                            </Button>
                        )}
                        buttonText="구글"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 30,
                        //marginBottom: 100,
                    }}
                >
                    <div 
                        style={{
                            float: "left",
                        }}
                    >
                        <Link href="/login" underline="always"
                            style={{
                                fontSize: "16px",
                                //color: "#555",
                                fontWeight: "400",
                            }}
                        >
                            이미 계정이 있으신가요?
                        </Link>
                    </div>
                    <div 
                        style={{
                            float: "right"
                        }}
                    >
                        <Link
                            href="/login"
                            style={{
                                fontSize: "16px",
                                color: "#555",
                                fontWeight: "400",
                            }}
                        >
                            로그인하기
                        </Link>
                    </div>
                </div>

                
            </div>
            <Divider
                variant="middle"
                style={{
                    width: '100%',
                    margin: 0,
                    marginTop: 20,
                    marginBottom: 20,
                }}
            />
            <Typography variant="body2" style={{ fontWeight: 500, color: '#531580', fontSize:'12px'}}>
                Copyright © 2020 OnlineSign All Rights Reserved.
            </Typography>
        </div>
    );
}

export default Entity;