import React, { Fragment } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const Dialog = withStyles(theme => ({
    paper: {
        width: '100%',
        maxWidth: 500,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 4,
        //     marginRight: 4,
        // },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    button_sign_request: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    button_cancel: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            // color: 'white',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    dialogAction: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 140,
        },
    },
}));

function Entity(props) {

    const classes = useStyles();
    
    return (
        <Fragment>
            <Dialog
                // onClose={props.onClose}
                aria-labelledby="dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogContent dividers>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 120,
                            }}
                        >
                            <h3>서명 요청 완료</h3>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 120,
                            }}
                        >
                            <p>서명요청이 완료되었습니다.</p>
                        </div>
                    </div>
                    <div className={classes.dialogAction}>
                        {props.bLoading && (
                            <div
                                style={{
                                    // width: '100%',
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}
                            >
                                <CircularProgress disableShrink />
                            </div>
                        )}
                        {!props.bLoading && (
                            <Fragment>
                                {/*
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.button_sign_request}
                                    onClick={props.onClickSign}
                                >
                                    서명하기
                                </Button>
                                */}
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.button_cancel}
                                    onClick={() => {
                                        props.onClickCancel();
                                    }}
                                >
                                    확인
                                </Button>
                            </Fragment>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default Entity;