import React, { setGlobal, useEffect, useGlobal, useState, useCallback, useRef } from 'reactn';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
// import IconDelete from '@material-ui/icons/Delete';
// import IconCheck from '@material-ui/icons/Check';
// import IconClose from '@material-ui/icons/Close';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import IconEdit from '@material-ui/icons/Edit';
// import IconSend from '@material-ui/icons/Send';
import axios from "axios";
// import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingOverlay from 'react-loading-overlay';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Pagination from '../component/Pagination';
import FileUploader from '../component/FileUploader';
import DialogSignRequest from '../component/DialogSignRequest';
import DialogRequestComplete from '../component/DialogRequestComplete';
import DialogCancelSignRequest from '../component/DialogCancelSignRequest';
import DialogResendNotification from '../component/DialogResendNotification';
import DialogChangeExpireDays from '../component/DialogChangeExpireDays';
import DialogSignProgressInformation from '../component/DialogSignProgressInformation';
import DialogAgainSignRequest from '../component/DialogAgainSignRequest';




import { toast_success, toast_warning } from '../api/api';
import { navigatePage, useOnClickOutside, formatDate } from '../lib/lib';



function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
    { id: 'status', numeric: false, disablePadding: false, label: '상태', width: 150 },
    { id: 'doc_name', numeric: false, disablePadding: false, label: '문서 이름' , width: 250},
    { id: 'participant', numeric: false, disablePadding: false, label: '서명 참여자' , width: 100},
    // { id: 'folder', numeric: false, disablePadding: false, label: '폴더' },
    { id: 'update_time', numeric: true, disablePadding: false, label: '서명요청 시간' , width: 100},
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'scroll',
        [theme.breakpoints.up('sm')]: {
            overflowX: 'unset',
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableToolbar: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    button_create_template: {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
    },
    searchPaper: {
        border: '1px solid #ccc',
        boxShadow: 'none',
        display: 'flex',
        transition: 'all .4s',
        width: '70%',
        '&:hover, &:focus-within': {
            width: '100%',
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 7,
        color: theme.palette.secondary.main,
    },
    iconButtonDel: {
        padding: 7,
        color: '#999',
    },
    tableRowTemplateName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: 300,
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 440,
        },
    },
}));

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount && rowCount > 0}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        //align={headCell.numeric ? 'right' : 'left'}
                        align='center'
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        //style={headCell.numeric ? { width: headCell.width, whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}
                        style={{ width: headCell.width, whiteSpace: 'nowrap' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                fontSize: '1.4em',
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const StyledButton = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        width: 190,
        height: 50,
        border: 'none',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '1.2em',
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}))(Button);



const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
        outline: 'none',
      },
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        outline: 'none',
        height: 38,
        width: '100%',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        // padding: '10px 26px 10px 12px',
        padding: 0,
        paddingLeft: 10,
        // paddingRight: 10,
        lineHeight: 1,
        // color: '',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        // fontFamily: [
        //     '-apple-system',
        //     'BlinkMacSystemFont',
        //     '"Segoe UI"',
        //     'Roboto',
        //     '"Helvetica Neue"',
        //     'Arial',
        //     'sans-serif',
        //     '"Apple Color Emoji"',
        //     '"Segoe UI Emoji"',
        //     '"Segoe UI Symbol"',
        // ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);







function Entity(props) {

    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    const [sDomainName] = useGlobal('sDomainName');
    const [sViewerDomainName] = useGlobal('sViewerDomainName');

    const [sSearchQuery, setSearchQuery] = useState('');
    const [sQueryTypeTmp, setQueryTypeTmp] = useState('01');
    const [sSearchStateQuery, setSearchStateQuery] = useState('00');
    const [sQuery, setQuery] = useState('');
    const [sQueryType, setQueryType] = useState(null);
    const [rows, setRows] = useState([]);

    const [bLoading, setLoading] = React.useState(false);


    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('update_time');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPopperEdit, setOpenPopperEdit] = React.useState(false);
    const [docToEdit, setDocToEdit] = React.useState({ id: -1, doc_name: '', pKey: '', cKey: '' });

    const [bFileUploaderOpen, setFileUploaderOpen] = React.useState(false);
    const [bDialogSignRequestOpen, setDialogSignRequestOpen] = React.useState(false);
    const [bDialogRequestCompleteOpen, setDialogRequestCompleteOpen] = useGlobal('bDialogRequestCompleteOpen');
    const [bDialogCancelSignRequestOpen, setDialogCancelSignRequestOpen] = React.useState(false);
    const [bDialogResendNotification, setDialogResendNotification] = React.useState(false);
    const [bDialogChangeExpireDays, setDialogChangeExpireDays] = React.useState(false);
    const [bDialogSignProgressInformation, setDialogSignProgressInformation] = React.useState(false);
    const [bDialogAgainSignRequest, setDialogAgainSignRequest] = React.useState(false);
    

    const [iEditingDocId] = useGlobal('iEditingDocId');

    const [sReferrerDocTempEdit, setReferrerDocTempEdit] = useGlobal('sReferrerDocTempEdit');

    const ref = useRef();

    const [iActiveDocuments, setActiveDocuments] = useGlobal('iActiveDocuments');

    const [sResendType, setResendType] = React.useState('');

    const [aTargetList, setTargetList] = useState([]);
    const [sFileName, setFileName] = useState('');
    //const [iFileLen, setFileLen] = useState(0);
    //const [sTimerId,setTimerId] = useState('');
    

    useOnClickOutside(ref, () => setOpenPopperEdit(false));

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    function handleChangePage(newPage) {
        setPage(newPage);
    }

    const callbackFileUpload = res => {
        setLoading(false);
        switch (res.data.status) {
            case '000':
                console.log(res.data.entity);
                toast_success('문서가 업로드되었습니다.');
                setGlobal({
                    iEditingDocId: (res.data.entity !== undefined && res.data.entity !== null) ? res.data.entity.templateIdx : -1,
                    sEditingDocTemplateName: (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.templateName !== null) ? res.data.entity.templateName : '',
                    sEditingDocTemplateApiKey: (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.apiKey !== null) ? res.data.entity.apiKey : '',
                    sReferrerDocTempEdit: 'signParticipant',
                    // sReferrerDocTempEdit: 'fileuploader',
                });

                setDialogSignRequestOpen(true);
                break;
            default:
                toast_warning(res.data.message);
        }
    };

    const callbackFileUploadCatch = err => {
        setLoading(false);
        toast_warning(`시스템 장애가 발생하였습니다.`);
    }

    const isSelected = id => selected.indexOf(id) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const fetchData = useCallback(() => {
        let sUrl = `/${sApiUrl}/docSignRequest`;
        if ( (sQuery !== null && sQuery !== undefined && sQuery !== '' && sQueryType !== undefined && sQueryType !== null) || (sSearchStateQuery !== '00') ) {
            sUrl = `/${sApiUrl}/docSignRequest/search?type=${sQueryType}&content=${sQuery}&state=${sSearchStateQuery}`;
        }
        
        console.log(sUrl);
        setLoading(true);
        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).get(sUrl)
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    if (res.data.entity !== undefined && res.data.entity !== null) {
                        setRows(res.data.entity.map(item => {
                            let sParticipant = '';
                            let sStatus = '';
                            let sSignTemplateName = '';
                            if (item.docSignTargetList.length > 0) {
                                item.docSignTargetList.forEach(element => {                                   
                                    if (sParticipant.length > 0) {
                                        sParticipant += '/';
                                    }
                                    sParticipant += element.targetName;
                                    sSignTemplateName = element.targetName;
                                });
                            } else {
                                sStatus = 'No participant';
                            }

                            if(item.state == "01") {
                                sStatus = '서명 대기중';
                            } else if(item.state == "02") {
                                sStatus = '서명 진행중';
                            } else if(item.state == "03") {
                                sStatus = '서명 완료';
                            } else if(item.state == "04") {
                                sStatus = '서명 거부';
                            } else if(item.state == "99") {
                                sStatus = '서명 취소';
                            }

                            let sUpdatedDate = '';
                            if (item.sendDate !== null) {
                                sUpdatedDate = formatDate(item.sendDate);
                                // const oUpdDate = new Date(item.updDate);
                                // sUpdatedDate = oUpdDate.toDateString();
                            }
                            // if (sUpdatedDate.length === 0) {
                            //     if (item.regDate !== null) {
                            //         sUpdatedDate = formatDate(item.regDate);
                            //         // const oCreatedDate = new Date(item.regDate);
                            //         // sUpdatedDate = oCreatedDate.toDateString();
                            //     }
                            // }
                            return {
                                id: item.docSignRequestIdx,
                                statusCode: item.state,
                                status: sStatus,
                                doc_name: item.templateName == null ? 'empty' : item.templateName,
                                doc_name_empty: item.templateName === null,
                                participant: sParticipant,
                                folder: '/directory/',
                                // created_date: item.regDate,
                                update_time: sUpdatedDate,
                                expire_day: item.signExpired,
                                pKey: item.pKey,
                                cKey: item.cKey,
                                sendUrl: item.sendUrl,
                                docSignTargetList: item.docSignTargetList,
                            };
                        }));
                        setActiveDocuments(res.data.entity.filter(item => {
                            let bRet = false;
                            if (item.docSignTargetList.length > 0) {
                                item.docSignTargetList.forEach(element => {
                                    if(element.state != "99") {
                                        bRet = true;
                                    }
                                    /*
                                    if (element.signYn !== undefined && element.signYn === 'Y') {
                                    } else {
                                        bRet = true;
                                    }
                                    */
                                });
                            } else {
                                bRet = true;
                            }
                            return bRet;
                        }).length);
                    } else {
                        setRows([]);
                        setActiveDocuments(0);
                    }
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
    }, [sApiUrl, sToken, sQuery, sQueryType,sSearchStateQuery]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData();
        setPage(0);
    }, [fetchData]);


    const cancelSignRequest = () => {
        setLoading(true);
        let sUrl = `/${sApiUrl}/docSignRequest/cancel/${docToEdit.id}`;

        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).post(sUrl)
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    toast_success(res.data.message);
                    fetchData();
                    setPage(0);
                    // setRows(res.data.entity.map(item => {
                    //     let sStatus = 'Completed';
                    //     let sParticipant = '';
                    //     if (item.docSignTargetList.length > 0) {
                    //         item.docSignTargetList.forEach(element => {
                    //             if (element.signYn !== undefined && element.signYn === 'Y') {
                    //             } else {
                    //                 sStatus = 'Waiting participant to sign';
                    //             }
                    //             if (sParticipant.length > 0) {
                    //                 sParticipant += '/';
                    //             }
                    //             sParticipant += element.targetName;
                    //         });
                    //     } else {
                    //         sStatus = 'No participant';
                    //     }
                    //     let sUpdatedDate = '';
                    //     if (item.updDate !== null) {
                    //         const oUpdDate = new Date(item.updDate);
                    //         sUpdatedDate = oUpdDate.toDateString();
                    //     }
                    //     if (sUpdatedDate.length === 0) {
                    //         if (item.regDate !== null) {
                    //             const oCreatedDate = new Date(item.regDate);
                    //             sUpdatedDate = oCreatedDate.toDateString();
                    //         }
                    //     }
                    //     return {
                    //         id: item.docSignRequestIdx,
                    //         status: sStatus,
                    //         doc_name: item.templiteName == null ? 'empty' : item.templiteName,
                    //         doc_name_empty: item.templiteName === null,
                    //         participant: sParticipant,
                    //         folder: '/directory/',
                    //         // created_date: item.regDate,
                    //         update_time: sUpdatedDate,
                    //     };
                    // }));
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
    };

    const resendNotification = (sResendType, sDocSignTargetIdx) => {
        
        setLoading(true);
        
        let sUrl = `/${sApiUrl}/docSignRequest/resendSignNotif/${docToEdit.id}`;

        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).post(sUrl,{resendType : sResendType, docSignTargetIdx : sDocSignTargetIdx})
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    toast_success(res.data.message);
                    // setRows(res.data.entity.map(item => {
                    //     let sStatus = 'Completed';
                    //     let sParticipant = '';
                    //     if (item.docSignTargetList.length > 0) {
                    //         item.docSignTargetList.forEach(element => {
                    //             if (element.signYn !== undefined && element.signYn === 'Y') {
                    //             } else {
                    //                 sStatus = 'Waiting participant to sign';
                    //             }
                    //             if (sParticipant.length > 0) {
                    //                 sParticipant += '/';
                    //             }
                    //             sParticipant += element.targetName;
                    //         });
                    //     } else {
                    //         sStatus = 'No participant';
                    //     }
                    //     let sUpdatedDate = '';
                    //     if (item.updDate !== null) {
                    //         const oUpdDate = new Date(item.updDate);
                    //         sUpdatedDate = oUpdDate.toDateString();
                    //     }
                    //     if (sUpdatedDate.length === 0) {
                    //         if (item.regDate !== null) {
                    //             const oCreatedDate = new Date(item.regDate);
                    //             sUpdatedDate = oCreatedDate.toDateString();
                    //         }
                    //     }
                    //     return {
                    //         id: item.docSignRequestIdx,
                    //         status: sStatus,
                    //         doc_name: item.templiteName == null ? 'empty' : item.templiteName,
                    //         doc_name_empty: item.templiteName === null,
                    //         participant: sParticipant,
                    //         folder: '/directory/',
                    //         // created_date: item.regDate,
                    //         update_time: sUpdatedDate,
                    //     };
                    // }));
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
    };

    const againSignRequest = () => {
        console.log(docToEdit);
        setLoading(true);
        let sUrl = `/${sApiUrl}/docSignRequest/againSignRequest/${docToEdit.id}`;

        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).post(sUrl, docToEdit)
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    toast_success(res.data.message);
                    fetchData();
                    setPage(0);
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
    };

    const changeExpireDays = iDays => {
        setLoading(true);
        let sUrl = `/${sApiUrl}/docSignRequest/changeSignExpired`;

        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).post(sUrl, {
            docSignRequestIdx: docToEdit.id,
            signExpired: iDays,
        })
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    toast_success(res.data.message);
                    fetchData();
                    setPage(0);
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
    };
    
    const docDownload = () => {
        /*
        if(iFileLen > 0) {
                let fName = aFileName[iFileLen-1];
                console.log(fName);
                const link = document.createElement('a');
                //link.href = `http://${sDomainName}/${sApiUrl}/docSignRequest/saveDocPDF/pdf?fileName=${res.data.entity.fileName}`;
                link.href = `http://localhost:7070/${sApiUrl}/docSignRequest/saveDocPDF/pdf?fileName=${fName}`;
                link.type = "application/octet-stream";
                link.setAttribute('download', "test.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setFileLen(iFileLen - 1);
        }
        */
        if(sFileName != null && sFileName != '') {
            const link = document.createElement('a');
            //운영
            //link.href = `https://${sDomainName}/${sApiUrl}/docSignRequest/saveDocPDF/pdf?fileName=${sFileName}`;
            //로컬
            link.href = `${sViewerDomainName}/${sApiUrl}/docSignRequest/saveDocPDF/pdf?fileName=${sFileName}`;
            link.type = "application/octet-stream";
            link.download = "test.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    /*
    useEffect(() => {
        setTimeout(docDownload(), 1000);
    }, [iFileLen,aFileName]);
    */

   useEffect(() => {
    setTimeout(docDownload(), 100);
}, [sFileName]);

    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <div className={classes.tableWrapper}>
                <div className={classes.tableToolbar}>
                    <div style={{ display: 'inline-block', marginRight: 10 }}>
                        {/**
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button_create_template}
                            style={{marginRight: '10px', }}
                            onClick={() => {
                                setGlobal({
                                    aSignRequestParticipant: [],
                                });
                                setFileUploaderOpen(true);
                            }}
                        >
                            문서 업로드 하기
                        </Button>   
                         */}
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button_create_template}
                            style={{marginRight: '10px', }}
                            onClick={() => {
                                if(selected.length == 0) {
                                    toast_warning("다운로드 받을 문서를 선택해주세요.");
                                    return;
                                }

                                let bCheck = false;
                                rows.map(item => { 
                                    if(selected.indexOf(item.id) > -1) {
                                        if(item.statusCode != '03') {
                                            bCheck = true;
                                        }
                                    }
                                });

                                if(bCheck) {
                                    toast_warning("서명 완료된 문서만 다운로드 가능합니다.");
                                    return;
                                }
                                setFileName('');
                                setLoading(true);
                               
                                axios.create({
                                    headers: {
                                        Authorization: `Bearer ${sToken}`,
                                    }
                                }).post(`/${sApiUrl}/docSignRequest/saveDocPDFMulti`,{
                                    signRequestList : selected.toString()
                                }).then(res => {
                                    console.log(res);
                                    
                                    if(res.data.entity != null) {
                                        /*
                                        setFileName(res.data.entity.map(item => {
                                            return item.fileName;
                                        }));

                                        console.log(aFileName);
                                        setFileLen(aFileName.length);
                                        */
                                        res.data.entity.map(item => {      
                                            console.log(item.fileName);                                      
                                            setFileName(item.fileName);
                                            console.log(sFileName);
                                        });
                                        
                                    }
                                    
                                    
                                }).catch(err => {
                                    setLoading(false);
                                    //toast_warning(err.response.statusText);
                                });
                                
                                setLoading(false);
                            }}
                        >
                            다운로드
                        </Button> 
                            
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button_create_template}
                            onClick={() => {
                                
                                if(selected.length == 0) {
                                    alert("삭제 할 문서를 선택해주세요.");
                                    return;
                                }
                                
                                setLoading(true);

                                axios.create({
                                    headers: {
                                        Authorization: `Bearer ${sToken}`,
                                    }
                                }).post(`/${sApiUrl}/docSignRequest/deleteDocument`, {
                                    signRequestList : selected.toString()
                                }).then(res => {
                                    setLoading(false);
                                    toast_warning("삭제되었습니다.");
                                    fetchData();
                                }).catch(err => {
                                    setLoading(false);
                                    toast_warning(err.response.statusText);
                                });
                            }}
                        >
                            삭제
                        </Button>                       
                    </div>
               
                    <div
                        style={{
                            display: 'inline-block',
                            width: 170,
                            marginRight: 6,
                            outline: 'none',
                        }}
                    >
                        <FormControl
                            style={{
                                height: 40,
                                width: '100%',
                                outline: 'none',
                            }}
                        >
                            <Select
                                value={sSearchStateQuery}
                                onChange={e => setSearchStateQuery(e.target.value)}
                                input={<BootstrapInput name="age" id="age-customized-select" />}
                                style={{
                                    outline: 'none',
                                }}
                            >
                                <MenuItem value={'00'}>진행상태</MenuItem>
                                <MenuItem value={'01'}>서명 대기중</MenuItem>
                                <MenuItem value={'02'}>서명 진행중</MenuItem>
                                <MenuItem value={'03'}>서명 완료</MenuItem>
                                <MenuItem value={'04'}>서명 거부</MenuItem>
                                <MenuItem value={'99'}>서명 취소</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div
                        style={{
                            display: 'inline-block',
                            width: 170,
                            marginRight: 6,
                            outline: 'none',
                        }}
                    >
                        <FormControl
                            style={{
                                height: 40,
                                width: '100%',
                                outline: 'none',
                            }}
                        >
                            <Select
                                value={sQueryTypeTmp}
                                onChange={e => setQueryTypeTmp(e.target.value)}
                                input={<BootstrapInput name="age" id="age-customized-select" />}
                                style={{
                                    outline: 'none',
                                }}
                            >
                                <MenuItem value={'01'}>문서 이름</MenuItem>
                                <MenuItem value={'02'}>서명 참여자</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ display: 'inline-block', marginRight: '10px' }}>
                        <Paper className={classes.searchPaper}>
                            <form
                                onSubmit={event => {
                                    event.preventDefault();
                                    setQuery(sSearchQuery);
                                    setQueryType(sQueryTypeTmp);
                                }}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <InputBase
                                    className={classes.input}
                                    placeholder="검색어를 입력해 주세요."
                                    inputProps={{ 'aria-label': '검색어를 입력해 주세요.' }}
                                    value={sSearchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                />
                                <IconButton className={classes.iconButton} aria-label="search" type="submit">
                                    <SearchIcon />
                                </IconButton>
                            </form>
                        </Paper>
                    </div>
                        
                </div>
                <LoadingOverlay
                    active={bLoading}
                    spinner
                    text='Loading...'
                    styles={{
                        overlay: {
                            position: 'fixed',
                            height: '100vh',
                            width: '100%',
                            top: 0,
                            left: 0,
                            display: 'flex',
                            textAlign: 'center',
                            fontSize: '1.2em',
                            color: '#FFF',
                            background: 'rgba(0,0,0,0.7)',
                            zIndex: 800,
                            transition: 'opacity 500ms ease-in',
                            opacity: 1,
                        },
                        // wrapper: {},
                        // content: {},
                        // spinner: {}
                    }}
                >
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    onChange={event => handleClick(event, row.id)}
                                                />
                                            </TableCell>
                                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>{row.status}</TableCell>
                                            <TableCell component="th" id={labelId} scope="row" align="center" padding="none" style={{ whiteSpace: 'nowrap' }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div
                                                        className={classes.tableRowTemplateName}
                                                        onClick={e => {
                                                            setAnchorEl(e.currentTarget);                                                            
                                                            setDocToEdit(row);
                                                            if (anchorEl === e.currentTarget) {
                                                                setOpenPopperEdit(!openPopperEdit);
                                                            } else {
                                                                setOpenPopperEdit(true);
                                                            }
                                                        }}
                                                        style={{
                                                            color: row.doc_name_empty ? '#ddd' : 'inherit'
                                                        }}
                                                    >
                                                        {row.doc_name_empty ? 'empty' : row.doc_name}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>{row.participant}</TableCell>
                                            {/* <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>{row.folder}</TableCell> */}
                                            <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>{row.update_time}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && rows.length > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <Popper open={openPopperEdit} anchorEl={anchorEl} placement="bottom-start" transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper
                                    ref={ref}
                                    style={{
                                        width: 370,
                                        // height: 150,
                                        padding: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <div>
                                        <Typography className={classes.typography} style={{ fontWeight: 'bold', textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {docToEdit.doc_name}
                                        </Typography>
                                    </div>
                                    <List>
                                        {(docToEdit.statusCode == "03")  && (
                                        <ListItem
                                            button
                                            onClick={() => {
                                                if(docToEdit.statusCode != '03') {
                                                    toast_warning("서명이 완료되지 않았습니다.");
                                                    return;
                                                }
                                                setReferrerDocTempEdit('my_document');
                                                setGlobal({
                                                    sEditingDocTemplateApiKey: docToEdit.pKey,
                                                    sEditingDocTemplateApiPKey: docToEdit.pKey,
                                                    sEditingDocTemplateApiCKey: docToEdit.cKey,
                                                    sSendUrl: docToEdit.sendUrl,
                                                });
                                                navigatePage('/document_template/document_template_view', props);
                                            }}
                                        >
                                            <ListItemText className={classes.listItemText} primary={'서명완료 문서 확인'} />
                                        </ListItem>
                                        )}
                                        {(docToEdit.statusCode == "01" || docToEdit.statusCode == "02")  && (
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setDialogCancelSignRequestOpen(true);
                                                setOpenPopperEdit(false);
                                            }}
                                        >
                                            <ListItemText className={classes.listItemText} primary={'서명요청 취소'} />
                                        </ListItem>
                                        )}
                                        {(docToEdit.statusCode == "01" || docToEdit.statusCode == "02") && (
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setDialogResendNotification(true);
                                                setOpenPopperEdit(false);
                                                setTargetList(docToEdit.docSignTargetList);
                                            }}
                                        >
                                            {/* <ListItemIcon className={classes.listItemIcon}><IconSend /></ListItemIcon> */}
                                            {/*<ListItemText className={classes.listItemText} primary={`서명요청 이메일 발송(서명참여자:${docToEdit.participant})`} />*/}
                                            <ListItemText className={classes.listItemText} primary={`다시 서명요청하기`} />
                                        </ListItem>
                                        )}
                                        {(docToEdit.statusCode == "01" || docToEdit.statusCode == "02") && (
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setDialogAgainSignRequest(true);
                                                setOpenPopperEdit(false);
                                            }}
                                        >
                                            {/* <ListItemIcon className={classes.listItemIcon}><IconSend /></ListItemIcon> */}
                                            {/*<ListItemText className={classes.listItemText} primary={`서명요청 새로하기(서명참여자:${docToEdit.participant})`} />*/}
                                            <ListItemText className={classes.listItemText} primary={`서명요청 새로하기`} />
                                        </ListItem>
                                        )}
                                        {(docToEdit.statusCode == "01" || docToEdit.statusCode == "02") && (
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setDialogChangeExpireDays(true);
                                                setOpenPopperEdit(false);
                                            }}
                                        >
                                            {/* <ListItemIcon className={classes.listItemIcon}><IconSend /></ListItemIcon> */}
                                            <ListItemText className={classes.listItemText} primary={'서명만료일 수정'} />
                                        </ListItem>
                                        )}
                                        {(docToEdit.statusCode == "03")  && (
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setDialogSignProgressInformation(true);
                                                setOpenPopperEdit(false);
                                            }}
                                        >
                                            {/* <ListItemIcon className={classes.listItemIcon}><IconSend /></ListItemIcon> */}
                                            <ListItemText className={classes.listItemText} primary={'서명내역 확인'} />
                                        </ListItem>
                                        )}
                                        {/* <ListItem
                                            button
                                            onClick={() => {
                                            }}
                                        >
                                            <ListItemIcon className={classes.listItemIcon}><IconSend /></ListItemIcon>
                                            <ListItemText className={classes.listItemText} primary={'Send request'} />
                                        </ListItem> */}
                                        {/* <ListItem
                                            button
                                            onClick={() => {
                                                setGlobal({
                                                    iEditingDocId: docToEdit.id,
                                                    sEditingDocTemplateName: docToEdit.doc_name,
                                                    sReferrerDocTempEdit: 'documenttemplatelist',
                                                });
                                                navigatePage('/document_template/document_template_creation', props);
                                            }}
                                        >
                                            <ListItemIcon className={classes.listItemIcon}><IconEdit /></ListItemIcon>
                                            <ListItemText className={classes.listItemText} primary={'Edit'} />
                                        </ListItem> */}
                                    </List>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </LoadingOverlay>
            </div>
            {rows.length === 0 && (
                <div
                    style={{
                        width: '100%',
                        height: 350,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            padding: 0,
                        }}
                    >
                        생성된 템플릿 문서가 없습니다.
                        </div>
                    <div
                        style={{
                            padding: 20,
                        }}
                    >
                        새로운 템플릿을 업로드하여 쉽게 사용하실 수 있습니다.
                        </div>
                    <div
                        style={{
                            padding: 20,
                        }}
                    >
                        <StyledButton
                            onClick={() => {
                                setGlobal({
                                    aSignRequestParticipant: [],
                                });
                                setFileUploaderOpen(true);
                            }}
                        >
                            문서 업로드 하기
                        </StyledButton>
                    </div>
                </div>
            )}
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
            <Pagination
                page={page}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                style={{
                    marginBottom: 50,
                }}
            />
            <DialogSignRequest
                open={bDialogSignRequestOpen}
                bLoading={bLoading}
                onClose={() => {
                    // setDialogSignRequestOpen(false);
                }}
                onClickCancel={() => {
                    setDialogSignRequestOpen(false);
                    setFileUploaderOpen(false);
                }}
                onClickCreate={() => {
                  
                    navigatePage('/my_document/sign_participant', props);
                    /*
                    setLoading(true);
                    axios.create({
                        headers: {
                            Authorization: `Bearer ${sToken}`,
                        }
                    }).post(`/${sApiUrl}/docSignRequest`, { templateIdx: iEditingDocId })
                        .then(res => {
                            setGlobal({
                                iEditingSignReqId: (res.data.entity !== undefined && res.data.entity !== null) ? res.data.entity.docSignRequestIdx : -1,
                                sEditingSignReqName: (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.templateName !== null) ? res.data.entity.templateName : '',
                                sEditingSignReqExpireDay: (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.signExpired !== null) ? res.data.entity.signExpired.toString() : '',
                            });
                            setLoading(false);
                            setDialogSignRequestOpen(false);
                            setFileUploaderOpen(false);
                            navigatePage('/my_document/sign_participant', props);
                        })
                        .catch(err => {
                            console.log(JSON.stringify(err));
                            setLoading(false);
                            setDialogSignRequestOpen(false);
                            setFileUploaderOpen(false);
                        });
                    */
                }}
            />
            <DialogRequestComplete
                open={bDialogRequestCompleteOpen}
                bLoading={bLoading}
                onClose={() => {
                    // bDialogRequestCompleteOpen(false);
                }}
                onClickCancel={() => {
                    setDialogRequestCompleteOpen(false);
                }}
                onClickSign={() => {
                    setDialogRequestCompleteOpen(false);
                    setReferrerDocTempEdit('my_document');
                    navigatePage('/document_template/document_template_view', props);
                }}
            />
            <DialogCancelSignRequest
                open={bDialogCancelSignRequestOpen}
                bLoading={bLoading}
                onClose={() => {
                    // setDialogCancelSignRequestOpen(false);
                }}
                onClickCancel={() => {
                    setDialogCancelSignRequestOpen(false);
                }}
                onClickRequestCancel={() => {
                    cancelSignRequest();
                    setDialogCancelSignRequestOpen(false);
                }}
            />
            <DialogResendNotification
                open={bDialogResendNotification}
                bLoading={bLoading}
                //docToEdit = {docToEdit}
                targetList = {aTargetList}
                onClose={() => {
                    // setDialogResendNotification(false);
                }}
                onClickCancel={() => {
                    setDialogResendNotification(false);
                }}
                onClickResendNotificationEmail={(docSignTargetIdx) => {
                    resendNotification("01", docSignTargetIdx);
                    setDialogResendNotification(false);
                }}
                onClickResendNotificationSMS={(docSignTargetIdx) => {
                    resendNotification("02", docSignTargetIdx);
                    setDialogResendNotification(false);
                }}
            />
            <DialogAgainSignRequest
                open={bDialogAgainSignRequest}
                bLoading={bLoading}
                //docToEdit = {docToEdit}
                targetList = {aTargetList}
                onClose={() => {
                    // setDialogResendNotification(false);
                }}
                onClickCancel={() => {
                    setDialogAgainSignRequest(false);
                }}
                onClickAgainSignRequest={() => {
                    againSignRequest();
                    setDialogAgainSignRequest(false);
                }}
            />
            <DialogChangeExpireDays
                open={bDialogChangeExpireDays}
                bLoading={bLoading}
                docToEdit={docToEdit}
                onClose={() => {
                    // setDialogChangeExpireDays(false);
                }}
                onClickCancel={() => {
                    setDialogChangeExpireDays(false);
                }}
                onClickChange={iDays => {
                    changeExpireDays(iDays);
                    setDialogChangeExpireDays(false);
                }}
            />
            <DialogSignProgressInformation
                open={bDialogSignProgressInformation}
                bLoading={bLoading}
                docToEdit={docToEdit}
                onClose={() => {
                    // setDialogSignProgressInformation(false);
                }}
                onClickCancel={() => {
                    setDialogSignProgressInformation(false);
                }}
            />
            <FileUploader
                open={bFileUploaderOpen}
                bLoading={bLoading}
                onClose={() => {
                    if (!bLoading) {
                        setFileUploaderOpen(false);
                    }
                }}
                nextStep={par => {
                    if (par.type === 'computer') {
                        const data = new FormData();
                        data.append('file', par.file);
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/upload`, data)
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if (par.type === 'googledrive') {
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/googledrive`, { fileId: par.file, fileName: par.filename, accessToken: par.token })
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if (par.type === 'dropbox') {
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/dropbox`, { fileId: par.file, fileName: par.filename, accessToken: par.token })
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if (par.type === 'onedrive') {
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/onedrive`, { fileUrl: par.downloadlink })
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if(par.type === 'box') {
                        const { token, authenticated_download_url, name } = par;
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docTemplate/box`, { token, authenticated_download_url, name })
                            .then(callbackFileUpload).catch(callbackFileUploadCatch);
                    }
                    if (par.type === 'my_templates') {
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docSignRequest/fromTemplate`, { templateIdx: par.file })
                        .then(res => {
                            setLoading(false);
                            switch (res.data.status) {
                                case '000':
                                    if (res.data.entity !== undefined && res.data.entity !== null) {
                                        setGlobal({
                                            iEditingSignReqId: (res.data.entity !== undefined && res.data.entity !== null) ? res.data.entity.docSignRequestIdx : -1,
                                            sEditingSignReqName: (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.templateName !== null) ? res.data.entity.templateName : '',
                                            sEditingSignReqExpireDay: (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.signExpired !== null) ? res.data.entity.signExpired.toString() : '',
                                        });
                                        navigatePage('/my_document/sign_participant', props);
                                    }
                                    break;
                                default:
                            }
                        })
                        .catch(err => {
                            setLoading(false);
                            toast_warning(`System error: ${JSON.stringify(err)}`);
                        });
                    }
                }}
            />
        </div>
        // <div>
        //     <div>My Documents</div>
        //     <button
        //         onClick={() => {
        //             navigatePage('/my_document/sign_participant', props);
        //         }}
        //     >
        //         participants
        //     </button>
        // </div>
    );
}

export default Entity;