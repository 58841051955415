import React, { useEffect, useState } from 'reactn'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    list_wrapper: {
        width: '100%',
        height: 40,
        borderBottom: '1px solid #333'
    },
    list_ul: {
        listStyleType: 'none',
        padding: 0
    },
    list_li: {
        width: 120,
        height: 40,
        backgroundColor: 'white',
        border: '2px solid #333',
        borderBottom: 'none',
        fontSize: '1.1em',
        float: 'left',
        cursor: 'pointer',
        lineHeight: '40px',
        textAlign: 'center',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EDF1F3'
        }
    },
    li_sign: {
        borderRadius: '4px 0 0 0'
    },
    li_stamp: {
        borderRadius: '0 4px 0 0',
        borderLeft: 'none',
        borderBottom: '1px solid #333'
    }
}));

const TableTab = (props) => {

    const classes = useStyles();

    useEffect(() => {  
        let li;      
        if(props.activeTab == 'sign') {
            li = document.getElementById('sign_tab');
        } else {
            li = document.getElementById('stamp_tab');
        }

        const lists = document.getElementsByClassName('tab');
        for (let i=0; i<lists.length; i++) {
            lists.item(i).style.borderBottom = '1px solid #333';
        }
        li.style.borderBottom = 'none';
        
    }, [props.activeTab]);
    
    function selectTab(e) {
        console.log(e.target.id);
        if (e.target.id === 'sign_tab') {
            props.onChange('sign');
        } else {
            props.onChange('stamp');
        }
    }
    
    return (
        <div className={classes.list_wrapper}>
            <ul className={classes.list_ul}>
                <li className={`${classes.list_li} ${classes.li_sign} tab`} id="sign_tab" onClick={e => selectTab(e)}>사인</li>
                <li className={`${classes.list_li} ${classes.li_stamp} tab`} id="stamp_tab" onClick={e => selectTab(e)}>도장</li>
            </ul>
        </div>
    )
}

export default TableTab;