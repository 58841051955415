import React, { Fragment, useState, useGlobal } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import { toast_success, toast_warning } from '../api/api';

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const Dialog = withStyles(theme => ({
    paper: {
        width: '100%',
        maxWidth: 500,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 4,
        //     marginRight: 4,
        // },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    textfield_label: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    textInputfield: {
        marginTop: 5,
        marginBottom: 5,
        height: 20,
    },
    button_sign_request: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        margin: '0 15px',
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    button_cancel: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            // color: 'white',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    dialogAction: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 140,
        },
    },
}));

const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
        outline: 'none',
      },
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        outline: 'none',
        height: 38,
        width: '100%',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        // padding: '10px 26px 10px 12px',
        padding: 0,
        paddingLeft: 10,
        // paddingRight: 10,
        lineHeight: 1,
        // color: '',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        // fontFamily: [
        //     '-apple-system',
        //     'BlinkMacSystemFont',
        //     '"Segoe UI"',
        //     'Roboto',
        //     '"Helvetica Neue"',
        //     'Arial',
        //     'sans-serif',
        //     '"Apple Color Emoji"',
        //     '"Segoe UI Emoji"',
        //     '"Segoe UI Symbol"',
        // ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function Entity(props) {
    const [sDocSignTargetIdx, setDocSignTargetIdx] = React.useState('');
    const [sNewTemplateName, setNewTemplateName] = React.useState('');
    const [sEditingDocTemplateName,setEditingDocTemplateName] = useGlobal('sEditingDocTemplateName');
    const classes = useStyles();
    
    return (
        <Fragment>
            <Dialog
                // onClose={props.onClose}
                aria-labelledby="dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogContent dividers>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 50,
                                marginBottom: 15,
                                alignItems: 'center',
                            }}
                        >
                            <h3>문서명 변경하기</h3>
                        </div>
                        {/** 
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 75,
                            }}
                        >
                            <p>서명을 다시 요청하시겠습니까?</p>
                        </div>
                        */}
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                marginRight: 6,
                                outline: 'none',
                                marginBottom: 15,
                                alignItems: 'center',
                            }}
                        >      
                            <div
                                style={{
                                    display: 'flex',
                                    width: '30%', 
                                    justifyContent : 'center',
                                    paddingTop : 5,
                                }}
                            >
                                <label htmlFor='field5' className={classes.textfield_label} >문서명</label> 
                            </div>
                            <FormControl
                                style={{
                                    height: 40,
                                    width: '60%',
                                    outline: 'none',
                                }}
                            >
                                <TextField
                                    id="s_password"
                                    className={classes.textInputfield}
                                    variant="outlined"                
                                    size='small'                            
                                    //style={{display:sSMSAuth}}
                                    //type='password'
                                    label="문서명"
                                    // placeholder="서명 참여자의 명의의 휴대폰 번호를 입력해 주세요"
                                    value={sEditingDocTemplateName}
                                    onChange={e => setEditingDocTemplateName(e.target.value)}
                                    //fullWidth
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.dialogAction}>
                        {props.bLoading && (
                            <div
                                style={{
                                    // width: '100%',
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}
                            >
                                <CircularProgress disableShrink />
                            </div>
                        )}
                        {!props.bLoading && (
                            <Fragment>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.button_sign_request}
                                    onClick={() => {
                                        if(sEditingDocTemplateName == null || sEditingDocTemplateName == '' || sEditingDocTemplateName.length == 0) {
                                            toast_warning('변경할 문서명을 입력해주세요.'); 
                                        }
                                        props.onClickCreate(sEditingDocTemplateName);
                                    }}
                                >
                                    확인
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.button_cancel}
                                    onClick={props.onClickCancel}
                                >
                                    취소
                                </Button>
                            </Fragment>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default Entity;