import React, { useState, useEffect, useGlobal, Fragment } from 'reactn';
import PropTypes from 'prop-types';
import {
    Typography,
    TextField,
    Button,
    Divider,
    Link,
} from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { toast_success, toast_warning } from '../api/api';
import Radio from '@material-ui/core/Radio';
import axios from "axios";

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});


const useStyles = makeStyles(theme => ({
    payarea: {
        width: "100%",
        margin: "0 auto",
        maxWidth: "1200px",
        padding: "0 20px",
        display: "flex",
        paddingTop: "45px",
    },

    paybox: {
        width: "33.333%",
        border: "none",
        boxShadow: "0 0 8px 5px rgba(0, 0, 0, 0.09)",
        height: "320px",
        margin: "0 6px",
        position: "relative",
        borderTop: "5px solid #c1c1c1",
        textAlign: "center",
        cursor: "pointer",
    },

    payment: {
        textAlign: "center",
        padding: "0 20px",
    },

    paytxt: {
        width: "100%",
        margin: "auto",
        maxWidth: "1200px",
        padding: "0 20px",
        //display: "flex",
        paddingTop: "50px",
    },
    paybtnDiv: {
        padding: "30px 0 60px",
        textAlign: "center",
    },
    paybtn: {
        width: "120px",
        height: "54px",
        fontSize: "17px",
        padding: "8px",
        position: "relative",
        top: "-2px",
        background: "#fff",
        color: "#1B1464",
        borderRadius: "5px",
        border: "1.5px solid #1B1464",
        textAlign: "center",
        //fontFamily: 'Noto Sans KR', sans-serif;
        cursor: "pointer",
        fontWeight: 400,
        transition: "all .3s",
        "&:hover": {
            background: "#1B1464",
            color: "#fff",
        },
        
    },
}));

function Entity() {
	const classes = useStyles();

	const [sToken, setToken] = useGlobal('sToken');
	const [sApiUrl] = useGlobal('sApiUrl');
	const [bLoading, setLoading] = useState(false);

    const [bPaymentA, setPaymentA] = useState(false);
    const [bPaymentB, setPaymentB] = useState(false);
    const [bPaymentC, setPaymentC] = useState(false);
    const [sPayName, setPayName] = useState('');
    const [sPayAmt, setPayAmt] = useState('');

	useEffect(() => {
        /*
		let sUrl = `/${sApiUrl}/user/settings`;
		setLoading(true);
		axios.create({
			headers: {
				Authorization: `Bearer ${sToken}`,
			}
		}).get(sUrl)
			.then(res => {
				setLoading(false);
				switch (res.data.status) {
					case '000':
						if (res.data.entity !== undefined && res.data.entity !== null) {
							setName(res.data.entity.userName);
							setEmail(res.data.entity.userEmail);
							if (res.data.entity.userCompany !== undefined && res.data.entity.userCompany !== null) {
								setHasCompany(true);
								setCompanyName(res.data.entity.userCompany.companyName);
							} else {
								setHasCompany(false);
							}
						} else {
						}
						break;
					default:
						toast_warning(res.data.message);
				}
			}).catch(err => {
				setLoading(false);
				toast_warning(`System error: ${JSON.stringify(err)}`);
            });
            */
	}, [sApiUrl, sToken]);

    function handleChange(type) {
        setPaymentA(false);
        setPaymentB(false);
        setPaymentC(false);
        document.getElementById("divPaymentA").style.borderTop = "5px solid #c1c1c1";
        document.getElementById("divPaymentB").style.borderTop = "5px solid #c1c1c1";
        document.getElementById("divPaymentC").style.borderTop = "5px solid #c1c1c1";

        if(type == "A") {
            setPaymentA(true);
            document.getElementById("divPaymentA").style.borderTop = "5px solid #1B1464";
            setPayName('10건 요금제');
            setPayAmt('10,000원');
        } else if(type == "B") {
            setPaymentB(true);
            document.getElementById("divPaymentB").style.borderTop = "5px solid #1B1464";
            setPayName('50건 요금제');
            setPayAmt('35,000원');
        } else if(type == "C") {
            setPaymentC(true);
            document.getElementById("divPaymentC").style.borderTop = "5px solid #1B1464";
            setPayName('100건 요금제');
            setPayAmt('65,000원');
        }
    }
    
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				// justifyContent: 'center',
				alignItems: 'center',
			}}
		>
            <div className={classes.payarea}>
                <div className={classes.paybox} id="divPaymentA" onClick={() => { handleChange("A"); }}>
                    <label className={classes.payment} htmlFor="paymentA"></label>
                    <Radio
                        id="paymentA"
                        checked={bPaymentA}
                        onChange={() => { handleChange("A"); }}
                        name="radio-button-demo"
                        color="primary"
                        value={bPaymentA}
                        style={{
                            height: "21px",
                            position: "absolute",
                            left: "50%",
                            top: "25px",
                            width: "20px",
                            zIndex: "10",
                            transform: "translateX(-50%)",
                        }}
                    />
                    <Typography 
                        variant="body2" 
                        onClick={() => { handleChange("A"); }}
                        style={{ 
                            textAlign: "center",
                            fontSize: "16px",
                            paddingTop: "50px",
                            color: "#1B1464",
                            paddingBottom: "5px",
                        }}
                    >
                        10건 요금제
                    </Typography>
                    <Typography 
                        variant="h4" 
                        onClick={() => { handleChange("A"); }}
                        style={{ 
                            fontWeight: "bold",
                        }}
                    >
                    10,000원
                    </Typography>
                    
                    <Divider
                        variant="middle"
                        onClick={() => { handleChange("A"); }}
                        style={{
                            width: '100%',
                            margin: 0,
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                    />
                    <Typography 
                        variant="body2" 
                        onClick={() => { handleChange("A"); }}
                        style={{ 
                            color: "#777",
                            fontSize: "16px",
                            margin: "20px 0",
                        }}
                    >
                    전자 서명 요청
                    </Typography>
                    <Typography 
                        variant="body2" 
                        onClick={() => { handleChange("A"); }}
                        style={{ 
                            fontWeight: 600,
                            fontSize: "21px",
                            color: "#1B1464",
                            paddingTop: "8px",
                        }}
                    >
                    월 10건
                    </Typography>
                </div>
                
                <div className={classes.paybox} id="divPaymentB" onClick={() => { handleChange("B"); }}>
                    <label className={classes.payment} htmlFor="paymentB"></label>
                    <Radio
                        id="paymentB"
                        checked={bPaymentB}
                        onChange={() => { handleChange("B"); }}
                        name="radio-button-demo"
                        color="primary"
                        value={bPaymentB}
                        style={{
                            height: "21px",
                            position: "absolute",
                            left: "50%",
                            top: "25px",
                            width: "20px",
                            zIndex: "10",
                            transform: "translateX(-50%)",
                        }}
                    />
                    <Typography 
                        variant="body2" 
                        onClick={() => { handleChange("B"); }}
                        style={{ 
                            textAlign: "center",
                            fontSize: "16px",
                            paddingTop: "50px",
                            color: "#1B1464",
                            paddingBottom: "5px",
                        }}
                    >
                        50건 요금제
                    </Typography>
                    <Typography 
                        variant="h4" 
                        onClick={() => { handleChange("B"); }}
                        style={{ 
                            fontWeight: "bold",
                        }}
                    >
                    35,000원
                    </Typography>
                    
                    <Divider
                        variant="middle"
                        onClick={() => { handleChange("B"); }}
                        style={{
                            width: '100%',
                            margin: 0,
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                    />
                    <Typography 
                        variant="body2" 
                        onClick={() => { handleChange("B"); }}
                        style={{ 
                            color: "#777",
                            fontSize: "16px",
                            margin: "20px 0",
                        }}
                    >
                    전자 서명 요청
                    </Typography>
                    <Typography 
                        variant="body2" 
                        onClick={() => { handleChange("B"); }}
                        style={{ 
                            fontWeight: 600,
                            fontSize: "21px",
                            color: "#1B1464",
                            paddingTop: "8px",
                        }}
                    >
                    월 50건
                    </Typography>
                </div>

                <div className={classes.paybox} id="divPaymentC" onClick={() => { handleChange("C"); }}>
                    <label className={classes.payment} htmlFor="paymentC"></label>
                    <Radio
                        id="paymentC"
                        checked={bPaymentC}
                        onChange={() => { handleChange("C"); }}
                        name="radio-button-demo"
                        color="primary"
                        value={bPaymentC}
                        style={{
                            height: "21px",
                            position: "absolute",
                            left: "50%",
                            top: "25px",
                            width: "20px",
                            zIndex: "10",
                            transform: "translateX(-50%)",
                        }}
                    />
                    <Typography 
                        variant="body2" 
                        onClick={() => { handleChange("C"); }}
                        style={{ 
                            textAlign: "center",
                            fontSize: "16px",
                            paddingTop: "50px",
                            color: "#1B1464",
                            paddingBottom: "5px",
                        }}
                    >
                        100건 요금제
                    </Typography>
                    <Typography 
                        variant="h4" 
                        onClick={() => { handleChange("C"); }}
                        style={{ 
                            fontWeight: "bold",
                        }}
                    >
                    65,000원
                    </Typography>
                    
                    <Divider
                        variant="middle"
                        onClick={() => { handleChange("C"); }}
                        style={{
                            width: '100%',
                            margin: 0,
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                    />
                    <Typography 
                        variant="body2" 
                        onClick={() => { handleChange("C"); }}
                        style={{ 
                            color: "#777",
                            fontSize: "16px",
                            margin: "20px 0",
                        }}
                    >
                    전자 서명 요청
                    </Typography>
                    <Typography 
                        variant="body2" 
                        onClick={() => { handleChange("C"); }}
                        style={{ 
                            fontWeight: 600,
                            fontSize: "21px",
                            color: "#1B1464",
                            paddingTop: "8px",
                        }}
                    >
                    월 100건
                    </Typography>
                </div>
            </div>
            <div className={classes.paytxt}>
                <Typography 
                    variant="h4" 
                    style={{ 
                        fontSize: "22px",
                        fontWeight: "bold",
                        paddingBottom: "25px",
                        borderBottom: "1px solid #c9c9c9",
                        marginBottom: "20px",
                    }}
                >
                    결제 금액
                </Typography>
                <Typography 
                    variant="body2" 
                    style={{ 
                        fontSize: "17px",
                        padding: "16px 0",
                        borderBottom: "1px solid #c9c9c9",
                    }}
                >
                    {sPayName}<span style={{float:"right"}}>{sPayAmt}</span>
                </Typography>
                <Typography 
                    variant="body2" 
                    style={{ 
                        fontSize: "17px",
                        padding: "16px 0",
                        borderBottom: "1px solid #c9c9c9",
                    }}
                >
                    결제 금액<span style={{float:"right", fontSize: "25px",position: "relative", fontWeight: "bold",}}>{sPayAmt}</span>
                </Typography>
                <div className={classes.paybtnDiv}>
                    <button className={classes.paybtn}>결제하기</button>
                </div>
            </div>
			
		</div>
	);
}

export default Entity;