import React, { Fragment, useState } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
    Checkbox,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from '@material-ui/core/Divider';
import { toast_warning } from '../api/api';
// import axios from "axios";

// import { toast_warning } from '../api/api';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1.4em' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        textAlign: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
}))(MuiDialogActions);

const Dialog = withStyles(theme => ({
    paper: {
        width: '80%',
        minWidth: 700,
        minHeight: 300,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 4,
        //     marginRight: 4,
        // },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    button_sign_request: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    button_cancel: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            // color: 'white',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    dialogAction: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 140,
        },
    },
    smsTextfield: {
        marginBottom: 10,
    },
}));

function Entity(props) {

    const classes = useStyles();

    const [bCheckbox1, setCheckbox1] = useState(false);
    const [bCheckbox2, setCheckbox2] = useState(false);

    // const [sToken] = useGlobal('sToken');
    // const [sApiUrl] = useGlobal('sApiUrl');

    const [bLoading, setLoading] = useState(false);

    // console.log(props.oSummary);

    return (
        <Fragment>
            <Dialog
                onClose={props.onClose}
                aria-labelledby="dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogTitle id="fileuploader-dialog-title" onClose={props.onClose}>
                    서명 요청 정보 확인
                </DialogTitle>
                <DialogContent dividers>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 180,
                            // height: '60vh',
                            padding: '20px 20px 0px 20px',
                        }}
                    >
                        {bLoading && (
                            <div
                                style={{
                                    // width: '100%',
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}
                            >
                                <CircularProgress disableShrink />
                            </div>
                        )}
                        {!bLoading && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <div style={{ flex: 0.3 }}>문서이름</div>
                                    <div style={{ flex: 0.7, fontWeight: 'bold' }}>{props.oSummary.name}</div>
                                </div>
                                <Divider style={{
                                    marginTop: 15,
                                    marginBottom: 15,
                                }} />
                                <div
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <div style={{ flex: 0.3 }}>서명 참여자</div>
                                    <div
                                        style={{
                                            flex: 0.7,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {props.oSummary.aTarget.map((item, index) => {
                                            let sAuthUsage = '인증 설정 안 되어 있습니다';
                                            if (item.authUse === 'Y') {
                                                if (item.authType === '01') {
                                                    sAuthUsage = '이메일 인증';
                                                }
                                                if (item.authType === '02') {
                                                    sAuthUsage = 'SMS 인증';
                                                }
                                                if (item.authType === '03') {
                                                    sAuthUsage = '휴대폰 본인인증';
                                                }
                                            }
                                            return (
                                                <div
                                                    key={item.id}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <span style={{ fontWeight: 'bold' }}>{index + 1}. {item.targetName} ({item.targetEmail})</span>
                                                    <span>{sAuthUsage}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <Divider style={{
                                    marginTop: 15,
                                    marginBottom: 15,
                                }} />
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            paddingLeft: 50,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Checkbox
                                                color="secondary"
                                                checked={bCheckbox1}
                                                onChange={event => {
                                                    setCheckbox1(event.target.checked);
                                                }}
                                            />
                                            <div>
                                                문서 내용, 이름, 이메일 등을 요청 이후 수정할 수 없습니다.
                                            </div>
                                        </div>
                                        {/*<div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Checkbox
                                                color="secondary"
                                                checked={bCheckbox2}
                                                onChange={event => {
                                                    setCheckbox2(event.target.checked);
                                                }}
                                            />
                                            <div>
                                                서명 요청 1건이 바로 차감되며, 취소 및 거절 시 복구 되지 않습니다.
                                            </div>
                                        </div>
                                        */}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    {props.bLoading && (
                        <div
                            style={{
                                // width: '100%',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!props.bLoading && (
                        <Fragment>
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.button_sign_request}
                                //disabled={!(bCheckbox1 && bCheckbox2)}
                                disabled={!(bCheckbox1)}
                                onClick={() => {
                                    const aUserWithoutAuth = props.oSummary.aTarget.filter(item => {
                                        if (item.authUse === 'Y') {
                                            if (item.authType === '01') {
                                                return false;
                                            }
                                            if (item.authType === '02') {
                                                return false;
                                            }

                                            if (item.authType === '03') {
                                                return false;
                                            }
                                        }
                                        return true;
                                    });
                                    
                                    if (aUserWithoutAuth.length === 0) {
                                        props.onClickCreate();
                                    } else {
                                        toast_warning('본인인증수단을 선택해주세요.');
                                    }
                                }}
                            >
                                서명 요청 하기
                            </Button>
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.button_cancel}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                취소
                            </Button>
                        </Fragment>
                    )}
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default Entity;