import React, { Fragment, useState, useGlobal } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
    Checkbox,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from '@material-ui/core/Divider';
import axios from "axios";

import { toast_warning } from '../api/api';

import SignCreateDialogContent from './SignCreateDialogContent';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1.4em' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        textAlign: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
}))(MuiDialogActions);

const Dialog = withStyles(theme => ({
    paper: {
        width: '80%',
        minWidth: 700,
        minHeight: 450,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 4,
        //     marginRight: 4,
        // },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    button_sign_from: {
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        flex: 1,
        margin: 10,
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },


    button_sign_request: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    button_cancel: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            // color: 'white',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    dialogAction: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 140,
        },
    },
    smsTextfield: {
        marginBottom: 10,
    },
}));

function Entity(props) {

    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');

    const [iViewType, setViewType] = useState(1);

    const [sCanvasImg, setCanvasImg] = useState(null);
    const [oAttachment, setAttachment] = useState(null);
    const [iGeneratedImgId, setGeneratedImgId] = useState(-1);

    const [bCheckbox1, setCheckbox1] = useState(false);
    const [bCheckbox2, setCheckbox2] = useState(false);

    const [bLoading, setLoading] = useState(false);

    // console.log(props.open);

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i += 1) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    return (
        <Fragment>
            <Dialog
                // onClose={props.onClose}
                aria-labelledby="dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogTitle id="signcreate-dialog-title" onClose={props.onClose}>
                    사인 생성하기
                </DialogTitle>
                <DialogContent dividers>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 300,
                            // height: '60vh',
                            padding: 20,
                            paddingTop: 0,
                            // backgroundColor: 'green',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'stretch',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                // backgroundColor: 'green',
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.button_sign_from}
                                onClick={() => {
                                    setViewType(1);
                                }}
                            >
                                사인 그리기
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.button_sign_from}
                                onClick={() => {
                                    setViewType(2);
                                }}
                            >
                                사인 생성
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.button_sign_from}
                                onClick={() => {
                                    setViewType(3);
                                }}
                            >
                                사인 업로드
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.button_sign_from}
                                onClick={() => {
                                    setViewType(4);
                                }}
                                disabled={true}
                            >
                                내 사인
                            </Button>
                        </div>
                        <div
                            style={{
                                // backgroundColor: 'red',
                                minHeight: 300,
                            }}
                        >
                            <SignCreateDialogContent
                                iViewType={iViewType}
                                setCanvasImg={setCanvasImg}
                                setAttachment={setAttachment}
                                iGeneratedImgId={iGeneratedImgId}
                                setGeneratedImgId={setGeneratedImgId}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {bLoading && (
                        <div
                            style={{
                                // width: '100%',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!bLoading && (
                        <Fragment>
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.button_sign_request}
                                // disabled={!(bCheckbox1 && bCheckbox2)}
                                onClick={() => {
                                    if (iViewType === 1) {
                                        // props.onClickCreate();
    
                                        const block = sCanvasImg.split(";");
                                        // Get the content type
                                        const contentType = block[0].split(":")[1];// In this case "image/gif"
                                        // get the real base64 content of the file
                                        const realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."
    
                                        const blob = b64toBlob(realData, contentType);
    
                                        // blob.arrayBuffer().then(buffer => {
                                        //     console.log(buffer);
                                        // });
    
                                        const data = new FormData();
                                        data.append('file', blob, 'canvas.png');
                                        data.append('signType', '01');
                                        setLoading(true);
                                        axios.create({
                                            headers: {
                                                Authorization: `Bearer ${sToken}`,
                                            }
                                        }).post(`/${sApiUrl}/docUserSignImg/upload`, data)
                                        .then(res => {
                                            setLoading(false);
                                            switch (res.data.status) {
                                                case '000':
                                                    // if (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.length > 0) {
                                                    // }
                                                    props.onClose();
                                                    break;
                                                default:
                                                    toast_warning(res.data.message);
                                            }
                                        })
                                        .catch(err => {
                                            setLoading(false);
                                            toast_warning(`System error: ${JSON.stringify(err)}`);
                                        });
                                    }
                                    if (iViewType === 2) {
                                        if (iGeneratedImgId !== -1) {
                                            setLoading(true);
                                            const sUrl = `/${sApiUrl}/docUserSign/${iGeneratedImgId}`;
                                            const data = new FormData();
                                            data.append('signType', '01');
                                            console.log(data);
                                            axios.create({
                                                headers: {
                                                    Authorization: `Bearer ${sToken}`,
                                                }
                                            }).post(sUrl, data)
                                            .then(res => {
                                                setLoading(false);
                                                switch (res.data.status) {
                                                    case '000':
                                                        props.onClose();
                                                        // if (res.data.entity !== undefined && res.data.entity !== null) {
                                                        // }
                                                        break;
                                                    default:
                                                        toast_warning(res.data.message);
                                                }
                                            }).catch(err => {
                                                setLoading(false);
                                                toast_warning(`System error: ${JSON.stringify(err)}`);
                                            });
                                        }
                                    }
                                    if (iViewType === 3) {
                                        if (oAttachment === null) {
                                            toast_warning('파일을 업로드해주세요.');
                                            return;
                                        }
                                        const data = new FormData();
                                        data.append('file', oAttachment);
                                        data.append('signType', '01');
                                        setLoading(true);
                                        axios.create({
                                            headers: {
                                                Authorization: `Bearer ${sToken}`,
                                            }
                                        }).post(`/${sApiUrl}/docUserSignImg/upload`, data)
                                        .then(res => {
                                            setLoading(false);
                                            switch (res.data.status) {
                                                case '000':
                                                    // if (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.length > 0) {
                                                    // }
                                                    props.onClose();
                                                    break;
                                                default:
                                                    toast_warning(res.data.message);
                                            }
                                        })
                                        .catch(err => {
                                            setLoading(false);
                                            toast_warning(`System error: ${JSON.stringify(err)}`);
                                        });
                                    }
                                }}
                            >
                                입력하기
                            </Button>
                        </Fragment>
                    )}
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default Entity;