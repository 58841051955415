//import React from 'reactn';
import React, { useState, useEffect, setGlobal, useGlobal, useCallback, useRef } from 'reactn';

import StampCreateDialogContentView1 from './StampCreateDialogContentView1';
import StampCreateDialogContentView2 from './StampCreateDialogContentView2';

const Entity = props => {
    return (
        <div>
            {props.iViewType === 1 && (
                <StampCreateDialogContentView1 iGeneratedImgId={props.iGeneratedImgId} setGeneratedImgId={props.setGeneratedImgId} setSelectedStamp={props.setSelectedStamp} />
            )}
            {props.iViewType === 2 && (
                <StampCreateDialogContentView2 setAttachment={props.setAttachment} />
            )}
            
        </div>
    );
};

export default Entity;