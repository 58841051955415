import React, { useState, useGlobal } from 'reactn';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';

import axios from "axios";

import { toast_warning } from '../api/api';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    selectLang: {
        minWidth: 120,
    },
    textField: {
        flex: 1,
        margin: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    create_btn: {
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 1,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    generatedSignImg: {
        width: '100%',
        border: '1px solid grey',
        cursor: 'pointer',
        backgroundColor: '#ccc',
        '&:hover': {
            borderColor: 'blue',
            backgroundColor: 'white',
        },
    },
    generatedSignImgSelected: {
        width: '100%',
        border: '1px solid blue',
        cursor: 'pointer',
        backgroundColor: 'white',
        '&:hover': {
            // borderColor: 'blue',
            backgroundColor: 'white',
        },
    },
}));

const Entity = props => {

    const classes = useStyles();

    const [bLoading, setLoading] = React.useState(false);

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');

    const [sLanguage, setLanguage] = useState(1);
    const [sName, setName] = useState('');
    const [aSignImg, setSignImg] = useState([]);

    return (
        <div
            className={classes.container}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 10,
                }}
            >
                <Select
                    className={classes.selectLang}
                    value={sLanguage}
                    onChange={e => setLanguage(e.target.value)}
                    displayEmpty
                    margin="dense"
                    input={<OutlinedInput />}
                >
                    <MenuItem value={1}>한글</MenuItem>
                    <MenuItem value={2}>영문</MenuItem>
                </Select>
                <TextField
                    className={classes.textField}
                    label="이름을 입력해 주세요."
                    margin="dense"
                    variant="outlined"
                    value={sName}
                    onChange={e => setName(e.target.value)}
                />
                <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.create_btn}
                    onClick={() => {
                        setLoading(true);
                        const sUrl = `/${sApiUrl}/docUserSignImg/create`;
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(sUrl, {
                            name: sName,
                            fontLanguage: sLanguage === 2 ? 'EN' : 'KR',
                        })
                        .then(res => {
                            setLoading(false);
                            switch (res.data.status) {
                                case '000':
                                    if (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.length > 0) {
                                        setSignImg(res.data.entity.map(item => {
                                            return {
                                                id: item.signImgIdx,
                                                filename: item.systemFileName,
                                            };
                                        }));
                                    }
                                    break;
                                default:
                                    toast_warning(res.data.message);
                            }
                        }).catch(err => {
                            setLoading(false);
                            toast_warning(`System error: ${JSON.stringify(err)}`);
                        });
                    }}
                >
                    생성하기
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        width: 400,
                        display: 'grid',
                        gridTemplateColumns: '50% 50%',
                    }}
                >
                    {aSignImg.map(oImg => (
                        <div
                            key={oImg.id}
                            style={{
                                padding: 2,
                            }}
                        >
                            <img
                                onClick={() => {
                                    props.setGeneratedImgId(oImg.id);
                                }}
                                className={props.iGeneratedImgId === oImg.id ? classes.generatedSignImgSelected : classes.generatedSignImg}
                                alt={oImg.filename}
                                src={`${sApiUrl}/docUserSignImg/downloadSignImg/${oImg.id}`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Entity;