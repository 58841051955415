import React, { useState, useGlobal, useEffect } from 'reactn';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';

import axios from "axios";

import { toast_warning } from '../api/api';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    selectLang: {
        minWidth: 120,
    },
    textField: {
        flex: 1,
        margin: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    create_btn: {
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 1,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    generatedSignImg: {
        width: '100%',
        border: '1px solid grey',
        cursor: 'pointer',
        backgroundColor: '#ccc',
        '&:hover': {
            // borderColor: 'red',
            backgroundColor: 'white',
        },
    },
}));

const Entity = props => {

    const classes = useStyles();

    const [bLoading, setLoading] = React.useState(false);

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');

    const [aSignImg, setSignImg] = useState([]);

    useEffect(() => {
        setLoading(true);
        const sUrl = `/${sApiUrl}/docUserSign`;
        axios.create({
            headers: {
                Authorization: `Bearer ${sToken}`,
            }
        }).get(sUrl)
        .then(res => {
            setLoading(false);
            switch (res.data.status) {
                case '000':
                    if (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.length > 0) {
                        setSignImg(res.data.entity.map(item => {
                            return {
                                signIdx: item.signIdx,
                                signImgIdx: item.signImgIdx,
                                filename: item.docUserSignImg.systemFileName,
                            };
                        }));
                    }
                    break;
                default:
                    toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(`System error: ${JSON.stringify(err)}`);
        });
    }, []);

    return (
        <div
            className={classes.container}
        >
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        width: 400,
                        display: 'grid',
                        gridTemplateColumns: '50% 50%',
                    }}
                >
                    {aSignImg.map(oImg => (
                        <div
                            key={oImg.signIdx}
                            style={{
                                padding: 2,
                            }}
                        >
                            <img
                                className={classes.generatedSignImg}
                                alt={oImg.filename}
                                src="https://www.docsketch.com/assets/vip-signatures/muhammad-ali-signature-6a40cd5a6c27559411db066f62d64886c42bbeb03b347237ffae98b0b15e0005.svg"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Entity;