/* eslint no-restricted-globals:0 */
import React, { Component } from "reactn";
import queryString from "query-string";
import axios from "axios";
import { clientId, clientSecret, grantType } from "../consts/box.config";
import { withRouter } from "react-router-dom";

class GetBoxToken extends Component {
  componentDidMount() {
    const { code } = queryString.parse(location.search);
    if (!code) {
      this.props.history.push('/');
      return
    };
    axios({
      url: "https://api.box.com/oauth2/token",
      method: "POST",
      data: `grant_type=${grantType}&code=${code}&client_id=${clientId}&client_secret=${clientSecret}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      }
    })
      .then(response => {
        const {
          status,
          data: { access_token },
          statusText
        } = response;
        if (status >= 200 && status < 300) {
          window.opener.setBoxToken(access_token);
        } else throw new Error(statusText);
      })
      .catch(e => {
        if (window.opener.onError) window.opener.onError(e);
        console.error(e);
      })
      .finally(() => {
        self.close();
        window.close();
      });
  }
  render() {
    return <p>Loading...</p>;
  }
}

export default withRouter(GetBoxToken);
