import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'typeface-roboto';
import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import axios from "axios";
import Kakao from 'kakaojs';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


Kakao.init('2e4a9a1e1a0871abf2a48c472200e97c');

const token = Cookies.get('token');

const sApiUrl = 'onlinesign';

setGlobal({
    bAuth: false,
    sToken: token === undefined ? '' : token,

    //sGoogleClientId: '831783532694-al3u3phd4b5cncma9ak8ksm90ml9srds.apps.googleusercontent.com',
    sGoogleClientId: '726193916115-mp6hdkr3lg6kgq27vsvf344g9897np8f.apps.googleusercontent.com', //HYD
    
    //sGoogleDriveApiKey: 'AIzaSyB1_L78AwiST32lP7rRxmz9Eyl4TAfRzWQ',
    sGoogleDriveApiKey: 'AIzaSyD9Sw7-6ZtoW2p-8MscnC7Shs24frggqX4', //HYD    
    sGoogleDriveDiscoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
    //sGoogleDriveScopes: 'https://www.googleapis.com/auth/drive.metadata.readonly',
    sGoogleDriveScopes: 'https://www.googleapis.com/auth/drive',

    //sDropBoxAppKey: 'wlbr4d2agtu47k6',
    sDropBoxAppKey: 'c1b4ua43n5cy0q7', //HYD

    //sOneDriveClientId: '6d1bad9d-68c7-4839-809f-c2ae7157f2f0',
    sOneDriveClientId: '959c9721-340a-44cb-94dc-bae523bcc1a7', //HYD    
    sOneDriveScope: 'files.read.all',

    //sFacebookAppId: '693312391145283',
    sFacebookAppId: '203127598008985',
    
    sDomainName: 'onlinesign.co.kr', //운영
    //sDomainName: 'pilot.onlinesign.co.kr', //개발
    //sDomainName: 'localhost:3000', //로컬

    sViewerDomainName: 'https://onlinesign.co.kr', //운영
    //sViewerDomainName: 'http://pilot.onlinesign.co.kr', //개발
    //sViewerDomainName: 'http://localhost:8082', //로컬
    //sViewerDomainName: '172.30.1.23:8082', //로컬

    sNaverClientId: 'D8njzElrCMk2s_PdpEH3',

    sApiUrl,

    aRoutePermitted: [
        '/login',
        '/signup',
        '/forgotpassword',
        '/forgotpass',
        '/login_participant',
        '/get-box-token',
        '/templateDataView',
        '/templateDataPdfView',
        //'/template_editing',
    ],

    sUserName: '',
    sUserEmail: '',
    iUserId: -1,
    iRoleId: -1,
    sSendUrl: '',
    // sUserSnsType: '',

    aPage: [
        {
            title: '내 계약서함',
            path: '/my_document',
            desc: '서명 요청 및 진행 상태를 확인할 수 있으며, 계약서를 보관 및 관리할 수 있습니다.',
        },
        {
            title: '서명 참여자 설정',
            path: '/my_document/sign_participant',
            desc: '',
            child: true,
        },
        {
            title: '서명요청 인증 설정',
            path: '/my_document/sign_request_settings',
            desc: '',
            child: true,
        },
        {
            title: '제공 문서함',
            path: '/offer_template',
            desc: '제공하고 있는 문서를 템플릿으로 저장하여 필요에 따라 사용하실 수 있습니다.',
        },
        {
            title: '제공 문서함 - 서명참여자 설정',
            path: '/offer_template/sign_participant',
            desc: '제공문서함에 서명참여자를 설정합니다.',
            child: true,
        },
        {
            title: '제공 문서함 - 문서 수정',
            path: '/offer_template/offer_template_editing',
            desc: '',
            child: true,
        },
        {
            title: '제공 문서함 - 서명요청 인증 설정',
            path: '/offer_template/sign_request_settings',
            desc: '',
            child: true,
        },
        {
            title: '내 문서함',
            path: '/document_template',
            desc: '자주 사용하는 문서를 템플릿으로 저장하여 필요에 따라 사용하실 수 있습니다.',
        },
        {
            title: '문서 설정',
            path: '/document_template/document_template_creation',
            desc: '',
            child: true,
        },
        {
            title: '문서 수정',
            path: '/document_template/template_editing',
            desc: '',
            child: true,
        },
        {
            title: '문서 입력 설정',
            path: '/document_template/document_template_editing',
            desc: '',
            child: true,
        },
        {
            title: '문서 확인',
            path: '/document_template/document_template_view',
            desc: '',
            child: true,
        },
        {
            title: '문서 입력 설정',
            path: '/template_editing',
            desc: '',
            child: true,
        },
        {
            title: '데이터 업로드',
            path: '/data_upload',
            desc: '템플릿에 일괄적용 할 데이터를 업로드 합니다.',
        },
        /*
        {
            title: '계약서 대량 발송',
            path: '/esti_send',
            desc: '전달받은 데이터로 계약서를 만들어 계약서를 전송합니다.',
        },
        */
        {
            title: '사인 만들기',
            path: '/draw_sign',
            desc: '사인 또는 도장을 만들어서 관리할 수 있습니다.',
        },
    ],

    oPageTitle: {
        title: '계약서함',
        desc: '서명 요청 및 진행 상태를 확인할 수 있으며, 계약서를 보관 및 관리할 수 있습니다.',
    },

    // Document template variables
    iEditingDocId: -1,
    sEditingDocTemplateName: '',
    sEditingDocTemplateApiKey: '',
    sEditingDocTemplateApiPKey: '',
    sEditingDocTemplateApiCKey: '',

    sReferrerDocTempEdit: '',

    

    // Sign request editing
    iEditingSignReqId: -1,
    sEditingSignReqName: '',
    sEditingSignReqExpireDay: '',

    aSignRequestParticipant: [],

    bDialogRequestCompleteOpen: false,

    bClickedStart: false,

    iActiveDocuments: 0,

    //Offer Template
    iOfferDocId: -1,
    sOfferDocTemplateApiCKey: -1,
    aOfferSignRequestParticipant: [],
    sOfferTargetToken: '',
});

(async () => {
    let oHeader = {};
    if (token !== undefined) {
        oHeader = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
    }
    try {
        const res = await axios.create(oHeader).post(`/${sApiUrl}/user/checkuser`,{connectUrl : window.location.href});
        if (res.status === 200 && res.data.status === '000') {
            setGlobal({
                bAuth: res.data.entity.auth,
                iUserId: res.data.entity.user.userIdx,
                sUserName: res.data.entity.user.userName,
                sUserEmail: res.data.entity.user.userEmail,
                iRoleId: res.data.entity.user.roleIdx,
                // sUserSnsType: res.data.entity.user.socialType,
            });
        }
    } catch {
    }

    ReactDOM.render(<App />, document.getElementById('root'));    
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
})();

