import React from 'reactn';

import SignCreateDialogContentView1 from './SignCreateDialogContentView1';
import SignCreateDialogContentView2 from './SignCreateDialogContentView2';
import SignCreateDialogContentView3 from './SignCreateDialogContentView3';
import SignCreateDialogContentView4 from './SignCreateDialogContentView4';

const Entity = props => {
    return (
        <div>
            {props.iViewType === 1 && (
                <SignCreateDialogContentView1 setCanvasImg={props.setCanvasImg} />
            )}
            {props.iViewType === 2 && (
                <SignCreateDialogContentView2 iGeneratedImgId={props.iGeneratedImgId} setGeneratedImgId={props.setGeneratedImgId} />
            )}
            {props.iViewType === 3 && (
                <SignCreateDialogContentView3 setAttachment={props.setAttachment} />
            )}
            {props.iViewType === 4 && (
                <SignCreateDialogContentView4 />
            )}
        </div>
    );
};

export default Entity;