import React, { useState, Fragment, useGlobal, useEffect, getGlobal, setGlobal } from 'reactn';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";

import { toast_warning } from '../api/api';
import DialogParticipantAuth from '../component/DialogParticipantAuth';
import DialogSignRequestSummary from '../component/DialogSignRequestSummary';

import { navigatePage, ordinal_suffix_of,autoFormatKrNumber } from '../lib/lib';

const useStyles = makeStyles(theme => ({
    textfield_label: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    button_create_template: {
        width: '100%',
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_back: {
        width: '100%',
        marginRight: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_next: {
        width: '100%',
        marginLeft: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
    },
    button_edit_name: {
        width: '100%',
        // marginLeft: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));

function Entity(props) {

    const classes = useStyles();

    const [bLoading, setLoading] = React.useState(false);

    const [sNameTemp, setNameTemp] = useState(getGlobal().sEditingSignReqName);
    const [bEditingName, setEditingName] = useState(false);
    const [bDialogParticipantAuthOpen, setDialogParticipantAuthOpen] = useState(false);
    const [bDialogSummaryOpen, setDialogSummaryOpen] = useState(false);
    const [iEditingTargetId, setEditingTargetId] = useState(-1);
    
    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    
    const [aSignRequestParticipant, setSignRequestParticipant] = useGlobal('aSignRequestParticipant');
    const [iEditingSignReqId] = useGlobal('iEditingSignReqId');
    const [sEditingSignReqName, setName] = useGlobal('sEditingSignReqName');
    const [sEditingSignReqExpireDay, setDays] = useGlobal('sEditingSignReqExpireDay');
    const [sUserEmail] = useGlobal('sUserEmail');
    const [sAuthType, setAuthType] = useState([]); 
    const [iEditingDocId] = useGlobal('iEditingDocId');
    const [sEditingDocTemplateApiKey] = useGlobal('sEditingDocTemplateApiKey');

    useEffect(() => {
        console.log(aSignRequestParticipant);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    maxWidth: 500,
                    width: '100%',
                }}
            >
                <label htmlFor='field_name' className={classes.textfield_label}>서명 문서 이름</label>
                <TextField
                    id="field_name"
                    style={{
                        marginTop: 3,
                    }}
                    // placeholder="이름 또는 회사명을 입력해 주세요."
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        readOnly: !bEditingName,
                    }}
                    disabled={!bEditingName}
                    value={ (sNameTemp.length > 25 ? sNameTemp.substring(0,25)+'...' : sNameTemp)}
                    onChange={e => setNameTemp(e.target.value)}
                />
                {bEditingName && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: 20,
                            paddingBottom: 50,
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button_wizard_back}
                            onClick={() => {
                                setEditingName(false);
                                setNameTemp(sEditingSignReqName);
                            }}
                        >
                            취소
                        </Button>
                        <Button
                            color="secondary"
                            className={classes.button_wizard_next}
                            onClick={() => {
                                setEditingName(false);
                                setName(sNameTemp);
                            }}
                        >
                            확인
                        </Button>
                    </div>
                )}
                {!bEditingName && (
                    <Button
                        color="secondary"
                        className={classes.button_edit_name}
                        onClick={() => {
                            setEditingName(true);
                        }}
                    >
                        서명 문서 이름 수정
                    </Button>
                )}
                <label htmlFor='field_expire_days' className={classes.textfield_label}>서명 만료일 설정</label>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        id="field_expire_days"
                        style={{
                            marginTop: 3,
                        }}
                        // placeholder="이름 또는 회사명을 입력해 주세요."
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        //value={sEditingSignReqExpireDay ? sEditingSignReqExpireDay : '14'}
                        value={sEditingSignReqExpireDay}
                        onChange={e => setDays(e.target.value)}
                    />
                    <div style={{ marginLeft: 20, marginRight: 20 }}>일</div>
                </div>
                <p>* 서명 참여자는 요청 메시지를 받은 후 서명 만료일 내에 서명을 완료해야 합니다.</p>
                {aSignRequestParticipant.map((item, index) => (                    
                    <Fragment key={item.id}>
                        <Divider style={{
                            marginTop: 15,
                            marginBottom: 15,
                        }} />
                        <Typography variant='h5' style={{ marginBottom: 20, fontWeight: 'bold' }}>
                            {ordinal_suffix_of(index + 1)} 서명 참여자
                        </Typography>
                        <label htmlFor={`field_name_${item.id}`} className={classes.textfield_label}>이름 or 회사명</label>
                        <TextField
                            id={`field_name_${item.id}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이름 또는 회사명을 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetName}
                            disabled={true}
                            
                            InputProps={{
                                readOnly: item.targetName != null ? true : false,
                            }}
                            
                            onChange={e => { 
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetName: e.target.value,
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_phone_${item.id}`} className={classes.textfield_label}>핸드폰 번호</label>
                        <TextField
                            id={`field_phone_${item.id}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="핸드폰 번호를 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetPhone}
                            disabled={true}
                            
                            InputProps={{
                                readOnly: true,
                            }}
                            
                            onChange={e => { 
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetPhone: autoFormatKrNumber(e.target.value),
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_email_${item.id}`} className={classes.textfield_label}>이메일 주소</label>
                        <TextField
                            id={`field_email_${item.id}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이메일 주소를 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={item.targetEmail}
                            disabled={true}
                            
                            InputProps={{
                                readOnly: true,
                            }}
                            
                            onChange={e => { 
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetEmail: e.target.value,
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        
                        {/**
                        item.message === null && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.button_edit_name}
                                onClick={() => {
                                    setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                        if (index === iIndex) {
                                            return {
                                                ...oItem,
                                                message: '',
                                            };
                                        } else {
                                            return {...oItem};
                                        }
                                    }));
                                }}
                            >
                                + 메시지 입력
                            </Button>
                        )
                         */
                        }
                        {/**
                        item.message !== null && (
                            <Fragment>
                                <label htmlFor={`field_msg_${item.id}`} className={classes.textfield_label}>메시지 입력</label>
                                <TextField
                                    id={`field_msg_${item.id}`}
                                    style={{
                                        marginTop: 3,
                                    }}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    type="text"
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={item.message}
                                    onChange={e => {
                                        setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                            if (index === iIndex) {
                                                return {
                                                    ...oItem,
                                                    message: e.target.value,
                                                };
                                            } else {
                                                return {...oItem};
                                            }
                                        }));
                                    }}
                                />
                            </Fragment>
                        )
                         */}
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button_edit_name}
                            onClick={() => {
                                setEditingTargetId(item.id);
                                setDialogParticipantAuthOpen(true);
                            }}
                        >
                            + 본인인증 수단 설정
                        </Button>
                    </Fragment>
                ))}
                <Divider style={{
                    marginTop: 15,
                    marginBottom: 15,
                }} />
                <DialogParticipantAuth
                    open={bDialogParticipantAuthOpen}
                    targetId={iEditingTargetId}
                    bLoading={bLoading}
                    setLoading={setLoading}
                    onClose={() => {
                        setDialogParticipantAuthOpen(false);
                        setEditingTargetId(-1);
                    }}
                    onClickSetVerification={({ auth_type}) => {
                        if(auth_type == "01") {
                            aSignRequestParticipant.map((oItem, iIndex) => {
                                if(oItem.targetName == null) {
                                    alert("서명참여자 이름 또는 회사명을 입력해주세요.");
                                    return;
                                }
                                if(oItem.targetEmail == null) {
                                    alert("서명참여자 이메일 정보를 입력해주세요.");
                                    return;
                                }
                                const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
                                if (oItem.targetEmail.match(regExp) == null) {
                                    alert("서명참여자 이메일이 올바르지 않습니다.");
                                    return;
                                }
                            });
                        } else if(auth_type == "02") {
                            aSignRequestParticipant.map((oItem, iIndex) => {
                                if(oItem.targetName == null) {
                                    alert("서명참여자 이름 또는 회사명을 입력해주세요.");
                                    return;
                                }
                                if(oItem.targetPhone == null) {
                                    alert("서명참여자 핸드폰 정보를 입력해주세요.");
                                    return;
                                }
                                const rgEx = /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/
                                console.log(rgEx.test(oItem.targetPhone));
                                if (oItem.targetPhone.match(rgEx) == null) {
                                    alert("서명참여자 핸드폰 정보가 올바르지 않습니다.");
                                    return;
                                }
                            });
                        } else if(auth_type == "03") {
                            aSignRequestParticipant.map((oItem, iIndex) => {
                                if(oItem.targetName == null) {
                                    alert("서명참여자 이름 또는 회사명을 입력해주세요.");
                                    return;
                                }
                                if(oItem.targetPhone == null) {
                                    alert("서명참여자 핸드폰 정보를 입력해주세요.");
                                    return;
                                }
                                const rgEx = /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/
                                console.log(rgEx.test(oItem.targetPhone));
                                if (oItem.targetPhone.match(rgEx) == null) {
                                    alert("서명참여자 핸드폰 정보가 올바르지 않습니다.");
                                    return;
                                }
                            });
                        }

                        //setAuthType(auth_type);
                        setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {                            
                            if (oItem.id === iEditingTargetId) {
                                return {
                                    ...oItem,
                                    authType: auth_type,
                                };
                            } else {
                                return {...oItem};
                            }
                        }));

                        setDialogParticipantAuthOpen(false);
                      
                    }}
                />
                <DialogSignRequestSummary
                    open={bDialogSummaryOpen}
                    oSummary={{
                        name: sEditingSignReqName,
                        aTarget: aSignRequestParticipant,
                    }}
                    bLoading={bLoading}
                    onClose={() => {
                        if (!bLoading) {
                            setDialogSummaryOpen(false);
                        }
                    }}
                    onClickCreate={() => {
                        console.log(aSignRequestParticipant);                        
                        console.log(sEditingSignReqName);
                        console.log(sEditingSignReqExpireDay);
                        
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docSignRequest/requestSign`, {
                            templateName: sEditingSignReqName,
                            signExpired: sEditingSignReqExpireDay,                            
                            targetList: aSignRequestParticipant,
                            templateIdx: iEditingDocId,
                            pKey: sEditingDocTemplateApiKey
                        })
                            .then(res => {
                                // setLoading(false);
                                switch (res.data.status) {
                                    case '000':
                                        setDialogSummaryOpen(false);
                                        setLoading(false);
                                        navigatePage('/my_document', props);
                                        if (res.data.entity !== null && res.data.entity.docSignTargetList !== undefined && res.data.entity.docSignTargetList !== null) {
                                            if (res.data.entity.docSignTargetList.filter(item => item.targetEmail === sUserEmail).length > 0) {
                                                setGlobal({
                                                    bDialogRequestCompleteOpen: true,
                                                    sEditingDocTemplateApiKey: res.data.entity.pKey,
                                                    sEditingDocTemplateApiCKey: res.data.entity.cKey,
                                                });
                                            }
                                        }
                                        break;
                                    default:
                                        toast_warning(res.data.message);
                                }
                            }).catch(err => {
                                setLoading(false);
                                toast_warning(`System error: ${JSON.stringify(err)}`);
                            });
                    }}
                    // targetId={iEditingTargetId}
                    // bLoading={bLoading}
                    // setLoading={setLoading}
                    // onClickSetVerification={({ auth_type, password, name, phone }) => {
                    //     setLoading(true);
                    //     axios.create({
                    //         headers: {
                    //             Authorization: `Bearer ${sToken}`,
                    //         }
                    //     }).post(`/${sApiUrl}/docSignTargetAuth`, {
                    //         docSignTargetIdx: iEditingTargetId,
                    //         authType: auth_type,
                    //         authPwd: password,
                    //         authPhoneName: name,
                    //         authPhoneNum: phone,
                    //     })
                    //         .then(res => {
                    //             setLoading(false);
                    //             switch (res.data.status) {
                    //                 case '000':
                    //                     setDialogParticipantAuthOpen(false);
                    //                     setEditingTargetId(-1);
                    //                     break;
                    //                 default:
                    //                     toast_warning(res.data.message);
                    //             }
                    //         }).catch(err => {
                    //             // setLoading(false);
                    //             toast_warning(`System error: ${JSON.stringify(err)}`);
                    //         });
                    // }}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: 20,
                        paddingBottom: 50,
                    }}
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button_wizard_back}
                        onClick={() => {
                            setSignRequestParticipant([]);
                            navigatePage('/my_document/sign_participant', props);
                        }}
                    >
                        이전
                    </Button>
                    <Button
                        color="secondary"
                        className={classes.button_wizard_next}
                        onClick={() => {
                            if (sEditingSignReqName.length > 0) {
                                setDialogSummaryOpen(true);
                            } else {
                                alert('서명문서이름을 입력해주세요.');
                            }
                        }}
                    >
                        완료
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Entity;