import React, { useEffect, Fragment, useState, useGlobal } from 'reactn';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";

import { toast_success, toast_warning } from '../api/api';
import { navigatePage, ordinal_suffix_of } from '../lib/lib';

const useStyles = makeStyles(theme => ({
    textfield_label: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    button_create_template: {
        width: '100%',
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_back: {
        width: '100%',
        marginRight: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_next: {
        width: '100%',
        marginLeft: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));

const Entity = props => {
    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    const [sViewerDomainName] = useGlobal('sViewerDomainName');
    
    const [iEditingDocId] = useGlobal('iEditingDocId');
    const [sEditingDocTemplateApiKey] = useGlobal('sEditingDocTemplateApiKey');
    const [sEditingDocTemplateApiPKey] = useGlobal('sEditingDocTemplateApiPKey');
    const [sEditingDocTemplateApiCKey] = useGlobal('sEditingDocTemplateApiCKey');

    const [sReferrerDocTempEdit] = useGlobal('sReferrerDocTempEdit');
    const [sSendUrl] = useGlobal('sSendUrl');
    const [sDomainName] = useGlobal('sDomainName');
    
    const [bLoading, setLoading] = useState(false);
    const [oConfirmData, setConfirmData] = useState({});

    

    useEffect(() => {
        window.addEventListener("message", function(e) {
            setConfirmData(e.data);
          });
          
    }, [props]);

    useEffect(() => {
		if(oConfirmData.confirm) {
            if (sReferrerDocTempEdit === 'my_document') {
                navigatePage('/my_document', props);
            }
        }
	}, [oConfirmData]);

    return (
        <div
            style={{
                height: 'calc(100% - 90px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            }}
        >
            <iframe                
                //운영
                //src={`https://onlinesign.co.kr/viewer/pdfView.html?doc_data_id=${sEditingDocTemplateApiCKey}`}
                //개발
                //src={`http://pilot.onlinesign.co.kr/viewer/pdfView.html?doc_data_id=${sEditingDocTemplateApiCKey}`}
                //로컬
                src={`${sViewerDomainName}/viewer/pdfView.html?doc_data_id=${sEditingDocTemplateApiCKey}`}
                style={{
                    width: 'calc(100%)',
                    flex: 1,
                    border: 'none',
                    marginBottom : '30px',
                }}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        maxWidth: 500,
                        minWidth: 500,
                    }}
                >
                    {bLoading && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {/*!bLoading && (
                        <Fragment>
                            <Button
                                color="secondary"
                                className={classes.button_wizard_next}
                                onClick={() => {
                                    setLoading(true);
                                    axios.create().post(`/${sApiUrl}/docSignRequest/saveDocPDF`, {
                                        token : sSendUrl,
                                        pKey : sEditingDocTemplateApiPKey,
                                        cKey : sEditingDocTemplateApiCKey,
                                    }).then(res => {
                                        console.log(res.data.entity.fileName);
                                        console.log(res.data.entity.filePath);
                                        
                                        const link = document.createElement('a');

                                        link.href = `http://${sDomainName}/${sApiUrl}/docSignRequest/saveDocPDF/pdf?fileName=${res.data.entity.fileName}`;
                                        //link.href = `http://localhost:7070/${sApiUrl}/docSignRequest/saveDocPDF/pdf?fileName=${res.data.entity.fileName}`;

                                        link.type = "application/octet-stream";
                                        link.setAttribute('download', "test.pdf");
                                        document.body.appendChild(link);
                                        link.click();
                                        setLoading(false);
                                    }).catch(err => {
                                        setLoading(false);
                                        toast_warning(err.response.statusText);
                                    });
                                }}
                            >
                                문서다운로드
                            </Button>
                            <Button
                                color="secondary"
                                className={classes.button_wizard_next}
                                onClick={() => {
                                    if (sReferrerDocTempEdit === 'my_document') {
                                        navigatePage('/my_document', props);
                                    }
                                }}
                            >
                                확인
                            </Button>
                        </Fragment>
                            )*/}
                </div>
            </div>
        </div>
    );
};

export default Entity;