import React, { Fragment, useRef, useState } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1.4em' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
}))(MuiDialogActions);

const Dialog = withStyles(theme => ({
    paper: {
        width: '100%',
        maxWidth: 768,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    contentSection1: {
        textAlign: 'center',
        width: '20%',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 10,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            borderRight: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'block',
            paddingRight: 0,
            paddingBottom: 20,
        },
    },
    contentSection2: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    filelistContainer: {
        height: '100%',
        width: '100%',
        // backgroundColor: 'green',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 20,
        },
    },
    button_provider: {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'none',
        width: '100%',
        minWidth: '46%',
        height: 56,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 2,
        marginRight: 2,
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        borderRadius: 56,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        [theme.breakpoints.down('xs')]: {
            width: '49%',
        },
    },
    button_provider_active: {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'none',
        width: '100%',
        minWidth: '46%',
        height: 56,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 2,
        marginRight: 2,
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        borderRadius: 56,
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        [theme.breakpoints.down('xs')]: {
            width: '49%',
        },
    },
    button_wizard_next: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    inputFile: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        borderRadius: 6,
        padding: 20,
        paddingTop: 6,
        paddingBottom: 6,
        width: '80%',
        marginTop: 20,
        cursor: 'pointer',
    },
}));

function Entity(props) {
    const classes = useStyles();

    const inputFile = useRef(null);
    const [sInputFileName, setInputFileName] = useState('');
    
    return (
        <Fragment>
            <Dialog
                onClose={props.onClose}
                aria-labelledby="fileuploader-dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogTitle id="fileuploader-dialog-title" onClose={props.onClose}>
                    문서 업로드
                </DialogTitle>
                <DialogContent dividers>
                    <div
                        className={classes.contentSection2}
                    >
                        <Fragment>
                            <Typography style={{ fontSize: '1.4em', textAlign: 'center' }}>
                                내 컴퓨터에서 업로드 할 문서를 선택해 주세요.
                            </Typography>
                            <input
                                type="file"
                                ref={inputFile}
                                // className={classes.inputFile}
                                id="computer_file_upload_input"
                                style={{
                                    display: 'none',
                                }}
                                onChange={() => {
                                    if (inputFile.current.files.length > 0) {
                                        setInputFileName(inputFile.current.files[0].name);
                                    } else {
                                        setInputFileName('');
                                    }
                                }}
                            />
                            <label
                                htmlFor="computer_file_upload_input"
                                className={classes.inputFile}
                            >
                                {(sInputFileName !== '') ? sInputFileName : '파일찾기'}
                            </label>
                        </Fragment>
                    </div>
                </DialogContent>
                <DialogActions>
                    {props.bLoading && (
                        <div
                            style={{
                                // width: '100%',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!props.bLoading && (
                        <Button
                            color="secondary"
                            className={classes.button_wizard_next}
                            onClick={() => {
                                if (inputFile.current !== null && inputFile.current.files.length > 0) {
                                    props.nextStep({ type: 'computer', file: inputFile.current.files[0] });
                                }
                            }}
                        >
                            업로드
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default Entity;