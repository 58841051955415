import React, { setGlobal, useEffect, useGlobal, useState, useRef } from 'reactn';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import IconDelete from '@material-ui/icons/Delete';
// import IconCheck from '@material-ui/icons/Check';
import IconClose from '@material-ui/icons/Close';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconEdit from '@material-ui/icons/Edit';
import IconSend from '@material-ui/icons/Send';
import axios from "axios";
// import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingOverlay from 'react-loading-overlay';

import Pagination from '../component/Pagination';
import TemplateSearch from '../component/TemplateSearch'
// import TemplateView from '../component/TemplateView'
import DialogParticipantAuth from '../component/DialogParticipantAuth';

import { toast_success, toast_warning } from '../api/api';
import { navigatePage, useOnClickOutside } from '../lib/lib';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
    { id: 'template_name', numeric: false, disablePadding: false, label: '템플릿 이름' },
    { id: 'created_date', numeric: true, disablePadding: false, label: '생성시간' },
    { id: 'updated_date', numeric: true, disablePadding: false, label: '업데이트 시간' },
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    formControl: {
		margin: theme.spacing(1),
		minWidth: 150,
        maxWidth: 150,
        height: 30
    },
    /*
    Select : {
        maxHeight: 40,
    },
    */
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'scroll',
        [theme.breakpoints.up('sm')]: {
            overflowX: 'unset',
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableToolbar: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    button_create_template: {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
    },
    searchPaper: {
        border: '1px solid #ccc',
        boxShadow: 'none',
        display: 'flex',
        transition: 'all .4s',
        width: '70%',
        '&:hover, &:focus-within': {
            width: '100%',
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 7,
        color: theme.palette.secondary.main,
    },
    iconButtonDel: {
        padding: 7,
        color: '#999',
    },
    tableRowTemplateName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: 300,
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 440,
        },
    },
}));

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    
    return (
        <TableHead>            
            <TableRow>
                {headCells.length === 0 ? <TableCell></TableCell> :             
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount && rowCount > 0}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                }
                {headCells.map(headCell => (                    
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headCell.numeric ? { width: 150, whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                fontSize: '1.4em',
                            }}
                        >
                            {headCell.label}
                            {/* {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null} */}
                        </TableSortLabel>
                    </TableCell>
                ))}          
                {/*headCells.length === 0 ? <TableCell></TableCell> :                  
                    <TableCell
                        key='view'
                        align='center'
                        padding='default'
                        sortDirection={false}
                        style= { {width: 150, whiteSpace: 'nowrap', fontSize: '1.4em',} }       
                    >             
                        <TableSortLabel
                        active={false}
                        direction={order}
                        //onClick={}
                        style={{
                            fontSize: '1.4em',
                        }}
                        >
                        view            
                        </TableSortLabel>                              
                    </TableCell>
                    */}        
            </TableRow>            
        </TableHead>
        
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const StyledButton = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        width: 190,
        height: 50,
        border: 'none',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '1.2em',
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}))(Button);



function Entity(props) {
    const classes = useStyles();

    const [selectedTemplate, setSelectedTemplate] = useState([]); 
	
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);

	const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('update_time');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);

	const [bLoading, setLoading] = React.useState(false);

	const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    
    const [bFileUploaderOpen, setFileUploaderOpen] = React.useState(false);
    //const [bTemplateViewOpen, setTemplateViewOpen] = React.useState(false);
    const [bDialogParticipantAuthOpen, setDialogParticipantAuthOpen] = useState(false);
    

	const updateTableData = sIdx => {
		let sUrl = `/${sApiUrl}/docDataApi/searchTemplate?templateIdx=${sIdx}`;
		setLoading(true);
		axios.create({
			headers: {
				Authorization: `Bearer ${sToken}`,
			}
		}).get(sUrl)
		.then(res => {
			setLoading(false);
			switch (res.data.status) {
				case '000':                    
					if (res.data.entity !== undefined && res.data.entity !== null) {
                        console.log(res.data);
						const aColumn = [];
                        const aRow = [];
						if (res.data.entity != null) {         
                            const oEntity = res.data.entity;
                            oEntity.headList.forEach((item, index) => {
                                if (index === 0) {
                                    aColumn.push({ id: 'N', numeric: false, disablePadding: false, label: 'N' });
                                }
                                
                                aColumn.push({ id: item.COL, numeric: false, disablePadding: false, label: item.COL_NAME });
                            });
                            //rowNum
                            const rCnt = oEntity.bodyList.length / oEntity.headList.length; 
                            let maxDocNo = 0;
                            let docNoArr = [];
                            oEntity.bodyList.forEach((bodyItem, index) => {     
                                let docNo = bodyItem.DOC_NO;
                                if(index == 0) {
                                    docNoArr.push(docNo);
                                } else {
                                    if(docNoArr.indexOf(docNo) < 0) {
                                        docNoArr.push(docNo);
                                    }
                                }
                                if(parseInt(maxDocNo) < parseInt(docNo) ) {
                                    maxDocNo = docNo;
                                }
                            });
                            for(let i=0; i<docNoArr.length; i++) {
                                
                                const oSet = {
                                    id: docNoArr[i],
                                };
                                oEntity.bodyList.forEach((bodyItem, index) => {      
                                    if( bodyItem.DOC_NO == docNoArr[i] ) {
                                        oSet['N'] = bodyItem.DOC_NO;    
                                        oSet[bodyItem.COL] = bodyItem.COL_VALUE;
                                    }
                                });
                                aRow.push(oSet);
                            }
                        }
                        
                        setColumns(aColumn);
						setRows(aRow);
					} else {
                    }
                    setFileUploaderOpen(false);
					break;
				default:
					toast_warning(res.data.message);
			}
		}).catch(err => {
			setLoading(false);
			toast_warning(`System error: ${JSON.stringify(err)}`);
		});
	};
    /*
	const handleChange = event => {
		setSelectedTemplate(event.target.value);
		updateTableData(event.target.value);
	};
    */
	const isSelected = id => selected.indexOf(id) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
	
	function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {        
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);            
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }
    /*
    function handleChangePage(newPage) {
        setPage(newPage);
    }
    */
    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <div className={classes.tableWrapper}>
                <div className={classes.tableToolbar}>
                    <div style={{display: 'inline-block', float: 'left', width: '70%'}}>
                        <div style={{ display: 'inline-block', marginRight: 10 }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.button_create_template}
                                onClick={() => {
                                    setFileUploaderOpen(true);
                                    // props.history.push('/document_template/document_template_creation');
                                }}
                            >
                            템플릿 가져오기
                            </Button>
                        </div>       
                        <div style={{ display: 'inline-block', marginRight: 10}}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.button_create_template}
                                onClick={() => {
                                    if(selected.length == 0) {
                                        toast_warning(`발송할 데이터를 선택해주세요.`);
                                        return;
                                    }

                                    setDialogParticipantAuthOpen(true);
                                    /*
                                    const chkData = [];
                                    rows.forEach((item, index) => {
                                        const dataArr = new Object();
                                        columns.forEach((headitem, hindex) => {
                                            let colId = headitem.id;
                                            dataArr[colId] = item[colId];
                                        });
                                        if(selected.indexOf(dataArr["N"]) > -1) {
                                            chkData.push(dataArr);
                                        }
                                    });
                                    console.log(chkData);
                                    
                                    setLoading(true);
                                    axios.create({
                                        headers: {
                                            Authorization: `Bearer ${sToken}`,
                                        }
                                    }).post(`/${sApiUrl}/docDataApi/estiSend`, {
                                        templateIdx: selectedTemplate,                                        
                                        dataList: chkData,                                                                                
                                        signExpired: '14',
                                        messageList: []
                                    })
                                        .then(res => {
                                            // setLoading(false);
                                            switch (res.data.status) {                                                case '000':
                                                    
                                                    setLoading(false);
                                                    toast_success("발송되었습니다.");   
                                                    setSelectedTemplate(selectedTemplate);
                                                    updateTableData(selectedTemplate);                                                 
                                                    break;
                                                default:
                                                    toast_warning(res.data.message);
                                            }
                                        }).catch(err => {
                                            setLoading(false);
                                            toast_warning(`System error: ${JSON.stringify(err)}`);
                                        });
                                    */
                                }}
                            >
                                발송
                            </Button>
                        </div>                                  
                        <div style={{ display: 'inline-block' }}>                                    
                            <TemplateSearch
                                open={bFileUploaderOpen}
                                bLoading={bLoading}
                                onClose={() => setFileUploaderOpen(false)}
                                setDocument={par => {                                    
                                        setSelectedTemplate(par);
                                        updateTableData(par);
                                    }
                                }   
                            />
                        </div>    
                        
                    </div> 
                </div>
                <div
                    style={{
                        marginTop: 30,
                    }}
                >
                    <LoadingOverlay
                        active={bLoading}
                        spinner
                        text='Loading...'
                        styles={{
                            overlay: {
                                position: 'fixed',
                                height: '100vh',
                                width: '100%',
                                top: 0,
                                left: 0,
                                display: 'flex',
                                textAlign: 'center',
                                fontSize: '1.2em',
                                color: '#FFF',
                                background: 'rgba(0,0,0,0.7)',
                                zIndex: 800,
                                transition: 'opacity 500ms ease-in',
                                opacity: 1,
                            },
                            // wrapper: {},
                            // content: {},
                            // spinner: {}
                        }}
                    >
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                headCells={columns}
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                
                                {stableSort(rows, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >                                                
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        onChange={event => handleClick(event, row.id)}
                                                    />
                                                </TableCell>
                                                {columns.map(column => {
                                                    return (
                                                        <TableCell
                                                            key={`${row.id}-${column.id}`}
                                                            align="left"
                                                        >
                                                            {row[column.id]}
                                                        </TableCell>
                                                    );
                                                })}

                                                {/*<TableCell padding="default" align="center"> 
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        size="medium" 
                                                        onClick={() => {
                                                            setTemplateViewOpen(true);
                                                        }}

                                                    >
                                                        보기
                                                    </Button>
                                                </TableCell>
                                                */
                                                }
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && rows.length > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </LoadingOverlay>
                    
                    
                </div>
                <DialogParticipantAuth
                    open={bDialogParticipantAuthOpen}
                    bLoading={bLoading}
                    setLoading={setLoading}
                    onClose={() => {
                        setDialogParticipantAuthOpen(false);
                    }}
                    onClickSetVerification={({ auth_type}) => {
                        //setAuthType(auth_type);
                        console.log(auth_type);
                        
                        const chkData = [];
                        rows.forEach((item, index) => {
                            const dataArr = new Object();
                            columns.forEach((headitem, hindex) => {
                                let colId = headitem.id;
                                dataArr[colId] = item[colId];
                            });
                            if(selected.indexOf(dataArr["N"]) > -1) {
                                chkData.push(dataArr);
                            }
                        });
                        console.log(chkData);
                        
                        setLoading(true);
                        axios.create({
                            headers: {
                                Authorization: `Bearer ${sToken}`,
                            }
                        }).post(`/${sApiUrl}/docDataApi/estiSend`, {
                            templateIdx: selectedTemplate,                                        
                            dataList: chkData,                                                                                
                            signExpired: '14',
                            messageList: [],
                            authType : auth_type
                        })
                            .then(res => {
                                // setLoading(false);
                                switch (res.data.status) {                                                case '000':
                                        
                                        setLoading(false);
                                        toast_success("발송되었습니다.");   
                                        setSelectedTemplate(selectedTemplate);
                                        updateTableData(selectedTemplate);                                                 
                                        break;
                                    default:
                                        toast_warning(res.data.message);
                                }
                            }).catch(err => {
                                setLoading(false);
                                toast_warning(`System error: ${JSON.stringify(err)}`);
                            });
                        setDialogParticipantAuthOpen(false);
                    }}
                />
            </div>
        </div>  
    );
}
/*
<div style={{ display: 'inline-block' }}>                                    
                            <TemplateView
                                open={bFileUploaderOpen}
                                bLoading={bLoading}
                                onClose={() => setTemplateViewOpen(false)}
                                
                                //onCreate={this.onSearchSubmit}
                            />
                        </div>     
                        */
export default Entity;