import React, { useEffect, useGlobal, useState } from 'reactn';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import IconDelete from '@material-ui/icons/Delete';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
//HYD ADD
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import LoadingOverlay from 'react-loading-overlay';

import axios from "axios";

import { toast_success, toast_warning } from '../api/api';
import DataUploader from '../component/DataUploader';
import DialogConfirm from '../component/DialogConfirm';

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 400,
		maxWidth: 400,
	},
	formControlBtn: {
		margin: theme.spacing(1),
		minWidth: 120,
		borderWidth: 2,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
		},
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	table: {
        minWidth: 750,
	},
	tab: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
}));

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headCell.numeric ? { width: 150, whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                fontSize: '1.4em',
                            }}
                        >
                            {headCell.label}
                            {/* {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null} */}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
	headCells: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// const headCells = [
//     { id: 'status', numeric: false, disablePadding: false, label: '상태' },
//     { id: 'doc_name', numeric: false, disablePadding: false, label: '문서 이름' },
//     { id: 'participant', numeric: false, disablePadding: false, label: '서명 참여자' },
//     { id: 'folder', numeric: false, disablePadding: false, label: '폴더' },
//     { id: 'update_time', numeric: true, disablePadding: false, label: '업데이트 시간' },
// ];

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <Typography
		component="div"
		role="tabpanel"
		hidden={value !== index}
		id={`nav-tabpanel-${index}`}
		aria-labelledby={`nav-tab-${index}`}
		{...other}
	  >
		{value === index && <Box p={3}>{children}</Box>}
	  </Typography>
	);
}
  
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	};
}

function LinkTab(props) {
	return (
	  <Tab
		component="a"
		onClick={event => {
		  event.preventDefault();
		}}
		{...props}
	  />
	);
  }


function Entity() {

	const classes = useStyles();

	const [iSelectedTemplate, setSelectedTemplate] = React.useState(-1);
	const [aTemplate, setTemplate] = React.useState([]);
	const [aTemplateComponent, setTemplateComponent] = React.useState([]);
	
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);

	const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('update_time');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);

	const [bLoading, setLoading] = React.useState(false);

	const [sToken] = useGlobal('sToken');
	const [sApiUrl] = useGlobal('sApiUrl');

	const [bFileUploaderOpen, setFileUploaderOpen] = React.useState(false);
	const [sTabValue, setTabValue] = React.useState(0);
	const [bSaveDisabled, setSaveDisabled] = React.useState(true);
	const [bSendDisabled, setSendDisabled] = React.useState(true);
	const [bConfirmOpen, setConfirmOpen] = React.useState(false);
	const [sTitle, setTitle] = React.useState('');
	const [sContent, setContent] = React.useState('');
	

	useEffect(() => {
		let sUrl = `/${sApiUrl}/docTemplate`;
		setLoading(true);
		axios.create({
			headers: {
				Authorization: `Bearer ${sToken}`,
			}
		}).get(sUrl)
			.then(res => {
				setLoading(false);
				switch (res.data.status) {
					case '000':
						if (res.data.entity !== undefined && res.data.entity !== null) {
							setTemplate(res.data.entity.map(item => {
								let sCreatedDate = '';
								if (item.regDate !== null) {
									const oDate = new Date(item.regDate);
									sCreatedDate = oDate.toDateString();
								}
								let sUpdatedDate = '';
								if (item.updDate !== null) {
									const oUpdDate = new Date(item.updDate);
									sUpdatedDate = oUpdDate.toDateString();
								}
								return {
									id: item.templateIdx,
									template_name: item.templateName,
									apiKey: item.apiKey,
									created_date: sCreatedDate,
									updated_date: sUpdatedDate,
								};
							}));
						} else {
							setTemplate([]);
						}
						break;
					default:
						toast_warning(res.data.message);
				}
			}).catch(err => {
				setLoading(false);
				toast_warning(`System error: ${JSON.stringify(err)}`);
			});
	}, [sApiUrl, sToken]);

	const updateTableData = sIdx => {
		let sUrl = `/${sApiUrl}/docDataUpload/findByTemplateIdx?templateIdx=${sIdx}`;
		setLoading(true);
		axios.create({
			headers: {
				Authorization: `Bearer ${sToken}`,
			}
		}).get(sUrl)
		.then(res => {
			setLoading(false);
			switch (res.data.status) {
				case '000':
					if (res.data.entity !== undefined && res.data.entity !== null) {
						// setTemplateComponent(res.data.entity);
						const aColumn = [];
						const aRow = [];
						if (res.data.entity.length > 0) {
							const oEntity = res.data.entity[0];
							console.log(oEntity);
							if (oEntity.docList !== undefined && oEntity.docList !== null) {
								oEntity.docList.forEach((item, index) => {
									const oSet = {
										id: item.docNo,
									};
									if (index === 0) {
										aColumn.push({ id: 'N', numeric: false, disablePadding: false, label: 'N' });
										item.componentList.forEach(oComp => {
											aColumn.push({ id: oComp.col, numeric: false, disablePadding: false, label: oComp.colName });
										});
									}
									oSet['N'] = item.docNo;
									item.componentList.forEach(oComp => {
										oSet[oComp.col] = oComp.colValue;
									});
									aRow.push(oSet);
								});
								
							}
						}
						setColumns(aColumn);
						// console.log(aColumn);
						setRows(aRow);
						if(aRow.length > 0) {
							setSaveDisabled(false);
						} else {
							setSaveDisabled(true);
							setSendDisabled(true);	
						}
						// console.log(aRow);
						
					} else {
						setSaveDisabled(true);
						setSendDisabled(true);
					}
					break;
				default:
					toast_warning(res.data.message);
			}
		}).catch(err => {
			setLoading(false);
			toast_warning(`System error: ${JSON.stringify(err)}`);
		});
	};

	const handleChange = event => {
		setSelectedTemplate(event.target.value);
		updateTableData(event.target.value);
	};

	const tabHandleChange = (event, newValue) => {
		setTabValue(newValue);
	  };

	const callbackFileUpload = res => {
        setLoading(false);
        switch (res.data.status) {
            case '000':
				toast_success('Uploaded');
				setFileUploaderOpen(false);
				if (iSelectedTemplate !== -1) {
					updateTableData(iSelectedTemplate);
				}
                break;
            default:
                toast_warning(res.data.message);
        }
    };

    const callbackFileUploadCatch = err => {
        setLoading(false);
        toast_warning(`System error: ${JSON.stringify(err)}`);
	}
	
	const isSelected = id => selected.indexOf(id) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
	
	function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    function handleChangePage(newPage) {
        setPage(newPage);
	}
	
	const sendSign = () => {
		setLoading(true);
		if (iSelectedTemplate !== -1) {
			// /onlinesign/docDataUpload/sendRequest/256
			axios.create({
				headers: {
					Authorization: `Bearer ${sToken}`,
				}
			}).post(`/${sApiUrl}/docDataUpload/sendRequest/${iSelectedTemplate}`)
				.then(res => {
					setLoading(false);
					switch (res.data.status) {
						case '000':
							toast_success('서명요청 완료되었습니다.');
							break;
						default:
							toast_warning(res.data.message);
					}
				}).catch(err => {
					setLoading(false);
					toast_warning(`System error: ${JSON.stringify(err)}`);
				});
		}
	}

	return (
		<div className={classes.tab}>
			<AppBar position="static">
				<Tabs
					variant="standard"
					value={sTabValue}
					onChange={tabHandleChange}
					aria-label="nav tabs example"
					
				>
					<Tab label="업로드 양식" {...a11yProps(0)} />
          			<Tab label="엑셀업로드" {...a11yProps(1)} />				
				</Tabs>
			</AppBar>
			<TabPanel value={sTabValue} index={0} >
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'flex-end',
						}}
					>
						<FormControl className={classes.formControl}>
							<InputLabel htmlFor="template-selector">템플릿 선택</InputLabel>
							<Select
								inputProps={{
									name: 'template-selector',
									id: 'template-selector-id',
								}}
								value={iSelectedTemplate}
								onChange={handleChange}
							>
								{aTemplate.map(item => (
									<MenuItem key={item.id} value={item.id}>
										{item.template_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Button
							className={classes.formControlBtn}
							variant="outlined"
							color="secondary"
							onClick={() => {
								if (iSelectedTemplate !== -1) {
									let sUrl = `/${sApiUrl}/docDataUpload/downloadXlsTemplate?templateIdx=${iSelectedTemplate}`;
									// let sUrl = `/${sApiUrl}/docDataUpload/downloadXlsTemplate?templateIdx=216`;
									setLoading(true);
									axios.create({
										headers: {
											Authorization: `Bearer ${sToken}`,
										},
										responseType: 'blob',
									}).get(sUrl)
									.then(res => {
										// console.log(typeof(res.headers['content-type']));
										// console.log(res.headers['content-type'].trim());
										setLoading(false);
										if (res.headers && typeof(res.headers['content-type']) === 'string') {
											if (res.headers['content-type'].trim().startsWith('application\/json')) {
												// console.log(res.data);
												const reader = new FileReader();
												reader.addEventListener('loadend', e => {
													const data = JSON.parse(e.srcElement.result);
													switch (data.status) {
														case '000':
															break;
														default:
															toast_warning(data.message);
													}
												});
												reader.readAsText(res.data);
											}
											if (res.headers['content-type'].trim().startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
												let sFilename = 'data-template.xls';
												// content-disposition: attachment; filename=doc_data_upload_216.xls
												if (res.headers['content-disposition'] && typeof(res.headers['content-disposition']) === 'string' && res.headers['content-disposition'].trim().startsWith('attachment')) {
													// console.log(res.headers['content-disposition'].trim().split('='));
													sFilename = res.headers['content-disposition'].trim().split('=')[1];
												}
												console.log(res.data);
												const url = window.URL.createObjectURL(new Blob([res.data]));
												const link = document.createElement('a');
												link.href = url;
												link.setAttribute('download', sFilename);
												document.body.appendChild(link);
												link.click();
											}
										}
									}).catch(err => {
										setLoading(false);
										toast_warning(`System error: ${JSON.stringify(err)}`);
									});
								}
							}}
						>
							업로드 양식 다운로드
						</Button>
					</div>
				</div>
			</TabPanel>
			<TabPanel value={sTabValue} index={1}>
			<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'flex-end',
						}}
					>
						<FormControl className={classes.formControl}>
							<InputLabel htmlFor="template-selector">템플릿 선택</InputLabel>
							<Select
								inputProps={{
									name: 'template-selector',
									id: 'template-selector-id',
								}}
								value={iSelectedTemplate}
								onChange={handleChange}
							>
								{aTemplate.map(item => (
									<MenuItem key={item.id} value={item.id}>
										{item.template_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Button
							className={classes.formControlBtn}
							variant="outlined"
							color="secondary"
							onClick={() => {
								if (iSelectedTemplate !== -1) {
									setFileUploaderOpen(true);
								}
							}}
						>
							엑셀 업로드
						</Button>
						<DataUploader
							open={bFileUploaderOpen}
							bLoading={bLoading}
							onClose={() => setFileUploaderOpen(false)}
							nextStep={par => {
								if (par.type === 'computer') {
									// /onlinesign/docDataUpload/upload
									const data = new FormData();
									data.append('templateIdx', iSelectedTemplate);
									data.append('file', par.file);
									setLoading(true);
									axios.create({
										headers: {
											Authorization: `Bearer ${sToken}`,
										}
									}).post(`/${sApiUrl}/docDataUpload/upload`, data)
										.then(callbackFileUpload).catch(callbackFileUploadCatch);
								}
							}}
						/>
					</div>
					<div>
						<Button
							className={classes.formControlBtn}
							variant="outlined"
							color="secondary"
							disabled={bSaveDisabled}
							onClick={() => {
								setLoading(true);
								if (iSelectedTemplate !== -1) {
									// /onlinesign/docDataUpload/save
									axios.create({
										headers: {
											Authorization: `Bearer ${sToken}`,
										}
									}).post(`/${sApiUrl}/docDataUpload/save`, {
										templateIdx: iSelectedTemplate,
									})
										.then(res => {
											setLoading(false);
											switch (res.data.status) {
												case '000':
													toast_success('저장되었습니다.');
													setSendDisabled(false);
													break;
												default:
													toast_warning(res.data.message);
													setSendDisabled(true);
											}
										}).catch(err => {
											setLoading(false);
											toast_warning(`System error: ${JSON.stringify(err)}`);
										});
								}
							}}
						>
							저 장
						</Button>
						<Button
							className={classes.formControlBtn}
							variant="outlined"
							color="secondary"
							disabled={bSendDisabled}
							onClick={() => {
								setTitle('서명요청하기');
								setContent('서명요청 하시겠습니까?');
								setConfirmOpen(true);
							}}
						>
							서명 요청
						</Button>
					</div>
				</div>
				<div
					style={{
						marginTop: 30,
					}}
				>
					<LoadingOverlay
						active={bLoading}
						spinner
						text='Loading...'
						styles={{
							overlay: {
								position: 'fixed',
								height: '100vh',
								width: '100%',
								top: 0,
								left: 0,
								display: 'flex',
								textAlign: 'center',
								fontSize: '1.2em',
								color: '#FFF',
								background: 'rgba(0,0,0,0.7)',
								zIndex: 800,
								transition: 'opacity 500ms ease-in',
								opacity: 1,
							},
							// wrapper: {},
							// content: {},
							// spinner: {}
						}}
					>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							size={'medium'}
						>
							<EnhancedTableHead
								headCells={columns}
								classes={classes}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
							/>
							<TableBody>
								{stableSort(rows, getSorting(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = isSelected(row.id);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}
											>
												{columns.map(column => {
													return (
														<TableCell
															key={`${row.id}-${column.id}`}
															align="left"
														>
															{row[column.id]}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								{emptyRows > 0 && rows.length > 0 && (
									<TableRow style={{ height: 49 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</LoadingOverlay>
				</div>
			</TabPanel>	
			<DialogConfirm
                open={bConfirmOpen}
                bLoading={bLoading}
                title={sTitle}
                content={sContent}
                onClose={() => {
                    setConfirmOpen(false);
                }}
                onClickCancel={() => {
                    setConfirmOpen(false);
                }}
                onClickConfirm={() => {
					setConfirmOpen(false);
					sendSign();
                }}
            />		
		</div>
	);
}

export default Entity;