import React, { useEffect, Fragment, useState, useGlobal } from 'reactn';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";

import { toast_success, toast_warning } from '../api/api';
import { navigatePage, ordinal_suffix_of } from '../lib/lib';

const useStyles = makeStyles(theme => ({
    textfield_label: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    button_create_template: {
        width: '100%',
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_back: {
        width: '100%',
        marginRight: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_next: {
        width: '100%',
        marginLeft: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));

const Entity = props => {
    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');

    const [iEditingDocId] = useGlobal('iEditingDocId');
    const [sEditingDocTemplateApiKey] = useGlobal('sEditingDocTemplateApiKey');
    const [sEditingDocTemplateApiPKey] = useGlobal('sEditingDocTemplateApiPKey');
    const [sEditingDocTemplateApiCKey] = useGlobal('sEditingDocTemplateApiCKey');

    const [sReferrerDocTempEdit] = useGlobal('sReferrerDocTempEdit');
    const [sSendUrl] = useGlobal('sSendUrl');
    const [sDomainName] = useGlobal('sDomainName');
    
    const [bLoading, setLoading] = useState(false);
    const [oConfirmData, setConfirmData] = useState({});

    

    useEffect(() => {
        window.addEventListener("message", function(e) {
            setConfirmData(e.data);
          });
          
    }, [props]);

    useEffect(() => {
        if(oConfirmData.confirm) {
            if (sReferrerDocTempEdit === 'my_document') {
                navigatePage('/my_document', props);
            }
        }
    }, [oConfirmData]);

    return (
        <div
            style={{
                height: 'calc(100% - 90px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            }}
        >
            <iframe                
                //운영
                src={`https://onlinesign.co.kr/intro/tutoria}`}
                //로컬
                //src={`http://localhost:8082/intro/tutorial`}
                framebolder="0" 
                style=""
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1050,
                    height: 650,
                    border: 0,
                    borderRadius: 10,
                }}
            />
        </div>
    );
};

export default Entity;