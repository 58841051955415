import React, { Fragment, useEffect, useState, useGlobal } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from '@material-ui/core/Divider';
import axios from "axios";

import { toast_warning } from '../api/api';

import { autoFormatKrNumber } from '../lib/lib';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1.4em' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        textAlign: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
}))(MuiDialogActions);

const Dialog = withStyles(theme => ({
    paper: {
        width: '80%',
        minWidth: 700,
        minHeight: 250,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 4,
        //     marginRight: 4,
        // },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    button_sign_request: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    button_cancel: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        // color: 'white',
        // backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            // color: 'white',
            // backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    dialogAction: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 140,
        },
    },
    smsTextfield: {
        marginBottom: 10,
    },
}));

function Entity(props) {

    const classes = useStyles();

    const [sAuthType, setAuthType] = useState('password');
    const [bAuthPasswordSet, setAuthPasswordSet] = useState(false);
    const [sPassword, setPassword] = useState('');
    const [bShowPassword, setShowPassword] = useState(false);
    const [sSmsName, setSmsName] = useState('');
    const [sSmsPhone, setSmsPhone] = useState('');

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');

    const [bLoading, setLoading] = React.useState(false);

    

    return (
        <Fragment>
            <Dialog
                onClose={props.onClose}
                aria-labelledby="dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogTitle id="fileuploader-dialog-title" onClose={props.onClose}>
                    본인인증 수단 설정
                </DialogTitle>
                <DialogContent dividers>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 50,
                            height: '9vh',
                        }}
                    >
                        {bLoading && (
                            <div
                                style={{
                                    // width: '100%',
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}
                            >
                                <CircularProgress disableShrink />
                            </div>
                        )}
                        {!bLoading && (
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                style={{
                                    width: '90%',
                                }}
                                value={sAuthType}
                                onChange={event => setAuthType(event.target.value)}
                            >   
                                <div
                                    style={{
                                        width : '32%',
                                        paddingLeft : '8%',
                                    }}
                                >
                                    <FormControlLabel
                                        value="password"
                                        control={<Radio color="secondary" />}
                                        label="이메일 인증"
                                        labelPlacement="end"
                                    />
                                </div>
                                <div
                                    style={{
                                        width : '32%',
                                        paddingLeft : '8%',
                                    }}
                                >
                                    <FormControlLabel
                                        value="sms"
                                        control={<Radio color="secondary" />}
                                        label="SMS 인증"
                                        labelPlacement="end"
                                    />
                                </div>
                                <div
                                    style={{
                                        width : '32%',
                                        paddingLeft : '4%',
                                    }}
                                >
                                    <FormControlLabel
                                        value="kmc"
                                        control={<Radio color="secondary" />}
                                        label="휴대폰 본인 인증"
                                        labelPlacement="end"
                                    />
                                </div>
                            </RadioGroup>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    {props.bLoading && (
                        <div
                            style={{
                                // width: '100%',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!props.bLoading && (
                        <Fragment>
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.button_sign_request}
                                onClick={() => {
                                    let type = '';
                                    if(sAuthType === 'password') {
                                        type = '01';
                                    } else if(sAuthType === 'sms') {
                                        type = '02';
                                    } else {
                                        type = '03';
                                    }
                                    props.onClickSetVerification({
                                        
                                        auth_type: type,
                                    });
                                }}
                            >
                                확인
                            </Button>
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.button_cancel}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                취소
                            </Button>
                        </Fragment>
                    )}
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default Entity;