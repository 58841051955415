import React, { useEffect, useState, useGlobal, Fragment } from 'reactn';
import queryString from 'query-string';
import {
    Typography,
    Button,
    Divider,
    Checkbox,
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { navigatePage, ordinal_suffix_of } from '../lib/lib';
import { Document } from 'react-pdf';

import axios from "axios";

import { toast_success, toast_warning } from '../api/api';
import { autoFormatKrNumber } from '../lib/lib';
import DialogConfirm from '../component/DialogConfirm';
import DialogKMCAuth from '../component/DialogKMCAuth';

const useStyles = makeStyles(theme => ({
    button: {
        // margin: theme.spacing(1),
        width: '100%',
        marginTop: 16,
        height: 40,
        borderRadius: 0,
    },
    buttonSns: {
        width: '48%',
        marginTop: 16,
        height: 40,
        borderRadius: 0,
    },
    inputField: {
        borderRadius: 0,
    },
    bottomLink: {
        color: 'black',
        fontSize: 12,
        textDecoration: 'none',
        marginLeft: 10,
        marginRight: 10,
    },
    textInputfield: {
        marginTop: 5,
        marginBottom: 5,
    },
    button_wizard_next: {
        width: '35%;',
        marginLeft: 5,
        height: 56,
        //marginTop: 19,
        //marginBottom: 24,
        borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));

const Entity = props => {

    const [token, setToken] = useState('');
    const [bLoading, setLoading] = useState(false);

    const [sApiUrl] = useGlobal('sApiUrl');
    const [sViewerDomainName] = useGlobal('sViewerDomainName');
    
    const [sAuthType, setAuthType] = useState('password');
    const [sEmailAuth, setEmailAuth] = useState('none');
    const [sSMSAuth, setSMSAuth] = useState('none');
    const [sEmail, setEmail] = useState('');
    const [sPassword, setPassword] = useState('');
    const [sPhone, setPhone] = useState('');
    const [sPincode, setPincode] = useState('');
    const [bAuthenticated, setAuthenticated] = useState(false);
    const [sDocApiKey, setDocApiKey] = useState('');
    const [sDocApiCKey, setDocApiCKey] = useState('');
    const [bSaveFlag, setSaveFlag] = useState(false);

    const { iNumPages, setNumPages } = useState(null);
    const { iPageNumber, setPageNumber } = useState(1);
    const [sDomainName] = useGlobal('sDomainName');
    const [bCheckbox, setCheckbox] = useState(false);
    const [bConfirmOpen, setConfirmOpen] = useState(false);
    const [sTitle, setTitle] = useState('');
    const [sContent, setContent] = useState('');
    const [bDialogKMCAuthOpen, setDialogKMCAuthOpen] = useState(false);
    const [sSignReqToken, setSignReqToken] = useState('');
    const [sTargetEmail, setTargetEmail] = useState('');
    const [oConfirmData, setConfirmData] = useState({});
    

    // const [aParticipant, setParticipant] = useState([
    //     {
    //         id: 1,
    //         name: 'user1',
    //     },
    //     {
    //         id: 2,
    //         name: 'user2',
    //     },
    // ]);

    const classes = useStyles();

    useEffect(() => {        
        const parsed = queryString.parse(props.location.search);
        
        if (parsed.token !== undefined) {
            setToken(parsed.token);         
            //setAuthenticated(true);
            //setDocApiCKey('5ec13257ff7a352c363ffdf6');
        } else {
            props.history.push('/login');
        }

        window.addEventListener("message", function(e) {
            setConfirmData(e.data);
        });
    }, [props]);

    useEffect(() => {
        if (token.length > 0) {
            setLoading(true);            
            axios.create().post(`/${sApiUrl}/docSignRequest/getTokenInfo`, { token : token, })
                .then(res => {
                    setLoading(false);
                    if (res.data.status === '000') {
                        //console.log(res.data.entity[0].docSignTargetList[0].docSignTargetAuth);
                        setAuthType(res.data.entity[0].docSignTargetList[0].docSignTargetAuth.authType);
                    } else {
                        toast_warning(res.data.message);
                    }
                }).catch(err => {
                    setLoading(false);
                    toast_warning(err.response.statusText);
                });
        }
    }, [token]);

    useEffect(() => {
		if(oConfirmData.signFlag == "Y") {
            setLoading(true);
            axios.create().post(`/${sApiUrl}/docSignRequest/saveSign`, {
                    token : token,
                    pKey : sDocApiKey,
                    cKey : sDocApiCKey,
                }).then(res => {
                    setLoading(false);
                    if (res.data.status === '000') {
                        setSaveFlag(true);
                        toast_success("서명완료되었습니다.");
                    } else {
                        toast_warning(res.data.message);
                    }
                }).catch(err => {
                    console.log(err);
                    setLoading(false);
                    toast_warning('시스템 처리중 장애가 발생하였습니다.');
                    //toast_warning(err.data.message);
                });
        } else if(oConfirmData.signFlag == "N") {
            rejectSign();                
            
        }
        
        if(oConfirmData.confirm) {
            if(window.opener) {
                window.close();
            }
        }
	}, [oConfirmData]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const rejectSign = () => {
        axios.create().post(`/${sApiUrl}/docSignRequest/rejectSign`, {
            token : token,
            pKey : sDocApiKey,
            cKey : sDocApiCKey,
        }).then(res => {
            setLoading(false);
            if (res.data.status === '000') {
                //setSaveFlag(true);
                toast_success("서명 거부되었습니다.");
                setAuthenticated(false);
                //window.close();
                //props.history.push('/login_participant');
                
                
            } else {
                toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(err.response.statusText);
        });
    }
    
    const closeCallback = (win) => {
        console.log("assafafsafsafsas");
        axios.create().post(`/${sApiUrl}/docSignRequest/getKmcAuth`, {
            token : token,
            authType : sAuthType 
        }).then(res => {
            setLoading(false);
            console.log("@@@@!!!!");
            console.log(res);
            if (res.data.status === '000') {
                if (res.data.status === '000') {
                    if (res.data.entity !== undefined && res.data.entity !== null && typeof(res.data.entity) === 'object') {
                        console.log(res.data.entity);
                        console.log("res.data.entity.signFlag::"+res.data.entity.signFlag);
                        setDocApiKey(res.data.entity.docSignRequestList[0].pKey);
                        setDocApiCKey(res.data.entity.docSignRequestList[0].cKey);
                        if(res.data.entity.docSignRequestList[0].state == "04") {
                            toast_warning('이미 서명 거부된 서명입니다.');            
                        } else {
                            setSignReqToken(res.data.entity.signReqToken);
                            setTargetEmail(res.data.entity.targetEmail);
                            setAuthenticated(true);
                            if(res.data.entity.signFlag == "y") {
                                setSaveFlag(true);
                            }
                            toast_success("인증에 성공하였습니다.");
                        }
                    }
                    setDialogKMCAuthOpen(false);
               
                    //win.close();
                    // props.history.push('/login');
                } else {
                    toast_warning(res.data.message);
                }
                
                
            } else {
                toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(err.response.statusText);
        });

    }

    const sendAuthNum = () => {        
        setLoading(true);
        axios.create().post(`/${sApiUrl}/docSignRequest/sendAuthNum`, {
            token : token,
            authType : sAuthType 
        }).then(res => {
            setLoading(false);
            console.log(res);
            if (res.data.status === '000') {
                if (res.data.status === '000') {
                    if (res.data.entity !== undefined && res.data.entity !== null && typeof(res.data.entity) === 'object') {
                        
                    }
                }
            } else {
                toast_warning(res.data.message);
            }
        }).catch(err => {
            setLoading(false);
            toast_warning(err.response.statusText);
        });
    }

    return (
        <div
            style={{
                // backgroundColor: 'green',
                height: '100vh',
                padding: 10,
                //paddingTop: 50,
                paddingBottom: 30,
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {/*<Typography variant="h4" gutterBottom style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                Online Sign
            </Typography>*/}
            <div 
                style={{
                    // backgroundColor: 'red',
                    //fontSize: 42,
                    fontWeight: 100,
                    textAlign : 'center',
                    letterSpacing : '3px',
                    //marginBottom : '-20px',
                }}
            >
                    <img 
                        src="images/logo1-6.png" 
                        alt=""
                        style={{
                            width:'100%',
                            maxWidth:'290px',
                            minWidth: '200px',
                        }}
                    />
            </div>
            {bAuthenticated && (
                <div
                    style={{
                        flex: 1,
                        // backgroundColor: 'green',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {(!bSaveFlag) &&
                    <iframe
                        //운영
                        //src={sDocApiCKey ? `https://onlinesign.co.kr/viewer/sign.html?doc_data_id=${sDocApiCKey}&targetEmail=${sTargetEmail}&docSignReqToken=${sSignReqToken}` : `https://onlinesign.co.kr/viewer/sign.html?doc_id=${sDocApiKey}&targetEmail=${sTargetEmail}&docSignReqToken=${sSignReqToken}`}
                        //개발
                        //src={sDocApiCKey ? `http://pilot.onlinesign.co.kr/viewer/sign.html?doc_data_id=${sDocApiCKey}&targetEmail=${sTargetEmail}&docSignReqToken=${sSignReqToken}` : `http://pilot.onlinesign.co.kr/viewer/sign.html?doc_id=${sDocApiKey}&targetEmail=${sTargetEmail}&docSignReqToken=${sSignReqToken}`}
                        //로컬
                        src={sDocApiCKey ? `${sViewerDomainName}/viewer/sign.html?doc_data_id=${sDocApiCKey}&targetEmail=${sTargetEmail}&docSignReqToken=${sSignReqToken}` : `${sViewerDomainName}/viewer/sign.html?id=${sDocApiKey}&targetEmail=${sTargetEmail}&docSignReqToken=${sSignReqToken}`}
                        style={{
                            flex: 1,
                            border: 'none',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                    }
                    {(bSaveFlag) &&
                    <iframe
                        //운영
                        //src={`https://onlinesign.co.kr/viewer/pdfView.html?doc_data_id=${sDocApiCKey}`}
                        //개발
                        //src={`http://pilot.onlinesign.co.kr/viewer/pdfView.html?doc_data_id=${sDocApiCKey}`}
                        //로컬
                        src={`${sViewerDomainName}/viewer/pdfView.html?doc_data_id=${sDocApiCKey}`}
                        style={{
                            flex: 1,
                            border: 'none',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                    }
                    
                </div>
            )}
            {!bAuthenticated && (
                <div
                    style={{
                        // backgroundColor: 'red',
                        minWidth: 350,
                        maxWidth: 650,
                    }}
                >
                    <Typography variant="h6" gutterBottom style={{ marginTop: 10, textAlign: 'center' }}>
                        인증 확인
                    </Typography>
                    <Divider
                        variant="middle"
                        style={{
                            width: '100%',
                            margin: 0,
                            marginTop: 0,
                            marginBottom: 20,
                        }}
                    />
                    {bLoading && (
                        <div
                            style={{
                                height: 400,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!bLoading && (
                        <Fragment>
                            <RadioGroup
                                aria-label="position"
                                name="position"
                                style={{
                                    width: '100%',
                                }}
                                value={sAuthType}
                                onChange={event => setAuthType(event.target.value)}
                            >
                                <div
                                    style={{
                                        display: (sAuthType == '01' ? 'flex' : 'none'),
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 0.5,
                                        }}
                                    >
                                        <FormControlLabel
                                            value="01"
                                            control={<Radio color="secondary" />}
                                            label="이메일 인증"
                                            labelPlacement="end"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            flex: 0.5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            disabled={sAuthType != '01'}
                                            style={{display: (sEmailAuth != 'none' ? "none" : "")}}
                                            onClick={() => { 
                                                //setLoading(true);
                                                sendAuthNum();
                                                setEmailAuth('');
                                            }}
                                        >
                                            인증번호 요청
                                        </Button>
                                        
                                        <TextField
                                            id="e_password"
                                            className={classes.textInputfield}
                                            variant="outlined"                                            
                                            style={{display:sEmailAuth}}
                                            type='password'
                                            label="인증번호"
                                            // placeholder="서명 참여자의 명의의 휴대폰 번호를 입력해 주세요"
                                            value={sPassword}
                                            onChange={e => setPassword(e.target.value)}
                                            fullWidth
                                        />
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            style={{display:sEmailAuth}}
                                            onClick={() => { 
                                                sendAuthNum();
                                            }}
                                        >
                                            인증번호 다시 요청
                                        </Button>
                                    </div>
                                </div>
                            
                                <div
                                    style={{
                                        display: (sAuthType == '02' ? 'flex' : 'none'),
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 0.5,
                                        }}
                                    >
                                        <FormControlLabel
                                            value="02"
                                            control={<Radio color="secondary" />}
                                            label="SMS 인증"
                                            labelPlacement="end"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            flex: 0.5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            style={{display: (sSMSAuth != 'none' ? "none" : "")}}
                                            disabled={sAuthType !== '02'}
                                            onClick={() => { 
                                                sendAuthNum();
                                                setSMSAuth('');
                                            }}
                                        >
                                            인증번호 요청
                                        </Button>
                                        <TextField
                                            id="s_password"
                                            className={classes.textInputfield}
                                            variant="outlined"                                            
                                            style={{display:sSMSAuth}}
                                            type='password'
                                            label="인증번호"
                                            // placeholder="서명 참여자의 명의의 휴대폰 번호를 입력해 주세요"
                                            value={sPincode}
                                            onChange={e => setPincode(e.target.value)}
                                            fullWidth
                                        />
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            style={{display:sSMSAuth}}
                                            onClick={() => { 
                                                sendAuthNum();
                                            }}
                                        >
                                            인증번호 다시 요청
                                        </Button>
                                    </div>
                                </div>
                            
                                <div
                                    style={{
                                        display: (sAuthType == '03' ? 'flex' : 'none'),
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 0.5,
                                        }}
                                    >
                                        <FormControlLabel
                                            value="03"
                                            control={<Radio color="secondary" />}
                                            label="휴대폰 본인인증"
                                            labelPlacement="end"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            flex: 0.5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            disabled={sAuthType !== '03'}
                                            onClick={() => { 
                                                if(!bCheckbox) {
                                                    toast_warning("개인정보 수집 및 이용 동의는 체크해주세요.");
                                                    return;
                                                }
                                                
                                                const KMCIS_window = window.open('', 'KMCISWindow', 'width=500, height=600, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250' );
                                                KMCIS_window.location = 'https://www.kmcert.com/kmcis/web/kmcisReq.jsp';
                                                //setDialogKMCAuthOpen(true);

                                                setLoading(true);

                                                const cert_data = {};
                                                //고정
                                                cert_data.cpId = 'MLSM1001';
                                                //cert_data.urlCode = '001001';
                                                cert_data.urlCode = '003001';
                                                //oSet.certNum = certNum;
                                                //oSet.date = date;
                                                cert_data.certMet = 'M';
                                                cert_data.plusInfo = token;
                                                cert_data.tr_url = 'https://onlinesign.co.kr/onlinesign/docSignRequest/getKMCRec_Cert';
                                                //oSet.tr_url = 'http://onlinesign.mslk.co.kr/login_participant?token=qt';
                                                cert_data.tr_add = 'N';
                                                
                                                //입력정보
                                                
                                                // cert_data.name = sName;
                                                // cert_data.birthDay = sBirthDay;
                                                // cert_data.gender = sGender;
                                                // cert_data.phoneNo = sPhoneNo;
                                                // cert_data.phoneCorp = sPhoneCorp;
                                                // cert_data.nation = sNation;
                                                
                                                cert_data.name = '';
                                                cert_data.birthDay = '';
                                                cert_data.gender = '';
                                                cert_data.phoneNo = '';
                                                cert_data.phoneCorp = '';
                                                cert_data.nation = '';
                                                axios.create().post(`/${sApiUrl}/docSignRequest/getKmcertKey`, {
                                                    token : token,
                                                    certData : cert_data
                                                }).then(res => {
                                                    console.log(res);
                                                    console.log("tr_cert:::"+cert_data.tr_cert);
                                                    cert_data.tr_cert = res.data.entity.tr_cert;
                                                    const form = document.createElement('form');
                                                    const trCert = document.createElement('input');
                                                    trCert.setAttribute("type","hidden");
                                                    trCert.setAttribute("name","tr_cert");
                                                    trCert.setAttribute("value",cert_data.tr_cert);
                                                    
                                                    const trUrl = document.createElement('input');
                                                    trUrl.setAttribute("type","hidden");
                                                    trUrl.setAttribute("name","tr_url");
                                                    trUrl.setAttribute("value",cert_data.tr_url);

                                                    const trAdd = document.createElement('input');
                                                    trAdd.setAttribute("type","hidden");
                                                    trAdd.setAttribute("name","tr_add");
                                                    trAdd.setAttribute("value",cert_data.tr_add);
                                                    form.append(trCert);
                                                    form.append(trUrl);
                                                    form.append(trAdd);
                                                    document.body.appendChild(form);
                                                    /*
                                                    const KMCIS_window = document.createElement('a');
                                                    KMCIS_window.setAttribute('target','KMCISWindow');
                                                    KMCIS_window.href = `https://www.kmcert.com/kmcis/web/kmcisReq.jsp`;
                                                    KMCIS_window.setAttribute('style','display:block;');
                                                    document.body.appendChild(KMCIS_window);
                                                    KMCIS_window.click();
                                                    */
                                                    //window.href.location="https://www.kmcert.com/kmcis/web/kmcisReq.jsp";
                                                    
                                                    form.setAttribute("target","KMCISWindow");
                                                    form.setAttribute("name","reqKMCISForm");
                                                    form.setAttribute("method","post");
                                                    form.setAttribute("action","https://www.kmcert.com/kmcis/web/kmcisReq.jsp");
                                                    //document.getElementsByName()''
                                                    
                                                    document.reqKMCISForm.submit();

                                                    const interval = window.setInterval(function() {
                                                        try {
                                                            if (KMCIS_window == null || KMCIS_window.closed) {
                                                                window.clearInterval(interval);
                                                                closeCallback(KMCIS_window);
                                                                setLoading(false);
                                                                document.body.removeChild(form);
                                                            }
                                                        }
                                                        catch (e) {
                                                        }
                                                    }, 1000);
                                                    //return win;
                                                
                                                    //const KMCIS_window = window.open('', 'KMCISWindow', 'width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250' );
                                                
                                                    
                                                }).catch(err => {
                                                    setLoading(false);
                                                    //toast_warning(err.response.statusText);
                                                });
                                                
                                                
                                            }}
                                        >
                                            인증하기
                                        </Button>
                                    </div>
                                </div>
                            </RadioGroup>
                            <Divider style={{
                                marginTop: 15,
                                marginBottom: 15,
                            }} />
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        //paddingLeft: 50,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            color="secondary"
                                            checked={bCheckbox}
                                            onChange={event => {
                                                setCheckbox(event.target.checked);
                                            }}
                                        />
                                        <div>
                                            개인정보 수집 및 이용 동의(필수)
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                disabled={bLoading}
                                onClick={() => {
                                    if(!bCheckbox) {
                                        toast_warning("개인정보 수집 및 이용 동의는 체크해주세요.");
                                        return;
                                    }
                                    
                                    /*
                                    const oSet = {};
                                    if(sAuthType == "02") {
                                        oSet.username= sPhone;
                                        oSet.password= sPincode;
                                    } else if(sAuthType == "01") {
                                        oSet.username= sEmail;
                                        oSet.password= sPassword;
                                    }
                                    */
                                    // console.log('TEST:', sAuthType, sEmail, sPassword, sPhone, sPincode);
                                    setLoading(true);
                                    axios.create().post(`/${sApiUrl}/docSignRequest/participantLogin`, {
                                            token,
                                            authType: sAuthType,
                                            ...sAuthType === '01' ? {                                                
                                                password: sPassword
                                            } : {                                                
                                                password: sPincode
                                            },
                                        }).then(res => {
                                            setLoading(false);
                                            if (res.data.status === '000') {
                                                if (res.data.entity !== undefined && res.data.entity !== null && typeof(res.data.entity) === 'object') {
                                                    console.log(res.data.entity);
                                                    console.log("res.data.entity.signFlag::"+res.data.entity.signFlag);
                                                    setDocApiKey(res.data.entity.docSignRequestList[0].pKey);
                                                    setDocApiCKey(res.data.entity.docSignRequestList[0].cKey);
                                                    if(res.data.entity.docSignRequestList[0].state == "04") {
                                                        toast_warning('이미 서명 거부된 서명입니다.');            
                                                    } else {
                                                        setSignReqToken(res.data.entity.signReqToken);
                                                        setTargetEmail(res.data.entity.targetEmail);
                                                        setAuthenticated(true);
                                                        if(res.data.entity.signFlag == "y") {
                                                            setSaveFlag(true);
                                                        }
                                                        toast_success("인증에 성공하였습니다.");
                                                    }
                                                }
                                                // props.history.push('/login');
                                            } else {
                                                toast_warning(res.data.message);
                                            }
                                        }).catch(err => {
                                            setLoading(false);
                                            toast_warning(err.response.statusText);
                                        });
                                }}
                            >
                                서명하기
                            </Button>
                        </Fragment>
                    )}
                    {/* Login as participant:
                    {aParticipant.map(oUser => {
                        return (
                            <div key={oUser.id}>
                                {oUser.name}
                            </div>
                        );
                    })} */}
                </div>
            )}
            <Divider
                variant="middle"
                style={{
                    width: '100%',
                    margin: 0,
                    marginTop: bAuthenticated ? 0 : 80,
                    marginBottom: 20,
                }}
            />
            <div style={{
                width: '100%',
                textAlign: "center",
            }}>
                {/*(bAuthenticated && !bSaveFlag) && (
                    <Fragment>
                        <Button
                            color="secondary"
                            className={classes.button_wizard_next}
                            onClose = {() => {window.close();}}
                            onClick={() => {
                                setConfirmOpen(true);
                                setTitle('서명 거부');
                                setContent('서명을 거부하시겠습니까?');
                              
                            }}
                        >
                            서명 거부
                        </Button>
                        <Button
                            color="secondary"
                            className={classes.button_wizard_next}
                            onClick={() => {
                                // console.log('TEST:', sAuthType, sEmail, sPassword, sPhone, sPincode);
                                setLoading(true);
                                axios.create().post(`/${sApiUrl}/docSignRequest/saveSign`, {
                                        token : token,
                                        pKey : sDocApiKey,
                                        cKey : sDocApiCKey,
                                    }).then(res => {
                                        setLoading(false);
                                        if (res.data.status === '000') {
                                            setSaveFlag(true);
                                            toast_success("서명완료되었습니다.");
                                        } else {
                                            toast_warning(res.data.message);
                                        }
                                    }).catch(err => {
                                        console.log(err);
                                        setLoading(false);
                                        toast_warning('시스템 처리중 장애가 발생하였습니다.');
                                        //toast_warning(err.data.message);
                                    });
                            }}
                        >
                            완료
                        </Button>
                    </Fragment>
                        )*/}

                {/*(bAuthenticated && bSaveFlag) && (
                    <Fragment>
                        <Button
                            color="secondary"
                            className={classes.button_wizard_next}
                            onClick={() => {
                                // console.log('TEST:', sAuthType, sEmail, sPassword, sPhone, sPincode);
                                setLoading(true);
                                
                                axios.create().post(`/${sApiUrl}/docSignRequest/saveDocPDF`, {
                                        token : token,
                                        pKey : sDocApiKey,
                                        cKey : sDocApiCKey,
                                    }).then(res => {
                                        console.log(res.data.entity.fileName);
                                        console.log(res.data.entity.filePath);
                                        
                                        const link = document.createElement('a');

                                        link.href = `http://${sDomainName}/${sApiUrl}/docSignRequest/saveDocPDF/pdf?fileName=${res.data.entity.fileName}`;
                                        //link.href = `http://localhost:7070/${sApiUrl}/docSignRequest/saveDocPDF/pdf?fileName=${res.data.entity.fileName}`;

                                        link.type = "application/octet-stream";
                                        link.setAttribute('download', "test.pdf");
                                        document.body.appendChild(link);
                                        link.click();
                                        setLoading(false);
                                    }).catch(err => {
                                        setLoading(false);
                                        toast_warning(err.response.statusText);
                                    });
                            }}
                        >
                            서명문서 다운로드
                        </Button>
                    </Fragment>
                        )*/}

                {(!bAuthenticated && !bSaveFlag) && (
                
                    <div>
                        Copyright © 2020 OnlineSign All Rights Reserved.
                    </div>
                )}
            </div>
            <DialogConfirm
                open={bConfirmOpen}
                bLoading={bLoading}
                title={sTitle}
                content={sContent}
                onClose={() => {
                    // setDialogCancelSignRequestOpen(false);
                }}
                onClickCancel={() => {
                    setConfirmOpen(false);
                }}
                onClickRejectSign={() => {
                    rejectSign();
                    setConfirmOpen(false);
                    setAuthenticated(false);
                    //props.history.push('/login_participant?token=p1');
                }}
            />
            <DialogKMCAuth
                open={bDialogKMCAuthOpen}
                //targetId={iEditingTargetId}
                bLoading={bLoading}
                setLoading={setLoading}
                token={token}
                onClose={() => {
                    setDialogKMCAuthOpen(false);
                }}
            />
        </div>
    );
};

export default Entity;
