import React, { Fragment, useRef, useState, useGlobal } from 'reactn';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import BoxFilePicker from './BoxFilePicker';

import { toast_warning } from '../api/api';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1.4em' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
}))(MuiDialogActions);

const Dialog = withStyles(theme => ({
    paper: {
        width: '100%',
        maxWidth: 768,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 4,
            marginRight: 4,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 4,
            marginRight: 4,
            maxWidth: '96%',
        },
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '96% !important',
        },
    }
}))(MuiDialog);

const useStyles = makeStyles(theme => ({
    contentSection1: {
        textAlign: 'center',
        width: '20%',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 10,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            borderRight: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'block',
            paddingRight: 0,
            paddingBottom: 20,
        },
    },
    contentSection2: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        minHeight: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    filelistContainer: {
        height: '100%',
        width: '100%',
        // backgroundColor: 'green',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 20,
        },
    },
    button_provider: {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'none',
        width: '100%',
        minWidth: '46%',
        height: 56,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 2,
        marginRight: 2,
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        borderRadius: 56,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        [theme.breakpoints.down('xs')]: {
            width: '49%',
        },
    },
    button_provider_active: {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'none',
        width: '100%',
        minWidth: '46%',
        height: 56,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 2,
        marginRight: 2,
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        borderRadius: 56,
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        [theme.breakpoints.down('xs')]: {
            width: '49%',
        },
    },
    button_wizard_next: {
        minWidth: 130,
        // width: '100%',
        // marginLeft: 5,
        height: 46,
        borderRadius: 46,
        // marginTop: 19,
        // marginBottom: 24,
        // borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        // paddingLeft: 6,
        // paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            //   borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '90%',
        },
    },
    inputFile: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        borderRadius: 6,
        padding: 20,
        paddingTop: 6,
        paddingBottom: 6,
        width: '80%',
        cursor: 'pointer',
    },
}));

function Entity(props) {
    const classes = useStyles();

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    
    const [bLoading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = useState('computer');
    
    const inputFile = useRef(null);
    const [sInputFileName, setInputFileName] = useState('');
    
    const [sDomainName] = useGlobal('sDomainName');
    const [sSelectedFileId, setSelectedFileId] = useState('');
    const [sSelectedFilename, setSelectedFilename] = useState('');

    const [aGoogleDriveFile, setGoogleDriveFile] = useState([]);
    const [bGoogleDriveSignedIn, setGoogleDriveSignedIn] = useState(false);
    const [sGoogleClientId] = useGlobal('sGoogleClientId');
    const [sGoogleDriveApiKey] = useGlobal('sGoogleDriveApiKey');
    const [sGoogleDriveDiscoveryDocs] = useGlobal('sGoogleDriveDiscoveryDocs');
    const [sGoogleDriveScopes] = useGlobal('sGoogleDriveScopes');
    const [sGoogleApiAccessToken, setGoogleApiAccessToken] = useState('');

    const [sDropBoxAppKey] = useGlobal('sDropBoxAppKey');
    const [aDropBoxFile, setDropBoxFile] = useState([]);
    const [bDropBoxFetched, setDropBoxFetched] = useState(false);
    const [sDropboxApiAccessToken, setDropboxApiAccessToken] = useState('');

    const [sOneDriveClientId] = useGlobal('sOneDriveClientId');
    const [sOneDriveScope] = useGlobal('sOneDriveScope');
    const [aOneDriveFile, setOneDriveFile] = useState([]);
    const [bOneDriveFetched, setOneDriveFetched] = useState(false);

    const [aMyTemplate, setMyTemplate] = useState([]);
    const [bMyTemplateFetched, setMyTemplateFetched] = useState(false);

    const listenerGoogleDriveSignin = isSignedIn => {
        setGoogleDriveSignedIn(isSignedIn);
        if (isSignedIn) {
            setGoogleApiAccessToken(window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token);
            retrieveAllFiles(files => {
                // console.log(files);
                setGoogleDriveFile(files);
            });
        }
    };

    const retrieveAllFiles = callback => {
        const retrievePageOfFiles = (request, result) => {
            request.execute(resp => {
                result = result.concat(resp.files);
                const nextPageToken = resp.nextPageToken;
                if (nextPageToken) {
                    request = window.gapi.client.drive.files.list({
                        'pageToken': nextPageToken
                    });
                    retrievePageOfFiles(request, result);
                } else {
                    callback(result);
                    setLoading(false);
                    // console.log(result);
                }
            });
        }
        const initialRequest = window.gapi.client.drive.files.list({
            // 'pageSize': 10,
            // 'fields': "nextPageToken, files(id, name)"
        });
        setLoading(true);
        retrievePageOfFiles(initialRequest, []);
    }

    const show_drive_files = () => {
        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                apiKey: sGoogleDriveApiKey,
                clientId: sGoogleClientId,
                discoveryDocs: sGoogleDriveDiscoveryDocs,
                scope: sGoogleDriveScopes
            }).then(() => {
                window.gapi.auth2.getAuthInstance().isSignedIn.listen(listenerGoogleDriveSignin);
                listenerGoogleDriveSignin(window.gapi.auth2.getAuthInstance().isSignedIn.get());
            }, error => {
                toast_warning(JSON.stringify(error));
            });
        });
    };

    // console.log(inputFile);

    return (
        <Fragment>
            <Dialog
                onClose={props.onClose}
                aria-labelledby="fileuploader-dialog-title"
                open={props.open}
                scroll="body"
            >
                <DialogTitle id="fileuploader-dialog-title" onClose={props.onClose}>
                    문서 업로드
                </DialogTitle>
                <DialogContent dividers>
                    <div
                        className={classes.contentSection1}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            className={currentPage === 'computer' ? classes.button_provider_active : classes.button_provider}
                            onClick={() => {
                                setInputFileName('');
                                setCurrentPage('computer');
                            }}
                        >
                            내 컴퓨터
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={currentPage === 'googledrive' ? classes.button_provider_active : classes.button_provider}
                            onClick={() => {
                                setCurrentPage('googledrive');
                                setSelectedFileId('');
                                show_drive_files();
                            }}
                        >
                            Google Drive
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={currentPage === 'dropbox' ? classes.button_provider_active : classes.button_provider}
                            onClick={() => {
                                setCurrentPage('dropbox');
                                setSelectedFileId('');
                            }}
                        >
                            DropBox
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={currentPage === 'onedrive' ? classes.button_provider_active : classes.button_provider}
                            onClick={() => {
                                setCurrentPage('onedrive');
                                setSelectedFileId('');
                            }}
                        >
                            One Drive
                        </Button>
                        {/**
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button_provider}
                            onClick={() => {
                                setCurrentPage('box');
                                setSelectedFileId('');
                            }}
                        >
                            Box
                        </Button>
                         */}
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button_provider}
                            onClick={() => {
                                setCurrentPage('my_templates');
                                setSelectedFileId('');
                                setMyTemplate([]);
                                setLoading(true);
                                axios.create({
                                    headers: {
                                        Authorization: `Bearer ${sToken}`,
                                    }
                                }).get(`/${sApiUrl}/docTemplate`)
                                .then(res => {
                                    switch (res.data.status) {
                                        case '000':
                                            setLoading(false);
                                            setMyTemplate(res.data.entity.map(item => {
                                                return {
                                                    id: item.templateIdx,
                                                    template_name: item.templateName,
                                                };
                                            }));
                                            setMyTemplateFetched(true);
                                            break;
                                        default:
                                            toast_warning(res.data.message);
                                    }
                                }).catch(err => {
                                    setLoading(false);
                                    toast_warning(`System error: ${JSON.stringify(err)}`);
                                });
                            }}
                        >
                            내 템플릿
                        </Button>
                    </div>
                    <div
                        className={classes.contentSection2}
                    >
                        {currentPage === 'computer' && (
                            <Fragment>
                                <Typography style={{ fontSize: '1.4em', textAlign: 'center' }}>
                                    내 컴퓨터에서 업로드 할 문서를 선택해 주세요.
                                </Typography>
                                <input
                                    type="file"
                                    ref={inputFile}
                                    // className={classes.inputFile}
                                    id="computer_file_upload_input"
                                    style={{
                                        display: 'none',
                                    }}
                                    onChange={() => {
                                        if (inputFile.current.files.length > 0) {
                                            setInputFileName(inputFile.current.files[0].name);
                                        } else {
                                            setInputFileName('');
                                        }
                                    }}
                                />
                                <label
                                    htmlFor="computer_file_upload_input"
                                    className={classes.inputFile}
                                >
                                    {(sInputFileName !== '') ? sInputFileName : '파일찾기'}
                                </label>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography style={{ fontSize: '1.1em', textAlign: 'center' }}>
                                        * 지원 파일 형식 : hwp, doc, docs, xls, xlsx, ppt,<br />pptx, pdf, jpg, jpeg, gif, png 등
                                    </Typography>
                                    <Typography style={{ fontSize: '1.1em', textAlign: 'center' }}>
                                        * 업로드 최대 용량 : 5MB 이하
                                    </Typography>
                                </div>
                            </Fragment>
                        )}
                        {currentPage === 'googledrive' && (
                            <div className={classes.filelistContainer}>
                                {!bGoogleDriveSignedIn && (
                                    <Button
                                        onClick={() => {
                                            window.gapi.auth2.getAuthInstance().signIn();
                                        }}
                                    >
                                        Connect your Google Drive
                                    </Button>
                                )}
                                {bGoogleDriveSignedIn && (
                                    <Fragment>
                                        <Typography style={{ fontWeight: 'bold', fontSize: '1.2em' }}>List of files:</Typography>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                maxWidth: '94%',
                                                // overflow: 'hidden',
                                            }}
                                        >
                                            {bLoading && (
                                                <div>
                                                    Loading...
                                                </div>
                                            )}
                                            {!bLoading && (
                                                <Fragment>
                                                    {aGoogleDriveFile.filter(item => {
                                                        if (item !== undefined && item !== null &&
                                                            [
                                                                'application/vnd.google-apps.folder',
                                                                'application/vnd.google-apps.document',
                                                                'application/vnd.google-apps.spreadsheet',
                                                                'application/vnd.google-apps.presentation',
                                                            ].indexOf(item.mimeType) === -1) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }
                                                    })
                                                        .map(item => (
                                                            <div key={item.id}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={sSelectedFileId === item.id}
                                                                            onChange={e => {
                                                                                if (e.target.checked) {
                                                                                    setSelectedFileId(item.id);
                                                                                    setSelectedFilename(item.name);
                                                                                } else {
                                                                                    if (item.id === sSelectedFileId) {
                                                                                        setSelectedFileId('');
                                                                                        setSelectedFilename('');
                                                                                    }
                                                                                }
                                                                            }}
                                                                            value={item.id}
                                                                        />
                                                                    }
                                                                    label={item.name}
                                                                />
                                                                {/* {item.name} - {item.mimeType} */}
                                                            </div>
                                                        ))}
                                                </Fragment>
                                            )}
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        )}
                        {currentPage === 'dropbox' && (
                            <div className={classes.filelistContainer}>
                            {!bDropBoxFetched && (
                                <Button
                                    onClick={() => {
                                        setDropBoxFile([]);
                                        const fetchRecursively = (sToken, cursor, array) => {
                                            axios.create({
                                                headers: {
                                                    Authorization: `Bearer ${sToken}`,
                                                }
                                            }).post(`https://api.dropboxapi.com/2/files/list_folder/continue`, {
                                                cursor,
                                            })
                                                .then(res => {
                                                    res.data.entries.forEach(item => {
                                                        array.push(item);
                                                    });
                                                    if (res.data.has_more) {
                                                        fetchRecursively(sToken, res.data.cursor, array);
                                                    } else {
                                                        setLoading(false);
                                                        setDropBoxFile(array);
                                                        setDropBoxFetched(true);
                                                    }
                                                }).catch(err => {
                                                    setLoading(false);
                                                    toast_warning(`System error: ${JSON.stringify(err)}`);
                                                });
                                        };
                                        setLoading(true);
                                        window.processOAuth = (sToken) => {
                                            if (sToken.length > 0) {
                                                setDropboxApiAccessToken(sToken);
                                                axios.create({
                                                    headers: {
                                                        Authorization: `Bearer ${sToken}`,
                                                    }
                                                }).post(`https://api.dropboxapi.com/2/files/list_folder`, {
                                                    path: "",
                                                    recursive: true,
                                                })
                                                    .then(res => {
                                                        const aTemp = [];
                                                        res.data.entries.forEach(item => {
                                                            aTemp.push(item);
                                                        });
                                                        if (res.data.has_more) {
                                                            fetchRecursively(sToken, res.data.cursor, aTemp);
                                                        } else {
                                                            setLoading(false);
                                                            setDropBoxFile(aTemp);
                                                            setDropBoxFetched(true);
                                                        }
                                                    }).catch(err => {
                                                        setLoading(false);
                                                        toast_warning(`System error: ${JSON.stringify(err)}`);
                                                    });
                                            }
                                        };
                                        window.open(
                                            //운영
                                            //`https://www.dropbox.com/oauth2/authorize?client_id=${sDropBoxAppKey}&response_type=token&redirect_uri=https://${sDomainName}`,
                                            //로컬
                                            `https://www.dropbox.com/oauth2/authorize?client_id=${sDropBoxAppKey}&response_type=token&redirect_uri=http://${sDomainName}`,
                                            'DropBox authentication',
                                            "width=600px,height=700px,top=100px,left=400px"
                                        );
                                    }}
                                >
                                    Fetch list from DropBox
                                </Button>
                            )}
                            {bDropBoxFetched && (
                                <Fragment>
                                    <Typography style={{ fontWeight: 'bold', fontSize: '1.2em' }}>List of files:</Typography>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            maxWidth: '94%',
                                            // overflow: 'hidden',
                                        }}
                                    >
                                        {bLoading && (
                                            <div>
                                                Loading...
                                            </div>
                                        )}
                                        {!bLoading && (
                                            <Fragment>
                                                {aDropBoxFile
                                                .filter(item => {
                                                    if (item['.tag'] === 'file' && item.is_downloadable !== undefined && item.is_downloadable) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                })
                                                    .map(item => (
                                                        <div key={item.id}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={sSelectedFileId === item.id}
                                                                        onChange={e => {
                                                                            if (e.target.checked) {
                                                                                setSelectedFileId(item.id);
                                                                                setSelectedFilename(item.name);
                                                                            } else {
                                                                                if (item.id === sSelectedFileId) {
                                                                                    setSelectedFileId('');
                                                                                    setSelectedFilename('');
                                                                                }
                                                                            }
                                                                        }}
                                                                        value={item.id}
                                                                    />
                                                                }
                                                                label={item.name}
                                                            />
                                                        </div>
                                                    ))}
                                            </Fragment>
                                        )}
                                    </div>
                                </Fragment>
                            )}
                        </div>
                        )}
                        {currentPage === 'onedrive' && (
                            <div className={classes.filelistContainer}>
                                {!bOneDriveFetched && (
                                    <Button
                                        onClick={() => {
                                            setLoading(true);
                                            window.processOAuth = (sToken) => {
                                                if (sToken.length > 0) {
                                                    console.log(sToken);
                                                    axios.create({
                                                        headers: {
                                                            Authorization: `Bearer ${sToken}`,
                                                        }
                                                    }).get(`https://graph.microsoft.com/v1.0/me/drive/root/children`)
                                                        .then(res => {
                                                            console.log(res);
                                                            setOneDriveFile(res.data.value);
                                                            setLoading(false);
                                                            setOneDriveFetched(true);
                                                        }).catch(err => {
                                                            setLoading(false);
                                                            toast_warning(`System error: ${JSON.stringify(err)}`);
                                                        });
                                                }
                                            };
                                            window.open(
                                                //운영
                                                //`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${sOneDriveClientId}&scope=${sOneDriveScope}&response_type=token&redirect_uri=https://${sDomainName}/`,
                                                //로컬
                                                `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${sOneDriveClientId}&scope=${sOneDriveScope}&response_type=token&redirect_uri=http://${sDomainName}/`,
                                                'OneDrive authentication',
                                                "width=500px,height=700px,top=100px,left=400px"
                                            );
                                        }}
                                    >
                                        Fetch list from OneDrive
                                    </Button>
                                )}
                                {bOneDriveFetched && (
                                    <Fragment>
                                        <Typography style={{ fontWeight: 'bold', fontSize: '1.2em' }}>List of files:</Typography>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                maxWidth: '94%',
                                                // overflow: 'hidden',
                                            }}
                                        >
                                            {bLoading && (
                                                <div>
                                                    Loading...
                                                </div>
                                            )}
                                            {!bLoading && (
                                                <Fragment>
                                                    {aOneDriveFile
                                                    .filter(item => {
                                                        if (item.file !== undefined && item.file !== null &&
                                                            [
                                                                // 'application/vnd.google-apps.folder',
                                                                // 'application/vnd.google-apps.document',
                                                                // 'application/vnd.google-apps.spreadsheet',
                                                                // 'application/vnd.google-apps.presentation',
                                                            ].indexOf(item.file.mimeType) === -1) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }
                                                    })
                                                        .map(item => (
                                                            <div key={item.id}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={sSelectedFileId === item.id}
                                                                            onChange={e => {
                                                                                if (e.target.checked) {
                                                                                    setSelectedFileId(item.id);
                                                                                    setSelectedFilename(item.name);
                                                                                } else {
                                                                                    if (item.id === sSelectedFileId) {
                                                                                        setSelectedFileId('');
                                                                                        setSelectedFilename('');
                                                                                    }
                                                                                }
                                                                            }}
                                                                            value={item.id}
                                                                        />
                                                                    }
                                                                    label={item.name}
                                                                />
                                                                {/* {item.name} - {item.mimeType} */}
                                                            </div>
                                                        ))}
                                                </Fragment>
                                            )}
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        )}
                        {currentPage === 'box' && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            >
                                <BoxFilePicker onSelect={o => {
                                    props.nextStep({ type: 'box', ...o });
                                }}/>
                            </div>
                        )}
                        {currentPage === 'my_templates' && (
                            <div className={classes.filelistContainer}>
                                <Fragment>
                                    <Typography style={{ fontWeight: 'bold', fontSize: '1.2em' }}>List of templates:</Typography>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            maxWidth: '94%',
                                            // overflow: 'hidden',
                                        }}
                                    >
                                        {bLoading && (
                                            <div>
                                                Loading...
                                            </div>
                                        )}
                                        {!bLoading && (
                                            <Fragment>
                                                {bMyTemplateFetched && (
                                                    <Fragment>
                                                        {aMyTemplate.map(item => {
                                                            return (
                                                                <div key={item.id}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={sSelectedFileId === item.id}
                                                                                onChange={e => {
                                                                                    if (e.target.checked) {
                                                                                        setSelectedFileId(item.id);
                                                                                        setSelectedFilename(item.template_name);
                                                                                    } else {
                                                                                        if (item.id === sSelectedFileId) {
                                                                                            setSelectedFileId('');
                                                                                            setSelectedFilename('');
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                value={item.id}
                                                                            />
                                                                        }
                                                                        label={item.template_name}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                    </div>
                                </Fragment>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    {props.bLoading && (
                        <div
                            style={{
                                // width: '100%',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!props.bLoading && currentPage !== 'box' && (
                        <Button
                            color="secondary"
                            className={classes.button_wizard_next}
                            onClick={() => {
                                if (currentPage === 'computer' && inputFile.current !== null && inputFile.current.files.length > 0) {
                                    props.nextStep({ type: 'computer', file: inputFile.current.files[0] });
                                }
                                if (currentPage === 'googledrive') {
                                    if (sSelectedFileId.length > 0) {
                                        props.nextStep({ type: 'googledrive', file: sSelectedFileId, filename: sSelectedFilename, token: sGoogleApiAccessToken });
                                    } else {
                                        toast_warning('Please select the file to upload Online Sign system');
                                    }
                                }
                                if (currentPage === 'dropbox') {
                                    if (sSelectedFileId.length > 0) {
                                        props.nextStep({ type: 'dropbox', file: sSelectedFileId, filename: sSelectedFilename, token: sDropboxApiAccessToken });
                                    } else {
                                        toast_warning('Please select the file to upload Online Sign system');
                                    }
                                }
                                if (currentPage === 'onedrive') {
                                    if (sSelectedFileId.length > 0) {
                                        const aChosenFile = aOneDriveFile.filter(item => item.id === sSelectedFileId);
                                        if (aChosenFile.length > 0) {
                                            props.nextStep({ type: 'onedrive', downloadlink: aChosenFile[0]['@microsoft.graph.downloadUrl'] });
                                        } else {
                                            toast_warning('Please select the file to upload Online Sign system');
                                        }
                                    } else {
                                        toast_warning('Please select the file to upload Online Sign system');
                                    }
                                }
                                if (currentPage === 'my_templates') {
                                    if (sSelectedFileId !== '') {
                                        props.nextStep({ type: 'my_templates', file: sSelectedFileId });
                                    } else {
                                        toast_warning('Please select the one of the templates');
                                    }
                                }
                                // props.onClose();
                            }}
                        >
                            업로드
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default Entity;