import React, { useState, Fragment, useGlobal, setGlobal, useEffect } from 'reactn';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';

import { toast_warning } from '../api/api';
import { navigatePage, ordinal_suffix_of, autoFormatKrNumber } from '../lib/lib';
import DialogConfirm from '../component/DialogConfirm';

const useStyles = makeStyles(theme => ({
    textfield_label: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    div_flex: {
        width: '100%',
        display: 'flex',
    },
    div_flex_wrap: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    button_create_template: {
        width: '100%',
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_back: {
        width: '100%',
        marginRight: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
    },
    button_wizard_next: {
        width: '100%',
        marginLeft: 5,
        height: 56,
        marginTop: 19,
        marginBottom: 24,
        borderWidth: 2,
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        fontSize: '1.1em',
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover': {
            borderWidth: 2,
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));

function Entity(props) {

    const classes = useStyles();

    const [sParticipantNameFirst, setParticipantNameFirst] = useState('');
    const [sParticipantPhoneFirst, setParticipantPhoneFirst] = useState('');
    const [sParticipantEmailFirst, setParticipantEmailFirst] = useState('');
    const [sParticipantNameSecond, setParticipantNameSecond] = useState('');
    const [sParticipantPhoneSecond, setParticipantPhoneSecond] = useState('');
    const [sParticipantEmailSecond, setParticipantEmailSecond] = useState('');
    const [aAdditionalParticipant, setAdditionalParticipant] = useState([]);
    const [aMinusParticipant, setMinusParticipant] = useState([]);
    // const [sNotificationContact, setNotificationContact] = useState('');

    const [sToken] = useGlobal('sToken');
    const [sApiUrl] = useGlobal('sApiUrl');
    
    const [sUserEmail] = useGlobal('sUserEmail');

    const [iEditingSignReqId] = useGlobal('iEditingSignReqId');
    const [iEditingDocId] = useGlobal('iEditingDocId');
    const [sEditingDocTemplateName] = useGlobal('sEditingDocTemplateName');
    const [iOfferDocId] = useGlobal('iOfferDocId');
    const [iParticipant,setParticipant] = React.useState(0);
    
    const [aSignRequestParticipant, setSignRequestParticipant] = useGlobal('aSignRequestParticipant');

    const [sReferrerDocTempEdit, setReferrerDocTempEdit] = useGlobal('sReferrerDocTempEdit');

    const [bLoading, setLoading] = React.useState(false);

    const [sMinusType, setMinusType] = React.useState('');

    const [bConfirmOpen, setConfirmOpen] = useState(false);
    const [sTitle, setTitle] = useState('');
    const [sContent, setContent] = useState('');

    useEffect(() => {
        if (props.targetId !== -1) {
            setLoading(true);
            axios.create({
                headers: {
                    Authorization: `Bearer ${sToken}`,
                }
            }).get(`/${sApiUrl}/docOffer/searchTarget/${iOfferDocId}`)
            .then(res => {
                    // const oEntity = res.data.entity;
                    switch (res.data.status) {
                        case '000':
                            setLoading(false);
                            let participant = 0;
                            if(res.data.entity.participant != null) {
                                participant = parseInt(res.data.entity.participant);
                            } else {
                                toast_warning('문서 편집화면에서 서명참여자를 추가해주세요.');
                            }

                            const targetList = res.data.entity.targetList;
                            console.log(targetList);
                            if(targetList.length < participant) {
                                let size = participant - res.data.entity.targetList.length;
                                for(let i=0; i<size; i++) {
                                    const objSignTarget = new Object();
                                    objSignTarget.id = (new Date().getTime().toString()-Math.random());
                                    objSignTarget.targetName = '';
                                    objSignTarget.targetPhone = '';
                                    objSignTarget.targetEmail = '';
                                    objSignTarget.order = (res.data.entity.targetList.length+i);
                                    targetList.push(objSignTarget);
                                }
                            }
                            console.log(targetList);
                            console.log("targetList end");
                            setParticipant(participant);
                            setGlobal({
                                //sEditingDocTemplateApiKey: res.data.entity.templateApiKey !== undefined ? res.data.entity.templateApiKey : '',                                
                                sEditingSignReqName: sEditingDocTemplateName,
                                aSignRequestParticipant: targetList.map((item, index) => {                                   
                                    return {
                                        id : index,
                                        targetId : item.offerTemplateTargetIdx,
                                        targetName: item.targetName,
                                        targetPhone: item.targetPhone,
                                        targetEmail: item.targetEmail,
                                        order : (item.order == null ? index+1 : item.order),
                                    };
                                }),
                                
                            });
                            
                            console.log(aSignRequestParticipant);
                        
                            /*
                            setGlobal({
                                //sEditingDocTemplateApiKey: res.data.entity.templateApiKey !== undefined ? res.data.entity.templateApiKey : '',
                                sEditingDocTemplateApiKey: res.data.entity.pKey !== undefined ? res.data.entity.pKey : '',
                                sEditingSignReqName: res.data.entity.templateName !== undefined && res.data.entity.templateName !== null ? res.data.entity.templateName : 'default name',
                                aSignRequestParticipant: res.data.entity.docSignTargetList.map((item, index) => {
                                    return {
                                        id: item.docSignTargetIdx,
                                        targetName: item.targetName,
                                        targetPhone: item.targetPhone,
                                        targetEmail: item.targetEmail,
                                        message: item.message,
                                        owner: item.owner === null ? false : item.owner,
                                        order: item.order === null ? index + 1 : item.order,
                                        authUse: 'N',
                                        authType: null,
                                    };
                                }),
                            });
                            */

                            // setSignRequestParticipant(res.data.entity.docSignTargetList.map(item => {
                            //     return {
                            //         id: item.docSignTargetIdx,
                            //         targetName: item.targetName,
                            //         targetPhone: item.targetPhone,
                            //         targetEmail: item.targetEmail,
                            //         message: item.message,
                            //         owner: item.owner,
                            //         authUse: 'N',
                            //         authType: null,
                            //     };
                            // }));
                            break;
                        default:
                            toast_warning(res.data.message);
                    }
                }).catch(err => {
                    // setLoading(false);
                    toast_warning(`System error: ${JSON.stringify(err)}`);
                });
        }
    }, [sToken, sApiUrl, iEditingSignReqId, props.targetId, iEditingDocId, sEditingDocTemplateName]);

    useEffect(() => {
        if(sMinusType == 'A') {
            setAdditionalParticipant(aMinusParticipant);
        } else if(sMinusType == 'S') {
            setSignRequestParticipant(aMinusParticipant);
        }
    },[aMinusParticipant]); 

    //const iPadding = aSignRequestParticipant.length > 0 ? (aSignRequestParticipant.length + 1) : 2;
    const iPadding = iParticipant > 0 ? (iParticipant + 1) : 2;

    const setValue = (index, type) => {
        let value = '';
        aSignRequestParticipant.map((oItem, iIndex) => {
            if (index === iIndex) {
                if(type === 'name') {
                    value = oItem.targetName;
                } else if(type === 'phone') {
                    value = oItem.targetPhone;
                } else if(type === 'email') {
                    value = oItem.targetEmail;
                }
            }
        });
        return value;
    }

    return (
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                id='div_ParticipantWrap'
                style={{
                    maxWidth: 500,
                    width: '100%',
                }}
            >
                {(iParticipant > 0) && ([...Array(iParticipant)].map((n, index) => (                    
                    <Fragment key={index}>
                        <Typography variant='h5' style={{ marginBottom: 20, fontWeight: 'bold' }}>
                            {ordinal_suffix_of(index + 1)} 서명 참여자
                        </Typography>
                        <label htmlFor={`field_name_${index}`} className={classes.textfield_label}>이름 or 회사명</label>
                        <TextField
                            id={`field_name_${index}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이름 또는 회사명을 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={setValue(index, 'name')}
                            onChange={e => {
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetName: e.target.value,
                                            order : (index+1),
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_phone_${index}`} className={classes.textfield_label}>핸드폰 번호</label>
                        <TextField
                            id={`field_phone_${index}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="핸드폰 번호를 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={setValue(index, 'phone')}
                            onChange={e => {
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetPhone: autoFormatKrNumber(e.target.value),
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <label htmlFor={`field_email_${index}`} className={classes.textfield_label}>이메일 주소</label>
                        <TextField
                            id={`field_email_${index}`}
                            style={{
                                marginTop: 3,
                            }}
                            placeholder="이메일 주소를 입력해 주세요."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={setValue(index, 'email')}
                            onChange={e => {
                                setSignRequestParticipant(aSignRequestParticipant.map((oItem, iIndex) => {
                                    if (index === iIndex) {
                                        return {
                                            ...oItem,
                                            targetEmail: e.target.value,
                                            owner: sUserEmail === e.target.value,
                                        };
                                    } else {
                                        return {...oItem};
                                    }
                                }));
                            }}
                        />
                        <Divider style={{
                            marginTop: 15,
                            marginBottom: 15,
                        }} />
                    </Fragment>
                )))}
                
                <TextField
                    id="field6"
                    disabled
                    style={{
                        marginTop: 3,
                        marginBottom: 27,
                    }}
                    placeholder="핸드폰 번호를 입력하시면 문자 or 카카오톡으로 전송이 됩니다."
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // value={sNotificationContact}
                    // onChange={e => setNotificationContact(e.target.value)}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: 20,
                        paddingBottom: 50,
                    }}
                >
                    {bLoading && (
                        <div
                            style={{
                                width: '100%',
                                paddingLeft: 20,
                                paddingRight: 20,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress disableShrink />
                        </div>
                    )}
                    {!bLoading && (
                        <div className={classes.div_flex_wrap}>
                            <div className={classes.div_flex}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.button_wizard_back}
                                    onClick={() => {
                                        setSignRequestParticipant([]);
                                        navigatePage('/my_document', props);
                                    }}
                                >
                                    이전
                                </Button>
                                <Button
                                    color="secondary"
                                    className={classes.button_wizard_next}
                                    onClick={() => {
                                        //setReferrerDocTempEdit('my_document/sign_participant');
                                        const aAll = [];
                                        if (aSignRequestParticipant.length > 0) {
                                            
                                            aSignRequestParticipant.forEach(item => {
                                                console.log(item);
                                                aAll.push({
                                                    offerTemplateTargetIdx: item.targetId, // extra field for existing items                                                
                                                    targetName: item.targetName,
                                                    targetPhone: item.targetPhone,
                                                    targetEmail: item.targetEmail,
                                                    targetPurpose: item.targetPurpose,
                                                    templateIdx : iEditingDocId,
                                                    order : item.order,
                                                });
                                            });
                                        } 
                                        
                                        let bCorrect = true;
                                        let sCorrectType = "";
                                        aAll.forEach(item => {
                                            if (item.targetName === null || item.targetName.length === 0) {
                                                bCorrect = false;
                                                sCorrectType = "01";
                                            }
                                            if (item.targetPhone === null || item.targetPhone.length === 0) {
                                                bCorrect = false;
                                                sCorrectType = "02";
                                            } else {
                                                if(item.targetPhone.length < 11 || item.targetPhone.length > 13) {
                                                    bCorrect = false;
                                                    sCorrectType = "02";
                                                }
                                            }
                                            if (item.targetEmail === null || item.targetEmail.length === 0) {
                                                bCorrect = false;
                                                sCorrectType = "03";
                                            } else {
                                                const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
                                                if (item.targetEmail.match(regExp) != null) {
                                                } else {
                                                    bCorrect = false;
                                                    sCorrectType = "03";
                                                }
                                            }
                                        });
                                        const aEmail = aAll.map(item => item.targetEmail);
                                        const aDistinctEmail = [...new Set(aEmail)];
                                        console.log(aEmail);
                                        console.log(aDistinctEmail);
                                        if (aEmail.length !== aDistinctEmail.length) {
                                            bCorrect = false;
                                            sCorrectType = "04";
                                        }
                                        if (!bCorrect) {
                                            if(sCorrectType == "01") {
                                                alert("이름 또는 회사명을 입력하세요.")
                                            } else if(sCorrectType == "02") {
                                                alert("핸드폰 번호를 입력하지 않았거나 올바르지 않습니다.")
                                            } else if(sCorrectType == "03") {
                                                alert("이메일을 입력하지 않았거나 올바르지 않습니다.")
                                            } else if(sCorrectType == "04") {
                                                alert("동일한 이메일이 입력되어 있습니다.")
                                            }
                                            return;
                                        }
                                        console.log(aAll);
                                        
                                        setLoading(true);   
                                    
                                        // alert('TODO: UPDATING Participants...');
                                        axios.create({
                                            headers: {
                                                Authorization: `Bearer ${sToken}`,
                                            }
                                        }).post(`/${sApiUrl}/docOffer/saveTarget`, {
                                            offerTemplateIdx: iOfferDocId,
                                            targetList: aAll,
                                        })
                                            .then(res => {
                                                setLoading(false);
                                                switch (res.data.status) {
                                                    case '000':
                                                        console.log(res.data.entity);
                                                        
                                                        if (res.data.entity !== undefined && res.data.entity !== null && res.data.entity.targetList !== undefined) {
                                                            setGlobal({
                                                                sEditingSignReqExpireDay : '14',
                                                                sOfferDocTemplateApiCKey : res.data.entity.docDataId,
                                                                aSignRequestParticipant: res.data.entity.targetList.map(item => {
                                                                    return {
                                                                        id: item.offerTemplateTargetIdx,
                                                                        targetName: item.targetName,
                                                                        targetPhone: item.targetPhone,
                                                                        targetEmail: item.targetEmail,    
                                                                        order : item.order,    
                                                                        authUse : 'Y',                                                    
                                                                    };
                                                                }),
                                                                sOfferTargetToken : res.data.entity.offerTargetToken,

                                                                sReferrerDocTempEdit: 'offer_template/offer_sign_participant',
                                                            });
                                                        }
                                                        
                                                        navigatePage('/offer_template/offer_template_editing', props);
                                                        
                                                        
                                                        
                                                        break;
                                                    default:
                                                        toast_warning(res.data.message);
                                                }
                                            }).catch(err => {
                                                setLoading(false);
                                                toast_warning(`System error: ${JSON.stringify(err)}`);
                                            });
                                    }}
                                >
                                    다음
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <DialogConfirm
                open={bConfirmOpen}
                bLoading={bLoading}
                title={sTitle}
                content={sContent}
                onClose={() => {
                    // setDialogCancelSignRequestOpen(false);
                }}
                onClickCancel={() => {
                    setConfirmOpen(false);
                    navigatePage('/my_document/sign_request_settings', props);
                }}
                onClickConfirm={() => {
                    setConfirmOpen(false);
                    navigatePage('/document_template/template_editing', props);
                }}
            />
        </div>
    );
}

export default Entity;